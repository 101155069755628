import * as Sentry from '@sentry/react';
import { PDF_VIEWER_URL } from 'config';
import { DriveFile, GetOneFileTypeRes } from 'models/Drive';
import { useCallback, useEffect, useState } from 'react';
import { getOneFileType, getOneShareFileType } from 'services/DriveService';

interface PreviewItem extends DriveFile {
  preview: string;
}

export const PDFViewer = ({
  file,
  url,
  token,
}: {
  file: PreviewItem;
  url?: string;
  token?: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isFail, setFail] = useState<boolean>(false);
  const [path, setPath] = useState<string | null>(null);

  const getPreview = useCallback(async () => {
    try {
      setLoading(true);
      let res: GetOneFileTypeRes | null = null;
      if (token) {
        res = await getOneShareFileType({
          driveType: file.driveType,
          folderType: file.folderType,
          path: file.dir ? `${file.dir}/${file.fileName}` : file.fileName,
          shareToken: token,
        });
      } else {
        res = await getOneFileType({
          driveType: file.driveType,
          folderType: file.folderType,
          path: file.dir ? `${file.dir}/${file.fileName}` : file.fileName,
        });
      }
      setPath(res.url);
      setFail(false);
    } catch (err) {
      Sentry.captureException(err);
      setFail(true);
      setPath(null);
    } finally {
      setLoading(false);
    }
  }, [file, token]);

  useEffect(() => {
    if (url) {
      setPath(url);
    } else if (file) {
      getPreview();
    }
  }, [file, getPreview, url, token]);

  if (loading) {
    return <div>Loading PDF...</div>;
  }

  if (!loading && isFail) {
    return <div>Fail to load PDF.</div>;
  }

  return (
    <iframe
      src={`${PDF_VIEWER_URL}?file=${path}`}
      title="pdf"
      className="pdf-iframe"
    ></iframe>
  );
};
