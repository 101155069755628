import * as Sentry from '@sentry/react';
import { API_URL, APP_URL } from 'config';
import _ from 'lodash';
import {
  DisplayFileType,
  DriveFile,
  DriveObject,
  DriveType,
  FileType,
  GetOneFileTypeRes,
  ShareDriveTokenResponse,
} from 'models/Drive';
import { toast } from 'react-toastify';
import {
  GetFolderListParams, // addToBookmarkInDrive,
  copyFileToDrive,
  deleteFolderInDrive,
  downloadOneDriveFile,
  getFolderList,
  getOneFileType,
  getOneShareFileType, // removeFromBookmarkInDrive,
} from 'services/DriveService';

import { toastError, toastSuccess } from './common';

export const VIDEO_EXT = [
  'MP4',
  'WEBM',
  'MOV',
  'AVI',
  'MPEG',
  'OGV',
  'TS',
  '3GP',
  '3G2',
  '',
];
export const IMAGE_EXT = [
  'JPG',
  'JPEG',
  'GIF',
  'PNG',
  'WEBP',
  'SVG',
  'AVIF',
  'BMP',
  'ICO',
  'TIF',
  'TIFF',
  '',
];
export const PDF_EXT = ['PDF'];

export const getFileType = (fileName: string): DisplayFileType => {
  const nameArr = fileName.split('.');
  if (nameArr && nameArr.length > 1) {
    const extension = nameArr[nameArr.length - 1].toUpperCase();
    if (VIDEO_EXT.includes(extension)) {
      return 'video';
    } else if (IMAGE_EXT.includes(extension)) {
      return 'image';
    } else if (PDF_EXT.includes(extension)) {
      return 'pdf';
    }
  }
  return 'others';
};

export const isFileIncludesEXT = (fileName: string): boolean => {
  const nameArr = fileName.split('.');
  if (nameArr && nameArr.length > 1) {
    const extension = nameArr[nameArr.length - 1].toUpperCase();
    if (VIDEO_EXT.includes(extension)) {
      return false;
    } else if (IMAGE_EXT.includes(extension)) {
      return false;
    } else if (PDF_EXT.includes(extension)) {
      return false;
    }
  }
  return true;
};

export const getFilePreviewLink = (
  folderType: FileType,
  dir: string,
  fileName: string,
  driveType: string,
  token?: string,
) => {
  const encodedPath = encodeURIComponent(`${dir}/${fileName}`);
  if (token) {
    return `${API_URL}/v1/drive/share/file?folderType=${folderType}&driveType=${driveType}&path=${encodedPath}&shareToken=${token}`;
  }
  return `${API_URL}/v1/drive/file?folderType=${folderType}&driveType=${driveType}&path=${encodedPath}`;
};

export const fileCompareFunc = (a, b) => {
  if (a.type > b.type) {
    return -1;
  }
  if (a.type < b.type) {
    return 1;
  }
  return 0;
};

export const addToMyFolderHelper = async (
  selectedItem: string[],
  setIsSelect?: (select: boolean) => void,
  setSelectedItem?: (value: string[]) => void,
) => {
  try {
    if (selectedItem.length > 0) {
      await Promise.all(
        selectedItem.map(item => {
          const splittedItem = item.split('/');
          const source: GetFolderListParams = {
            folderType: splittedItem[0] as FileType,
            driveType: 'public' as DriveType,
            path: `${splittedItem.slice(1).join('/')}`,
          };
          const dest: GetFolderListParams = {
            folderType: splittedItem[0] as FileType,
            driveType: 'private' as DriveType,
            path: `${splittedItem[splittedItem.length - 1]}`,
          };
          const params = {
            source,
            dest,
          };
          return copyFileToDrive(params);
        }),
      );
      setSelectedItem?.([]);
      setIsSelect?.(false);
      toastSuccess('成功新增至我的文件');
    } else {
      toastError('請選擇檔案');
    }
  } catch (err) {
    // console.log(err);
    Sentry.captureException(err);
    toastError('新增文件失敗，請重試');
  }
};

export const fileOnClickHelper = (
  e: React.MouseEvent<HTMLElement>,
  data: DriveObject,
  isSelect: boolean,
  selectedItem: string[],
  setSelectedItem: any,
) => {
  const { dir, fileName, folderType } = data;

  if (isSelect && selectedItem) {
    let tempItem = [...selectedItem];
    const itemToString = `${folderType}/${dir ? dir + '/' : ''}${fileName}`;
    if (tempItem.includes(itemToString)) {
      setSelectedItem?.(tempItem.filter(item => item !== itemToString));
    } else {
      setSelectedItem?.([...tempItem, itemToString]);
    }
  }
  // else if (e.detail === 1) {
  //   // previewFile(data);
  // } else if (e.detail === 2) {
  //   // openFolder(data);
  // }
};

// preview file
export const previewFileHelper = (
  driveData: DriveFile[],
  item: DriveObject,
  setPreviewIndex: any,
  setPreviewOpen: any,
) => {
  const index = _.findIndex(
    driveData.filter(file => file.type === 'File'),
    file => file.fileName === item.fileName,
  );
  if (index > -1) {
    setPreviewIndex(index);
    setPreviewOpen(true);
  }
};

export const openFolderHelper = (
  data: DriveObject,
  location: any,
  navigate: any,
) => {
  const { dir, fileName, driveType, folderType } = data;
  const params = new URLSearchParams(location.search);
  params.set('path', `${dir}/${fileName}`);
  params.set('type', folderType);
  navigate({
    pathname: location.pathname.includes(driveType)
      ? location.pathname
      : driveType,
    search: params.toString(),
  });
};

export const deleteFileHelper = async (
  selectedItem: string[],
  getDriveListData: () => void,
  setLoading: (value: boolean) => void,
  setIsSelect?: (select: boolean) => void,
  setSelectedItem?: (value: string[]) => void,
) => {
  try {
    setLoading(true);
    if (selectedItem.length > 0) {
      await Promise.all(
        selectedItem.map(item => {
          const splittedItem = item.split('/');
          const params: GetFolderListParams = {
            folderType: splittedItem[0] as FileType,
            driveType: 'private' as DriveType,
            path: `${splittedItem.slice(1).join('/')}`,
          };
          return deleteFolderInDrive(params);
        }),
      );
      setSelectedItem?.([]);
      getDriveListData?.();
      setIsSelect?.(false);
      setLoading(false);
      toastSuccess('移除資料成功');
    } else {
      toastError('移除失敗，請選擇文件或資料夾');
    }
  } catch (err) {
    // console.log(err);
    Sentry.captureException(err);
    setLoading(false);
    toastError('移除資料失敗，請重試');
  } finally {
    setLoading(false);
  }
};

// driveExplorer helper funuction

export const openFolderInDialogHelper = async (
  data: DriveObject,
  setCurrentFolderListInDialog: (data: DriveFile[]) => void,
) => {
  const { folderType, driveType, dir, fileName, type } = data;
  try {
    if (type === 'Folder') {
      const params = {
        folderType,
        driveType,
        path: `${dir ? dir + '/' : ''}${fileName}`,
      };
      const listRes = await getFolderList(params);
      setCurrentFolderListInDialog(
        listRes.map(res => ({
          ...res,
          fileType: getFileType(res.fileName),
        })),
      );
    } else {
      toastError('請選擇有效資料夾');
    }
  } catch (err) {
    // console.log(err);
    Sentry.captureException(err);
    toastError('獲取資料失敗，請重試');
  }
};

export const getPrevPageRoute = (
  type: string | null,
  path: string | null,
  fromPublic: boolean,
) => {
  if (!path || path.length <= 0) {
    return `/drive`;
  } else {
    const arrayOfPath = path.split('/').filter(item => item !== '');
    if (arrayOfPath.length <= 1) {
      return `${fromPublic ? `/drive/public?type=${type}` : `/drive`}`;
    } else {
      arrayOfPath.pop();
      let newPath: string;
      if (arrayOfPath.length <= 1) {
        newPath = encodeURIComponent(`/${arrayOfPath.join('/')}`);
      } else {
        newPath = encodeURIComponent(arrayOfPath.join('/'));
      }
      return `/drive/${
        fromPublic
          ? `public?type=${type}&path=${newPath}`
          : `private?path=${newPath}&type=${type}`
      }`;
    }
  }
};

export const downloadFile = async (file: DriveFile, token?: string) => {
  try {
    if (token) {
      const res = await getOneShareFileType({
        driveType: file.driveType,
        folderType: file.folderType,
        path: `${file.dir ? `${file.dir}/` : ''}${file.fileName}`,
        shareToken: token,
      });
      if (res.url) {
        await downloadOneDriveFile(res.url, file.fileName);
      }
    } else {
      const res = await getOneFileType({
        driveType: file.driveType,
        folderType: file.folderType,
        path: `${file.dir ? `${file.dir}/` : ''}${file.fileName}`,
      });
      if (res.url) {
        await downloadOneDriveFile(res.url, file.fileName);
      }
    }
  } catch (err) {
    Sentry.captureException(err);
    toast.error('下載檔案失敗。');
  }
};

export const getShareDrivePath = (
  data: ShareDriveTokenResponse,
  file?: GetOneFileTypeRes,
) => {
  const encodedPath = encodeURIComponent(`${data.path}`);
  return `${APP_URL}/share?folder=${data.driveType}&type=${
    data.folderType
  }&path=${encodedPath}&token=${data.token}${
    file?.type && file?.type === 'File' ? `&fileName=${encodedPath}` : ''
  }${file?.url ? `&url=${file.url}` : ''}`;
};
