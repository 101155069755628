import { QRCodeColor } from 'models';
import { store } from 'store';
import { useAppSelector } from 'store/hooks';

const determineValidMembership = (qrCodeColor: QRCodeColor | null) => {
  return qrCodeColor && qrCodeColor.noOfDaysExpired < 31;
};

export const checkValidMembership = () => {
  const { qrCodeColor } = store.getState().auth;
  return {
    isValid: determineValidMembership(qrCodeColor),
  };
};

export const useValidMembership = () => {
  const { qrCodeColor } = useAppSelector(rootState => rootState.auth);
  return {
    isValid: determineValidMembership(qrCodeColor),
  };
};
