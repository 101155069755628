import * as Sentry from '@sentry/react';
import Attachment from 'assets/icons/attachment.svg';
import BoldTickIcon from 'assets/icons/bold_tick.svg';
import Calendar from 'assets/icons/calendar_purple.svg';
import Clock from 'assets/icons/clock.svg';
import Coin from 'assets/icons/coin.svg';
import Deadline from 'assets/icons/deadline.svg';
import Flag from 'assets/icons/flag.svg';
import LocationPin from 'assets/icons/location_pin.svg';
import MoneyBag from 'assets/icons/money_bag.svg';
import Phone from 'assets/icons/phone.svg';
import VerySmallCoin from 'assets/icons/very_small_coin.svg';
import 'assets/scss/Components/EventDetailCard.scss';
import { CheckIsSameDate, FontHelper, toastError } from 'helpers';
import { getFileType } from 'helpers/DriveHelper';
import useBreakpoint from 'hooks/breakpoints';
import { Course, Event } from 'models';
import { DriveType, FileType, GetOneFileTypeRes } from 'models/Drive';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { getOneFileType } from 'services/DriveService';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { ImageSwiper } from '../Common';
import { BookmarkButton } from '../Common/BookmarkButton';
import { FileViewer } from '../Drive';
import { EventPricingOptions } from './EventPricingOptions';

export interface EventDetailCardProps {
  eventData?: Event | null;
  courseData?: Course | null;
  bookmarked?: boolean;
  setBookmarked?: Dispatch<SetStateAction<boolean>>;
}

export const EventDetailCard = ({
  eventData,
  courseData,
  bookmarked,
  setBookmarked,
}: EventDetailCardProps) => {
  const [FileViewerOpen, setFileViewerOpen] = useState<boolean>(false);
  const [AttachmentTypeData, setAttachmentTypeData] =
    useState<GetOneFileTypeRes>();
  const navigate = useNavigate();
  const { Auth } = useAppSelector((rootState: RootState) => rootState.auth);
  const { size, isMobile } = useBreakpoint();

  const getAttachmentFileType = useCallback(
    async (folderType: FileType, driveType: DriveType, path: string) => {
      try {
        const params = {
          folderType: folderType,
          driveType: driveType,
          path: path || '',
        };
        const AttachmentTypeRes = await getOneFileType(params);
        setAttachmentTypeData(AttachmentTypeRes);
      } catch (err) {
        // console.log(err);
        Sentry.captureException(err);
        toastError('獲取資源資料失敗');
      }
    },
    [],
  );

  const attachmentOnClick = () => {
    if (
      eventData &&
      eventData.eventAttachmentDriveType &&
      eventData.eventAttachmentFolderType &&
      eventData.eventAttachmentPath
    ) {
      if (AttachmentTypeData && AttachmentTypeData.type === 'File') {
        setFileViewerOpen(true);
      } else if (AttachmentTypeData && AttachmentTypeData.type === 'Folder') {
        navigate(
          `/drive/${eventData.eventAttachmentDriveType}?type=${eventData.eventAttachmentFolderType}&path=${eventData.eventAttachmentPath}`,
        );
      }
    } else if (
      courseData &&
      courseData.courseAttachmentDriveType &&
      courseData.courseAttachmentFolderType &&
      courseData.courseAttachmentPath
    ) {
      if (AttachmentTypeData && AttachmentTypeData.type === 'File') {
        setFileViewerOpen(true);
      } else if (AttachmentTypeData && AttachmentTypeData.type === 'Folder') {
        navigate(
          `/drive/${courseData.courseAttachmentDriveType}?type=${courseData.courseAttachmentFolderType}&path=${courseData.courseAttachmentPath}`,
        );
      }
    } else return;
  };

  useEffect(() => {
    if (Auth) {
      if (
        eventData &&
        eventData.eventAttachmentDriveType &&
        eventData.eventAttachmentFolderType &&
        eventData.eventAttachmentPath
      ) {
        getAttachmentFileType(
          eventData.eventAttachmentFolderType,
          eventData.eventAttachmentDriveType,
          eventData.eventAttachmentPath,
        );
      } else if (
        courseData &&
        courseData.courseAttachmentDriveType &&
        courseData.courseAttachmentFolderType &&
        courseData.courseAttachmentPath
      ) {
        getAttachmentFileType(
          courseData.courseAttachmentFolderType,
          courseData.courseAttachmentDriveType,
          courseData.courseAttachmentPath,
        );
      }
    }
  }, [getAttachmentFileType, courseData, eventData, Auth]);

  return (
    <div className="event-detail-body user-select-none">
      {FileViewerOpen ? (
        eventData &&
        eventData.eventAttachmentDriveType &&
        eventData.eventAttachmentFolderType &&
        eventData.eventAttachmentPath &&
        AttachmentTypeData ? (
          <FileViewer
            visible={FileViewerOpen}
            files={[
              {
                type: AttachmentTypeData.type,
                folderType: eventData.eventAttachmentFolderType,
                driveType: eventData.eventAttachmentDriveType,
                dir: '',
                fileType: getFileType(eventData.eventAttachmentPath),
                fileName: eventData.eventAttachmentPath,
                isBookmark: false,
              },
            ]}
            onClose={() => setFileViewerOpen(false)}
            previewIndex={0}
            url={AttachmentTypeData.url ? AttachmentTypeData.url : undefined}
          />
        ) : courseData &&
          courseData.courseAttachmentDriveType &&
          courseData.courseAttachmentFolderType &&
          courseData.courseAttachmentPath &&
          AttachmentTypeData ? (
          <FileViewer
            visible={FileViewerOpen}
            files={[
              {
                type: AttachmentTypeData.type,
                folderType: courseData.courseAttachmentFolderType,
                driveType: courseData.courseAttachmentDriveType,
                dir: '',
                fileType: getFileType(courseData.courseAttachmentPath),
                fileName: courseData.courseAttachmentPath,
                isBookmark: false,
              },
            ]}
            onClose={() => setFileViewerOpen(false)}
            previewIndex={0}
            url={AttachmentTypeData.url ? AttachmentTypeData.url : undefined}
          />
        ) : null
      ) : null}
      {!isMobile && bookmarked !== undefined && setBookmarked ? (
        <BookmarkButton
          isBookmarked={bookmarked}
          eventId={eventData?.eventId}
          courseId={courseData?.courseId}
          setBookmark={setBookmarked}
        />
      ) : null}
      <div
        className="event-detail-image"
        style={{
          paddingBottom:
            (eventData?.images && eventData.images.length > 1) ||
            (courseData?.images && courseData.images.length > 1)
              ? '50px'
              : '30px',
        }}
      >
        <div className="event-detail-image-container">
          {eventData?.images ? (
            <ImageSwiper images={eventData.images} />
          ) : courseData?.images ? (
            <ImageSwiper images={courseData.images} />
          ) : null}
          <div className="swiper-pagination"></div>
        </div>
      </div>
      <div className="event-detail-info d-flex flex-column align-items-start justify-content-between">
        <div className="event-title font-20px-bold">
          {eventData
            ? eventData.eventTitle
            : courseData
            ? courseData.courseTitle
            : null}
        </div>
        <div className="member-only-and-availability d-flex align-items-center justify-content-between w-100">
          <div className="member-only d-flex align-items-center">
            {eventData ? (
              <img src={BoldTickIcon} alt="Tick" className="me-2" />
            ) : courseData ? (
              <span>課程編號：</span>
            ) : null}
            <div className="info-text ms-0">
              {eventData
                ? eventData.memberOnly
                  ? '會員專用'
                  : '公眾參與'
                : courseData
                ? courseData.courseCode
                : null}
            </div>
          </div>
          {eventData ? (
            eventData.isFewQuotaLeft && eventData.eventVacancy > 0 ? (
              <div className="availability d-flex align-items-center">
                <img src={Flag} alt="Flag" />
                <div className="availability-text">剩餘少量名額</div>
              </div>
            ) : null
          ) : courseData ? (
            courseData.isFewQuotaLeft && courseData.courseVacancy > 0 ? (
              <div className="availability d-flex align-items-center">
                <img src={Flag} alt="Flag" />
                <div className="availability-text">剩餘少量名額</div>
              </div>
            ) : null
          ) : null}
        </div>

        <div className="event-date d-flex align-items-center">
          <img src={Calendar} alt="Date:" />
          <div className="info-text">
            {eventData
              ? moment(eventData.startDate).locale('en').format('DD-MMM-YYYY')
              : courseData
              ? CheckIsSameDate(courseData.startDate, courseData.endDate)
                ? moment(courseData.startDate)
                    .locale('en')
                    .format('DD-MMM-YYYY')
                : moment(courseData.startDate)
                    .locale('en')
                    .format('DD-MMM-YYYY') +
                  ' 至 ' +
                  moment(courseData.endDate).locale('en').format('DD-MMM-YYYY')
              : null}
          </div>
        </div>
        {eventData ? (
          <div className="event-time d-flex align-items-center">
            <img src={Clock} alt="Time:" />
            <div className="info-text">
              {moment(eventData.startDate).format('HH:mm')} -&nbsp;
              {moment(eventData.endDate).format('HH:mm')}
            </div>
          </div>
        ) : null}

        <div className="event-avenue d-flex align-items-start">
          <img src={LocationPin} alt="Avenue:" />
          <div className="info-text">
            {eventData
              ? eventData.eventLocation
              : courseData
              ? courseData.courseLocation
              : null}
          </div>
        </div>
        <div className="event-fee d-flex align-items-start">
          <img src={MoneyBag} alt="Fee:" />
          <div className="info-text payment-text">
            {eventData ? (
              <EventPricingOptions PaymentOptions={eventData.paymentOptions} />
            ) : courseData ? (
              <EventPricingOptions PaymentOptions={courseData.paymentOptions} />
            ) : null}
          </div>
        </div>
        {courseData ? (
          courseData.contactPhoneNumber ? (
            <div className="event-contact d-flex align-items-center">
              <img src={Phone} alt="Contact:" />
              <div className="info-text">
                {courseData.contactPhoneNumber}
                {' (' + courseData.contactPerson + ')'}
              </div>
            </div>
          ) : null
        ) : null}

        <div className="event-deadline d-flex align-items-center">
          <img src={Deadline} alt="Deadline:" />
          <div className="info-text">
            截止報名日期：
            {eventData
              ? moment(eventData.registrationDeadline)
                  .locale('en')
                  .format('DD-MMM-YYYY') +
                ' ' +
                moment(eventData.registrationDeadline)
                  .locale('en')
                  .format('HH:mm')
              : courseData
              ? moment(courseData.registrationDeadline)
                  .locale('en')
                  .format('DD-MMM-YYYY') +
                ' ' +
                moment(courseData.registrationDeadline)
                  .locale('en')
                  .format('HH:mm')
              : null}
          </div>
        </div>
        <div className="points-to-be-earn-when-apply-container d-flex flex-column">
          {eventData ? (
            eventData.pointEarnAfterApply ? (
              <div className="d-flex align-items-center">
                <span className="points-to-be-earn-when-apply-text">
                  * 報名活動可獲取&nbsp;
                </span>
                <img src={Coin} alt="point" draggable={false} />
                <span>&nbsp;{eventData.pointEarnAfterApply}</span>
              </div>
            ) : null
          ) : courseData ? (
            courseData.pointEarnAfterApply ? (
              <div className="d-flex align-items-center">
                <span className="points-to-be-earn-when-apply-text">
                  * 報名課程可獲取&nbsp;
                </span>
                <img src={Coin} alt="point" draggable={false} />
                <span>&nbsp;{courseData.pointEarnAfterApply}</span>
              </div>
            ) : null
          ) : null}
          {eventData ? (
            eventData.pointEarnAfterCheckIn ? (
              <div className="d-flex align-items-center">
                <span className="points-to-be-earn-when-apply-text">
                  * 成功參加活動可獲取&nbsp;
                </span>
                <img src={Coin} alt="point" draggable={false} />
                <span>&nbsp;{eventData.pointEarnAfterCheckIn}</span>
              </div>
            ) : null
          ) : null}
        </div>

        <div className="description-content">
          {eventData ? (
            eventData.eventDescription ? (
              <ReactQuill
                theme="snow"
                value={eventData.eventDescription}
                readOnly
                modules={{
                  toolbar: false,
                }}
                className="quill-comp"
              />
            ) : null
          ) : courseData ? (
            courseData.courseDescription ? (
              <ReactQuill
                theme="snow"
                value={courseData.courseDescription}
                readOnly
                modules={{
                  toolbar: false,
                }}
                className="quill-comp"
              />
            ) : null
          ) : null}
        </div>
      </div>
      {courseData ? (
        <div className="course-detail-info">
          <hr className="seperation-line w-100" />
          <div className="course-detail-info-heading d-inline-flex align-items-end yellow-heading">
            <div className="me-2 font-18px-semibold course-detail-info-heading-text">
              課節資料
            </div>
            <div className="font-12px-semibold course-detail-info-heading-count">
              {'(合共' + courseData.CourseSchedules.length + '堂)'}
            </div>
          </div>
          <div className="course-detail-info-body">
            {courseData.CourseSchedules.map((schedule, index) => (
              <div
                className="course-schedule d-flex align-items-center justify-content-between"
                key={index}
              >
                <div
                  className={`course-number ${FontHelper(
                    size,
                    14,
                    16,
                    16,
                    'bold',
                  )}`}
                >
                  <div>
                    {'課堂 - ' +
                      (index + 1 < 10 ? '0' + (index + 1) : index + 1)}
                  </div>
                  {schedule.pointEarnAfterCheckIn ? (
                    <div className="d-flex align-items-center">
                      <span className="points-to-be-earned d-flex align-items-center points-to-be-earned-left">
                        參加課堂可獲取
                      </span>
                      <img src={VerySmallCoin} alt="point" draggable={false} />
                      <span className="points-to-be-earned points-to-be-earned-right">
                        {schedule.pointEarnAfterCheckIn}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="course-schedule-date">
                  {moment(schedule.date).locale('en').format('DD-MMM-YYYY')}
                </div>
                <div className="course-schedule-time">
                  {moment(schedule.startTime, 'HH:mm:ss').format('HH:mm')}{' '}
                  -&nbsp;
                  {moment(schedule.endTime, 'HH:mm:ss').format('HH:mm')}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="event-detail-reminder">
        <hr className="seperation-line w-100" />
        <div className="reminder-heading d-flex align-items-center justify-content-between">
          <div className="reminder-heading-left yellow-heading">注意事項</div>
          {Auth && AttachmentTypeData ? (
            <button
              className={`reminder-heading-right ${
                AttachmentTypeData ? 'active' : 'inactive'
              }`}
              onClick={() => attachmentOnClick()}
            >
              <div className="attachment d-flex align-items-center">
                <div className="attachment-text">附件</div>
                <img src={Attachment} alt="Attachment" draggable={false} />
              </div>
            </button>
          ) : null}
        </div>
        <div className="reminder-content">
          {eventData ? (
            eventData.eventReminder ? (
              <ReactQuill
                theme="snow"
                value={eventData.eventReminder}
                readOnly
                modules={{
                  toolbar: false,
                }}
                className="quill-comp"
              />
            ) : null
          ) : courseData ? (
            courseData.courseReminder ? (
              <ReactQuill
                theme="snow"
                value={courseData.courseReminder}
                readOnly
                modules={{
                  toolbar: false,
                }}
                className="quill-comp"
              />
            ) : null
          ) : null}
        </div>
      </div>
    </div>
  );
};
