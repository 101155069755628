import arrowIcon from 'assets/icons/arrow_right_purple_small.svg';
import 'assets/scss/Components/Profile/StatementRow.scss';
import _ from 'lodash';
import { Fee, Statement } from 'models';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Collapse } from 'react-bootstrap';

interface PaymentItemProps {
  statement: Statement;
}

export const StatementRow = ({ statement }: PaymentItemProps) => {
  const [open, setOpen] = useState(false);

  const feeDetail = useMemo(() => {
    let newFeeDetail: {
      date: string;
      data: Fee[];
    }[] = [];
    for (const [key, value] of Object.entries(
      _.groupBy(statement.Fees, 'date'),
    )) {
      newFeeDetail.push({
        date: key,
        data: value,
      });
    }
    return _.orderBy(newFeeDetail, 'date', 'asc');
  }, [statement.Fees]);

  return (
    <>
      <button
        onClick={() => setOpen(!open)}
        aria-controls={`${statement.statementId}`}
        aria-expanded={open}
        className="d-flex flex-row statement-row-button"
      >
        <div className="statement-row-container d-flex flex-row">
          <div className="d-flex flex-column align-items-start w-100">
            <div className="font-16px-semibold text-color-purple d-flex align-items-center w-100">
              <div>#{statement.statementId}</div>
              <div className="statement-row-list-item-title one-line-ellipsis">
                {statement.title}
              </div>
            </div>

            <div className="d-flex align-items-center pay-text">
              <div className="font-12px-semibold font-spacing">帳單費用：</div>
              <div className="font-12px-semibold text-color-purple font-spacing">
                ${_.round(statement.originalAmount / 100, 2)}
              </div>
            </div>
          </div>
          <img src={arrowIcon} alt="" className="drag-none" />
        </div>
      </button>
      <Collapse in={open}>
        <div id={`${statement.statementId}`}>
          {feeDetail.map((fee, index) => (
            <div className="fee-item-container" key={fee.date}>
              <div className="fee-date text-color-purple font-14px-bold">
                {moment(fee.date).locale('en').format('DD-MMM-YYYY')}
              </div>
              {fee.data.map(item => (
                <div className="fee-item" key={item.feeId}>
                  <div className={'font-12px-semibold font-spacing'}>
                    {item.title}
                  </div>
                  <div
                    className={
                      'font-12px-semibold text-color-purple font-spacing'
                    }
                  >
                    ${_.round(item.amount / 100, 2)}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
};
