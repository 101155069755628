import * as Sentry from '@sentry/react';
import CheckCircle from 'assets/icons/drive_circle_check_icon.svg';
import FileIcon from 'assets/icons/drive_document_icon.svg';
import FolderIcon from 'assets/icons/drive_folder_icon.svg';
import HollowCircle from 'assets/icons/drive_hollow_circle_icon.svg';
import SmallHollowCircle from 'assets/icons/drive_hollow_circle_small.svg';
import ImageIcon from 'assets/icons/drive_image_icon_small.svg';
import PrivateDeleteIcon from 'assets/icons/drive_private_remove_icon_purple.svg';
import SmallCheckCircle from 'assets/icons/drive_ticked_circle_small.svg';
import VideoIcon from 'assets/icons/drive_video_icon_small.svg';
import 'assets/scss/Components/Drive/FileListItem.scss';
import { toastError, toastSuccess } from 'helpers';
import { getFilePreviewLink, getFileType } from 'helpers/DriveHelper';
import useBreakpoint from 'hooks/breakpoints';
import { BookmarkData, DriveFile } from 'models/Drive';
import { Dispatch, useState } from 'react';
import { removeFromBookmarkInDrive } from 'services/DriveService';

import { BookmarkButton } from '../Common/BookmarkButton';
import Image from '../Common/Image';
import Loading from '../Common/Loading';
import { DropDownMenu } from './DropDownMenu';

interface Props {
  data: DriveFile;
  isSelect: boolean;
  setSelectedItem?: (item: string[]) => void;
  setSelectedItemObject?: Dispatch<React.SetStateAction<DriveFile[]>>;
  dataOnClick?: (e) => void;
  selectedItem?: string[];
  selectedItemObject?: DriveFile[];
  isSearching?: boolean;
  getDriveListData?: () => void;
  isMoveFolderInPrivate?: boolean;
  isPrivate?: boolean;
  setIsMoveFolderDialog?: () => void;
  selectedItemInDialog?: string[];
  isBookmark?: boolean;
  isShareItem?: boolean;
  token?: string;
}

const FileListItem = ({
  isSearching,
  selectedItem,
  setSelectedItem,
  selectedItemObject,
  setSelectedItemObject,
  data,
  dataOnClick,
  isSelect,
  getDriveListData,
  isMoveFolderInPrivate,
  isPrivate,
  setIsMoveFolderDialog,
  selectedItemInDialog,
  isBookmark,
  isShareItem = false,
  token,
}: Props) => {
  // const [bookmarkLoading, setBookmarkLoading] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const { isMobile } = useBreakpoint();
  const [bookMarked, setBookMarked] = useState<boolean>(data.isBookmark);

  const selectedItemMapFunction = (items: any, data: any): boolean => {
    let checker = false;
    if (selectedItemObject) {
      selectedItemObject.forEach(item => {
        // let splitItem = item.split('/')[item.split('/').length - 1];
        if (
          item.dir === data.dir &&
          item.fileName === data.fileName &&
          item.folderType === data.folderType
        ) {
          checker = true;
        }
      });
    } else {
      items.forEach((item: any) => {
        let splitItem = item.split('/')[item.split('/').length - 1];
        if (splitItem === data.fileName) {
          checker = true;
        }
      });
    }

    return checker;
  };

  const removeFromBookmarkOnclick = async (
    e: React.MouseEvent<HTMLElement>,
    data: BookmarkData,
  ) => {
    e.stopPropagation();
    const { folderType, driveType, dir, fileName } = data;
    const params = {
      folderType,
      driveType,
      path: `${dir ? dir + '/' : ''}${fileName}`,
    };
    try {
      setRemoveLoading(true);
      await removeFromBookmarkInDrive(params);
      getDriveListData?.();
      toastSuccess('移除成功');
      setRemoveLoading(false);
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('移除失敗，請重試');
      setRemoveLoading(false);
    } finally {
      setRemoveLoading(false);
    }
  };
  return (
    <tr className="list-item-row">
      <td
        className={`${
          isSelect ? 'justify-content-start' : 'justify-content-between'
        }
        ${
          (isMoveFolderInPrivate && data.type === 'File') ||
          (isMoveFolderInPrivate && selectedItemMapFunction(selectedItem, data))
            ? 'unselectable'
            : ''
        }`}
        style={{
          background:
            isMoveFolderInPrivate && selectedItemInDialog
              ? ''
              : selectedItem?.includes(
                  `${data.folderType}/${data.dir ? data.dir + '/' : ''}${
                    data.fileName
                  }`,
                )
              ? 'rgba(255, 186, 94, 0.21)'
              : '',
        }}
      >
        {/* display checked icon when isSelecting */}
        {isSelect &&
          !isSearching &&
          (selectedItem &&
          selectedItem.includes(
            `${data.folderType}/${data.dir ? data.dir + '/' : ''}${
              data.fileName
            }`,
          ) ? (
            <div className="circle-icon-div d-flex align-items-center">
              {isMobile ? (
                <img
                  src={SmallCheckCircle}
                  alt=""
                  className=""
                  onClick={dataOnClick}
                />
              ) : (
                <img
                  src={CheckCircle}
                  alt=""
                  className=""
                  onClick={dataOnClick}
                />
              )}
            </div>
          ) : (
            <div className="circle-icon-div d-flex align-items-center">
              {isMobile ? (
                <img
                  src={SmallHollowCircle}
                  alt=""
                  className=""
                  onClick={dataOnClick}
                />
              ) : (
                <img
                  src={HollowCircle}
                  alt=""
                  className=""
                  onClick={dataOnClick}
                />
              )}
            </div>
          ))}

        {/* List All item with image */}
        <div
          className="drive-folder-item-div d-flex align-items-center w-100"
          onClick={dataOnClick}
        >
          <div className="image-preview-div">
            {!isSelect ? (
              <>
                {data.type === 'Folder' ? (
                  <div className="file-image-div">
                    <Image
                      className="preview-image"
                      src={getFilePreviewLink(
                        data.folderType,
                        data.dir,
                        `.${data.fileName}`,
                        data.driveType,
                      )}
                      alt={data.fileName}
                      draggable={false}
                      imagewidth={56}
                      imageheight={56}
                      customstyle={{
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                      fallbackimagecomponent={
                        <img
                          src={FolderIcon}
                          alt=""
                          draggable={false}
                          width={56}
                          height={56}
                        />
                      }
                    />
                  </div>
                ) : getFileType(data.fileName) === 'image' ? (
                  <div className="file-image-div file-grid-image-preview user-select-none">
                    <Image
                      className="preview-image"
                      src={getFilePreviewLink(
                        data.folderType,
                        data.dir,
                        `${data.fileName}`,
                        data.driveType,
                        token,
                      )}
                      alt={data.fileName}
                      draggable={false}
                      imagewidth={56}
                      imageheight={56}
                      customstyle={{
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                      fallbackimagecomponent={
                        <img
                          src={ImageIcon}
                          alt=""
                          className=""
                          draggable={false}
                          width={56}
                          height={56}
                        />
                      }
                    />
                  </div>
                ) : getFileType(data.fileName) === 'video' ? (
                  <div className="file-image-div">
                    <Image
                      className="preview-image"
                      src={getFilePreviewLink(
                        data.folderType,
                        data.dir,
                        `${data.fileName}`,
                        data.driveType,
                        token,
                      )}
                      alt={data.fileName}
                      draggable={false}
                      imagewidth={56}
                      imageheight={56}
                      customstyle={{
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                      fallbackimagecomponent={
                        <img
                          src={VideoIcon}
                          alt=""
                          className=""
                          draggable={false}
                          width={56}
                          height={56}
                        />
                      }
                    />
                  </div>
                ) : (
                  <div className="file-image-div">
                    <img
                      src={FileIcon}
                      alt=""
                      draggable={false}
                      width={56}
                      height={56}
                    />
                  </div>
                )}
              </>
            ) : null}
          </div>

          <div
            className={`${
              (isMoveFolderInPrivate && data.type === 'File') ||
              (isMoveFolderInPrivate &&
                selectedItemMapFunction(selectedItem, data))
                ? 'disable-color'
                : ''
            } file-name-div font-16px-medium two-line-ellipsis`}
          >
            {data.fileName}
          </div>
        </div>

        {/* Public page or private page bookmark & dropdownmenu  */}
        {!isSelect && !isSearching && (
          <div className="drive-non-selected-item-btn-div d-flex align-items-center">
            {isBookmark ? (
              <div
                className="noselect"
                onClick={e => removeFromBookmarkOnclick(e, data)}
              >
                {removeLoading ? (
                  <div>
                    <Loading playerHeight={50} playerWidth={50} />
                  </div>
                ) : (
                  <img
                    src={PrivateDeleteIcon}
                    alt="remove_icon"
                    draggable={false}
                    className="pe-2"
                  />
                )}
              </div>
            ) : data.driveType === 'public' &&
              data.type === 'File' &&
              !isShareItem ? (
              <div className="file-list-item-bookmark-container">
                <BookmarkButton
                  driveItemParams={{
                    folderType: data.folderType,
                    driveType: data.driveType,
                    path: `${data.dir ? data.dir + '/' : ''}${data.fileName}`,
                  }}
                  isBookmarked={bookMarked}
                  setBookmark={setBookMarked}
                />
              </div>
            ) : null}
            {data.driveType === 'public' && data.type === 'Folder' ? null : (
              <div className="option-div">
                <DropDownMenu
                  data={data}
                  getDriveListData={getDriveListData}
                  isPrivate={isPrivate}
                  setSelectedItemObject={setSelectedItemObject}
                  itemLink={getFilePreviewLink(
                    data.folderType,
                    data.dir,
                    data.fileName,
                    data.driveType,
                    token,
                  )}
                  notBookmarked={!bookMarked}
                  setBookmarked={setBookMarked}
                  fileType={data.type}
                  isShareItem={isShareItem}
                  setIsMoveFolderDialog={setIsMoveFolderDialog}
                  setSelectedItem={setSelectedItem}
                  token={token}
                />
              </div>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

export default FileListItem;
