import * as Sentry from '@sentry/react';
import BiggerBookMarkFalse from 'assets/icons/BiggerBookmark_false.svg';
import BiggerBookMarkTrue from 'assets/icons/BiggerBookmark_true.svg';
import BookMarkFalse from 'assets/icons/Bookmark_false.svg';
import BookMarkTrue from 'assets/icons/Bookmark_true.svg';
import BookmarkedIcon from 'assets/icons/drive_bookmark_filled_heart_icon.svg';
import HollowHeartIcon from 'assets/icons/drive_bookmark_hollow_heart_icon.svg';
import 'assets/scss/Components/CommonScss/BookmarkButton.scss';
import { DriveType, FileType } from 'models/Drive';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
  addCourseBookmark,
  removeCourseBookmark,
} from 'services/CourseService';
import {
  addToBookmarkInDrive,
  removeFromBookmarkInDrive,
} from 'services/DriveService';
import { addEventBookmark, removeEventBookmark } from 'services/EventServices';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { toastError } from '../../../helpers';

interface DriveItemParams {
  folderType: FileType;
  driveType: DriveType;
  path: string;
}

export interface BookmarkButtonProps {
  isBookmarked: boolean;
  setBookmark: Dispatch<SetStateAction<boolean>>;
  eventId?: number | string | null;
  courseId?: number | string | null;
  smallerBookmark?: boolean;
  driveItemParams?: DriveItemParams;
}

export const BookmarkButton = ({
  isBookmarked,
  eventId,
  courseId,
  smallerBookmark,
  driveItemParams,
  setBookmark,
}: BookmarkButtonProps) => {
  const { Auth } = useAppSelector((rootState: RootState) => rootState.auth);
  const [Cooldown, setCooldown] = useState<boolean>(false);

  const checkCanSendReq = useCallback(() => {
    if (!Cooldown) {
      setCooldown(true);
      setTimeout(() => {
        setCooldown(false);
      }, 1000);
      return true;
    } else {
      return false;
    }
  }, [Cooldown]);

  const sendBookmarkReq = useCallback(async () => {
    let bookmarkStatus = isBookmarked;
    if (Auth) {
      if (eventId) {
        setBookmark(!isBookmarked);
        try {
          isBookmarked
            ? await removeEventBookmark(eventId)
            : await addEventBookmark(eventId);
        } catch (err) {
          // console.log(err);
          Sentry.captureException(err);
          toastError('編輯我的最愛時發生錯誤');
          setBookmark(bookmarkStatus);
        }
      } else if (courseId) {
        setBookmark(!isBookmarked);
        try {
          isBookmarked
            ? await removeCourseBookmark(courseId)
            : await addCourseBookmark(courseId);
        } catch (err) {
          // console.log(err);
          Sentry.captureException(err);
          toastError('編輯我的最愛時發生錯誤');
          setBookmark(bookmarkStatus);
        }
      } else {
        toastError('請檢查您的網絡');
      }
    } else {
      toastError('請先登入');
    }
  }, [isBookmarked, courseId, eventId, setBookmark, Auth]);

  const snedDriveBookmarkReq = useCallback(async () => {
    if (setBookmark) {
      let bookmarkStatus = isBookmarked;
      if (Auth) {
        if (driveItemParams) {
          setBookmark(!isBookmarked);
          if (isBookmarked) {
            try {
              await removeFromBookmarkInDrive(driveItemParams);
            } catch (err) {
              // console.log(err);
              Sentry.captureException(err);
              toastError('移除失敗，請重試');
              setBookmark(bookmarkStatus);
            }
          } else {
            try {
              await addToBookmarkInDrive(driveItemParams);
            } catch (err) {
              // console.log(err);
              Sentry.captureException(err);
              toastError('新增失敗，請重試');
              setBookmark(bookmarkStatus);
            }
          }
        } else {
          toastError('請檢查您的網絡');
        }
      } else {
        toastError('請先登入');
      }
    }
  }, [Auth, isBookmarked, setBookmark, driveItemParams]);

  const handleBookmarkOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      if (checkCanSendReq()) {
        if (!driveItemParams) {
          sendBookmarkReq();
        } else {
          snedDriveBookmarkReq();
        }
      }
    },
    [checkCanSendReq, sendBookmarkReq, driveItemParams, snedDriveBookmarkReq],
  );

  return (
    <button
      onClick={handleBookmarkOnClick}
      className="bookmark  text-center"
      // tabIndex={-1}
    >
      {driveItemParams ? (
        isBookmarked ? (
          <img src={BookmarkedIcon} alt="" draggable={false} />
        ) : (
          <img src={HollowHeartIcon} alt="" draggable={false} />
        )
      ) : smallerBookmark ? (
        isBookmarked ? (
          <img
            src={BookMarkTrue}
            alt="bookmark icon"
            className="bg-white"
            draggable="false"
          />
        ) : (
          <img
            src={BookMarkFalse}
            alt="bookmark icon"
            className="bg-white"
            draggable="false"
          />
        )
      ) : isBookmarked ? (
        <img
          src={BiggerBookMarkTrue}
          alt="bookmark icon"
          className="bg-white"
          draggable="false"
        />
      ) : (
        <img
          src={BiggerBookMarkFalse}
          alt="bookmark icon"
          className="bg-white"
          draggable="false"
        />
      )}
    </button>
  );
};
