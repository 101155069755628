import { Filter, ListResult, Survey, SurveyResponse } from 'models';

import Axios from '.';

export const getOneSurvey = async (surveyId: number | string) => {
  const { data } = await Axios.get<Survey>(`/surveys/${surveyId}`);
  return data;
};

export const submitOneSurveyResponse = async (
  surveyId: number | string,
  payload: SurveyResponse,
) => {
  const { data } = await Axios.post(`/surveys/responses/${surveyId}`, payload);
  return data;
};

interface GetSurveyResponsesParams extends Filter<Survey> {
  surveyId: number | string;
}

export const getSurveyResponses = async (params: GetSurveyResponsesParams) => {
  const { data } = await Axios.get<ListResult<Survey>>('/surveys/responses', {
    params,
  });
  return data;
};
