import 'assets/scss/Components/Profile/StatementInfoRow.scss';

interface Props {
  userName?: string;
  userAgentLicenseNumber?: string;
}

export const StatementInfoRow = ({
  userName,
  userAgentLicenseNumber,
}: Props) => {
  return (
    <div className="d-flex statement-info-row">
      <div className="d-flex">
        <div className="font-14px-medium text-color-primary">
          會員名稱：
          <span className="font-14px-bold text-color-purple">{userName}</span>
        </div>
      </div>
      <div className="d-flex member-id-row">
        <div className="font-14px-medium text-color-primary">
          營業員編號：
          <span className="font-14px-bold text-color-purple">
            {userAgentLicenseNumber ?? ' - '}
          </span>
        </div>
      </div>
    </div>
  );
};
