import * as Sentry from '@sentry/react';
import AddFolderIcon from 'assets/icons/drive_add_folder_icon_purple.svg';
import MoveFileIcon from 'assets/icons/drive_move_file_icon.svg';
import PrivateDeleteIcon from 'assets/icons/drive_private_remove_icon_black.svg';
import RenameIcon from 'assets/icons/drive_rename_icon.svg';
import PurpleLens from 'assets/icons/lens.svg';
import 'assets/scss/Components/Drive/DriveFunctionBar.scss';
import { toastError, toastSuccess } from 'helpers';
import {
  addToMyFolderHelper,
  deleteFileHelper,
  isFileIncludesEXT,
} from 'helpers/DriveHelper';
import useBreakpoint from 'hooks/breakpoints';
import { DriveType, FileType } from 'models/Drive';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mvFileToDrive } from 'services/DriveService';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import Loading from '../Common/Loading';
import { Dialog } from '../Modal';
import { DriveMobileBottom } from './DriveMobileBottom';
import DriveSearchBar from './DriveSearchBar';
import { DriveSearchResult } from './DriveSearchResult';
import {
  RenameOrAddFolderForm,
  RenameOrAddFolderFormItemParams,
} from './RenameOrAddFolderForm';

interface Props {
  isSelect: boolean;
  setIsSelect: (select: boolean) => void;
  seletOnClick?: () => void;
  selectedItem: string[];
  setSelectedItem: (value: string[]) => void;
  isPrivate?: boolean;
  getDataList?: () => void;
  setIsMoveFolderDialog?: () => void;
}

export const DriveFunctionBar = ({
  isSelect,
  setIsSelect,
  selectedItem,
  setSelectedItem,
  seletOnClick,
  isPrivate,
  getDataList,
  setIsMoveFolderDialog,
}: Props) => {
  const { isDataSearched } = useAppSelector(
    (rootState: RootState) => rootState.drive,
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const { isMobile } = useBreakpoint();

  const formOnSubmit = async (values: RenameOrAddFolderFormItemParams) => {
    const { path } = values;
    setLoading(true);
    try {
      const params = {
        source: {
          folderType: 'private' as FileType,
          driveType: 'private' as DriveType,
          path: `/${selectedItem[0].split('/').slice(1).join('/')}`,
        },
        dest: {
          folderType: 'private' as FileType,
          driveType: 'private' as DriveType,
          path: `/${selectedItem[0]
            .split('/')
            .slice(1, selectedItem[0].split('/').length - 1)
            .join('/')}/${path}`,
        },
      };
      await mvFileToDrive(params);
      getDataList?.();
      setLoading(false);
      setIsRenaming(false);
      setIsSelect(false);
      toastSuccess('更改資料夾名稱成功');
      setSelectedItem([]);
    } catch (err) {
      setLoading(false);
      // console.log(err);
      Sentry.captureException(err);
      toastError('更改失敗，請重試');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="function-div-grid">
        {!isMobile
          ? isSelect && (
              <div className="show-selected-number-div">
                <div className="font-16px-bold selected-text-div">已選擇</div>
                <div className="">{`${selectedItem.length}個項目`}</div>
              </div>
            )
          : null}
        <div className="first-tier-function-div">
          {isSelect ? (
            isPrivate && !isMobile ? (
              <div className="private-selected-div">
                <div
                  className={`private-move-folder-div ${
                    selectedItem.length > 0 ? '' : 'disable-color unselectable'
                  }`}
                  onClick={() =>
                    selectedItem.length > 0 && setIsMoveFolderDialog?.()
                  }
                >
                  <img src={MoveFileIcon} alt="move-folder" draggable={false} />
                  <div className="">移至</div>
                </div>
                <div
                  className={`private-rename-folder-div ${
                    selectedItem.length > 0 &&
                    selectedItem.length < 2 &&
                    isFileIncludesEXT(selectedItem[0])
                      ? ''
                      : 'disable-color unselectable'
                  }`}
                  onClick={() =>
                    selectedItem.length > 0 &&
                    selectedItem.length < 2 &&
                    isFileIncludesEXT(selectedItem[0]) &&
                    setIsRenaming(true)
                  }
                >
                  <img src={RenameIcon} alt="move-folder" draggable={false} />
                  <div className="">重新命名</div>
                </div>
                <div
                  className={`private-delete-seleced-div ${
                    selectedItem.length > 0 ? '' : 'disable-color unselectable'
                  }`}
                  onClick={() =>
                    selectedItem.length > 0 &&
                    deleteFileHelper(
                      selectedItem,
                      getDataList!,
                      setRemoveLoading,
                      () => setIsSelect(false),
                      setSelectedItem,
                    )
                  }
                >
                  {removeLoading ? (
                    <div className="loading-div">
                      <Loading playerHeight={40} playerWidth={25} />
                    </div>
                  ) : (
                    <>
                      <img
                        src={PrivateDeleteIcon}
                        alt="delete-seleced"
                        draggable={false}
                      />
                      <div>移除</div>
                    </>
                  )}
                </div>
              </div>
            ) : isMobile ? null : (
              <div className="selected-div">
                <div
                  className="add-to-my-folder-div d-flex cursor-pointer noselect"
                  onClick={() =>
                    addToMyFolderHelper(
                      selectedItem,
                      () => setIsSelect(false),
                      () => setSelectedItem([]),
                    )
                  }
                >
                  <img src={AddFolderIcon} alt="" className="drag-none" />
                  <div className="font-16px-semibold text-color-purple">
                    新增至「我的文件」
                  </div>
                </div>
              </div>
            )
          ) : isMobile ? (
            <img
              src={PurpleLens}
              alt="search"
              onClick={() => {
                navigate('/drive/search');
              }}
            />
          ) : (
            <div className="search-bar noselect">
              <DriveSearchBar />
              {isDataSearched ? (
                <div className="searching-div">
                  <DriveSearchResult />
                </div>
              ) : null}
            </div>
          )}

          {/* {(isMobile && isMobileSearching) || !seletOnClick ? null : (
            <div className="selection-div font-16px-semibold text-color-purple col-md-1 noselect">
              <div className="text-div cursor-pointer" onClick={seletOnClick}>
                {!isDataSearched ? (isSelect ? '取消' : '選擇') : null}
              </div>
            </div>
          )} */}
          <div className="selection-div font-16px-semibold text-color-purple col-md-1 noselect">
            <div className="text-div cursor-pointer" onClick={seletOnClick}>
              {!isDataSearched ? (isSelect ? '取消' : '選擇') : null}
            </div>
          </div>
        </div>
      </div>
      {isSelect && isMobile ? (
        <DriveMobileBottom
          publicPage={!isPrivate}
          selectedItem={selectedItem}
          setIsSelect={setIsSelect}
          setSelectedItem={setSelectedItem}
          removeLoading={removeLoading}
          setIsMoveFolderDialog={setIsMoveFolderDialog}
          setIsRenaming={setIsRenaming}
          getDataList={getDataList}
          setRemoveLoading={setRemoveLoading}
        />
      ) : null}
      {isRenaming && (
        <Dialog
          visible={isRenaming}
          title={'重新命名文件夾'}
          onClose={() => setIsRenaming(false)}
          size={'w-580'}
        >
          <RenameOrAddFolderForm
            formSubmit={formOnSubmit}
            loading={loading}
            data={
              selectedItem[0].split('/')[selectedItem[0].split('/').length - 1]
            }
            isRenaming={true}
          />
        </Dialog>
      )}
    </>
  );
};
