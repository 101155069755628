import { Route, Routes } from 'react-router-dom';

import { GuestFeePaymentPage } from '../GuestFeePaymentPage/loadable';
import { GuestFeePaymentSuccessPage } from '../GuestFeePaymentSuccessPage/loadable';
import { NotFoundPageWithUI } from '../NotFoundPageWithUI/loadable';
import { AboutPage } from './AboutPage/loadable';
import { MyCardPage } from './MyCardPage/loadable';
import { PreagentPage } from './PreagentPage/loadable';
import { ProfileHistoryPage } from './ProfileHistoryPage/loadable';
import { ProfileListPage } from './ProfileListPage/loadable';
import { ScorePage } from './ScorePage/loadable';
// import { ScorePage } from './ScorePage/loadable';
import {
  SettingEditPage,
  SettingInitPage,
  SettingPasswordPage,
} from './SettingPage';
import { UserFeeDetailPage } from './UserFeeDetailPage/loadable';
import { UserFeePage } from './UserFeePage/loadable';
import { UserFeePaymentPage } from './UserFeePaymentPage/loadable';

export const ProfileRoutes = () => {
  return (
    <Routes>
      <Route index element={<ProfileListPage />}></Route>
      <Route path="/preagent" element={<PreagentPage />}></Route>
      <Route path="/mycard" element={<MyCardPage />}></Route>
      <Route path="/history" element={<ProfileHistoryPage />}></Route>
      <Route path="/fee" element={<UserFeePage />}></Route>
      <Route path="/fee/payment" element={<UserFeePaymentPage />}></Route>
      <Route path="/fee/:statementId" element={<UserFeeDetailPage />}></Route>
      <Route path="/score" element={<ScorePage />}></Route>
      <Route path="/setting" element={<SettingInitPage />}></Route>
      <Route path="/setting/edit" element={<SettingEditPage />}></Route>
      <Route path="/about" element={<AboutPage />}></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
      <Route
        path="/setting/edit-password"
        element={<SettingPasswordPage />}
      ></Route>
      <Route
        path="/fee/history/:statementId"
        element={<UserFeeDetailPage />}
      ></Route>
      <Route
        path="/guest-fee-payment"
        element={<GuestFeePaymentPage />}
      ></Route>
      <Route
        path="/guest-fee-payment-result"
        element={<GuestFeePaymentSuccessPage />}
      ></Route>
    </Routes>
  );
};
