import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import * as stripeJs from '@stripe/stripe-js';
import 'assets/scss/Components/Payment/CustomCheckoutForm.scss';
import 'assets/scss/Components/Payment/StripeCheckoutForm.scss';
import { APP_URL } from 'config';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { updateQRCodeColor, updateUserProfile } from 'store/Auth';
import { useAppDispatch } from 'store/hooks';

import { Button } from '../Common';
import { getUserPendingSum } from 'store/App';

const CustomCheckoutForm = ({
  customPayNowComponent,
  redirectURL,
  setLoading,
}: {
  customPayNowComponent?: ReactNode;
  redirectURL?: string;
  setLoading?: Dispatch<SetStateAction<boolean>>;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSubmit = async (event: any) => {
    // // console.log(event);
    setIsLoading(true);
    setLoading?.(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${
          redirectURL ? redirectURL : `${APP_URL}/events/my-events`
        }`,
      },
    });

    if (error) {
      toast.error(
        error.message || 'Encounter unknown payment error, please try again.',
      );
      setErrorMessage(
        error.message || 'Encounter unknown payment error, please try again.',
      );
    } else {
      dispatch(updateUserProfile());
      dispatch(getUserPendingSum());
      dispatch(updateQRCodeColor());
    }

    setIsLoading(false);
    setLoading?.(false);
  };

  const paymentElementOptions: stripeJs.StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
    wallets: {
      applePay: 'auto',
      googlePay: 'auto',
    },
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-checkout-form">
      <div className="stripe-form-container">
        <span className="payment-method-heading yellow-heading">付款方式</span>
        <PaymentElement
          options={paymentElementOptions}
          className="stripe-form"
        />
      </div>
      {customPayNowComponent ? (
        customPayNowComponent
      ) : (
        <Button
          id="pay-now-button"
          className={`book-now-button mt-4 `}
          type="submit"
          loading={isLoading}
          containerStyle={{
            padding: '9px 0',
            height: '24.5px',
            width: '100%',
          }}
        >
          付款
        </Button>
      )}
      {errorMessage && <div className="err-text">{errorMessage}</div>}
    </form>
  );
};

export default CustomCheckoutForm;
