import 'assets/scss/Components/Profile/OutstandingAmount.scss';
import { formatPrice, toClientPrice } from 'helpers';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Button } from '../Common';

interface Props {
  amount: number;
  dueDate?: string;
  onClick?: () => void;
  isLink?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

export const OutstandingAmountCard = ({
  amount,
  dueDate,
  onClick,
  isLink,
  loading,
  disabled,
}: Props) => {
  return (
    <div className="outstanding-amount-div card">
      <div className="outstanding-amount-text">
        <div className="d-flex left-side align-items-center">
          <div className="font-18px-semibold font-spacing">未繳費用：</div>
          <div className="text-color-purple font-spacing">
            ${_.round(amount / 100, 2)}
          </div>
        </div>
        <div className="text-color-secondary font-spacing">
          繳費到期日：
          {dueDate ? moment(dueDate).locale('en').format('DD-MMM-YYYY') : '-'}
        </div>
      </div>
      {!amount ? (
        <div className="font-18px-bold text-color-purple">已繳付</div>
      ) : (
        <div className="button-div">
          {isLink ? (
            <Link to={`/profile/fee/payment`} rel="noreferrer">
              <button
                className="button font-18px-semibold text-color-purple pay-btn"
                disabled={disabled}
              >
                繳費
              </button>
            </Link>
          ) : (
            <Button
              children={'確認繳費'}
              type="submit"
              customClass="font-18px-semibold text-color-purple pay-btn"
              onClick={onClick}
              loading={loading}
              disabled={disabled}
            ></Button>
            // <button
            //   type="submit"
            //   className="button font-18px-semibold text-color-purple"
            //   onClick={() => setIsPaymentSubmitSuccess?.(true)}
            // >
            //   {currentPage === 'payment' ? '確認繳費' : '繳費'}
            // </button>
          )}
        </div>
      )}
    </div>
  );
};
