import Fax from 'assets/icons/fax.svg';
import Location from 'assets/icons/location.svg';
import Email from 'assets/icons/mail_icon.svg';
import Phone from 'assets/icons/telephone_icon.svg';
import Website from 'assets/icons/website.svg';
import 'assets/scss/Components/Contact/ContactCard.scss';
import { getImageURL } from 'helpers';
import {
  ContactCardData,
  ContactCardflow,
  ContactIndividual,
} from 'models/Contact';
import React from 'react';

interface Props {
  data: ContactCardData;
  viewMoreOnClick?: () => void;
  depth?: number;
  currentFlow: ContactCardflow;
}

const ContactCard = (props: Props) => {
  const { data, viewMoreOnClick, depth = 1, currentFlow } = props;
  return (
    <div className="contact-card-container card d-flex flex-column">
      <div
        className={`contact-card-header d-flex flex-row justify-content-between w-100`}
      >
        <div className="title-container-div d-flex">
          <div className="title-icon-div">
            {currentFlow === 'contact' && depth === 1 ? (
              <div className="avatar-container-div">
                {data.avatar ? (
                  <img src={getImageURL(data?.avatar)} alt="avatar"></img>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="card-title-highlight" />
            )}
          </div>
          <div className="title-div">
            {currentFlow === 'info'
              ? data.title
              : depth === 2
              ? data.categoryName
              : data.name}
          </div>
        </div>
        {depth === 2 ? (
          <div className="view-more-btn" onClick={() => viewMoreOnClick?.()}>
            查看更多
          </div>
        ) : null}
      </div>
      {depth === 2 ? (
        <div className="content-container-div d-flex flex-column w-100">
          {data?.Contacts?.map((contact: ContactIndividual, index: number) => (
            <div
              className="contact-type-container d-flex flex-column w-100"
              key={index}
            >
              <div className="contact-type-title">{contact.name} :</div>
              <div className="contact-detail-container d-flex flex-column">
                {contact.phones && contact.phones[0] !== '' ? (
                  <div className="contact-div phone-div d-flex flex-wrap">
                    <img
                      className="phone contact-icon"
                      src={Phone}
                      alt="phone"
                    ></img>
                    {contact.phones.map((phone, index) => {
                      if (index === 0) {
                        return (
                          <a
                            href={`tel: ${contact.phones}`}
                            className="phone text text-wrap"
                          >
                            {contact.phones}
                          </a>
                        );
                      } else {
                        return (
                          <>
                            <span>&nbsp;/&nbsp;</span>
                            <a
                              href={`tel: ${phone}`}
                              className="phone text text-wrap"
                            >
                              {phone}
                            </a>
                          </>
                        );
                      }
                    })}
                  </div>
                ) : null}
                {contact.email && contact.email[0] !== '' ? (
                  <div className="contact-div email-div d-flex">
                    <img
                      className="email contact-icon"
                      src={Email}
                      alt="email"
                    ></img>
                    <a href={`mailto: ${contact.email}`} className="email text">
                      {contact.email}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={`content-container-div d-flex w-100 flex-column`}>
          {data.url ? (
            <div className="contact-div url-div d-flex">
              <img className="url contact-icon" src={Website} alt="url"></img>
              <a
                href={data.url}
                className="url text"
                target={'_blank'}
                rel="noreferrer"
              >
                {data.url}
              </a>
            </div>
          ) : null}
          {data.address ? (
            <div className="contact-div address-div d-flex">
              <img
                className="address contact-icon"
                src={Location}
                alt="address"
              ></img>
              <div className="address text">{data.address}</div>
            </div>
          ) : null}
          {data.phones ? (
            <div className="contact-div phone-div d-flex">
              <img className="phone contact-icon" src={Phone} alt="phone"></img>
              <div className="phone text d-flex">
                {data.phones.map((phone: string, index: number) => (
                  <React.Fragment key={index}>
                    <a href={`tel: ${phone}`} className="phone-item">
                      {phone.toString().replace(/\s/g, '')}
                    </a>
                    <span className="phone-divider">/</span>
                  </React.Fragment>
                ))}
              </div>
            </div>
          ) : null}
          {data.fax ? (
            <div className="contact-div fax-div d-flex">
              <img className="fax contact-icon" src={Fax} alt="fax"></img>
              <a href={`fax: ${data.fax}`} className="fax text">
                {data.fax}
              </a>
            </div>
          ) : null}
          {data.email ? (
            <div className="contact-div email-div d-flex">
              <img className="email contact-icon" src={Email} alt="email"></img>
              <a href={`mailto: ${data.email}`} className="email text">
                {data.email}
              </a>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ContactCard;
