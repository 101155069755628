import { Route, Routes } from 'react-router-dom';

import { NotFoundPageWithUI } from '../NotFoundPageWithUI/loadable';
import { CourseBookmarkPage } from './CourseBookmarkPage/loadable';
import { CourseDetailRoutes } from './CourseDetailPage';
import { CourseListPage } from './CourseListPage/loadable';
import { CourseMobileSearchPage } from './CourseMobileSearchPage/loadable';
import { MyCourseRoutes } from './MyCoursePage';

export const CourseRoutes = () => {
  return (
    <Routes>
      <Route index element={<CourseListPage />}></Route>
      <Route path="/my-courses/*" element={<MyCourseRoutes />}></Route>
      <Route
        path="/detail/:courseId/*"
        element={<CourseDetailRoutes />}
      ></Route>
      <Route path="/bookmark" element={<CourseBookmarkPage />}></Route>
      <Route path="/search" element={<CourseMobileSearchPage />}></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
