import Coin from 'assets/icons/coin.svg';
import 'assets/scss/Components/EventApplicationDetailPricing.scss';
import { formatPrice, numberWithCommas, toClientPrice } from 'helpers';

interface EventApplicationDetailPricingProps {
  totalPaymentAmount: number | null;
  totalMembershipPointUsed: number | null;
  pointEarnAfterApply?: number | null;
}

export const EventApplicationDetailPricing = ({
  totalPaymentAmount,
  totalMembershipPointUsed,
  pointEarnAfterApply,
}: EventApplicationDetailPricingProps) => {
  return (
    <div className="event-total-price-container d-flex flex-column align-items-end text-nowrap">
      {totalMembershipPointUsed || totalPaymentAmount ? (
        <div className="total-price-flexbox d-flex align-items-center justify-content-between w-100">
          <div className="total-price-text">總價</div>
          <div className="total-price d-flex align-items-center flex-nowrap">
            {totalPaymentAmount
              ? `HKD ${formatPrice(toClientPrice(totalPaymentAmount), true)}`
              : null}
            {totalMembershipPointUsed ? (
              <div className="d-flex align-items-center flex-nowrap">
                {totalPaymentAmount ? <span>&nbsp;+&nbsp;</span> : null}
                <img src={Coin} alt="point" />
                &nbsp;
                {numberWithCommas(totalMembershipPointUsed)}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {pointEarnAfterApply ? (
        <div className="points-earn-after-apply d-flex align-items-center">
          此訂單可獲取&nbsp;
          <img src={Coin} alt="point" />
          &nbsp;{pointEarnAfterApply}
        </div>
      ) : null}
    </div>
  );
};
