import 'assets/scss/Components/CommonScss/DisabledUser.scss';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

export interface DisabledUserProps {
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  isColorError?: boolean;
}

export const DisabledUser = ({
  setIsOpen,
  isColorError,
}: DisabledUserProps) => {
  return (
    <div className="disabled-user-card">
      {isColorError ? (
        <div className="disabled-user-card-text">讀取會籍狀態時發生錯誤</div>
      ) : (
        <>
          <div className="disabled-user-card-text">會籍已到期，請盡快繳費</div>
          <Link
            to="/profile/fee"
            className="disabled-user-card-button"
            onClick={() => setIsOpen?.(false)}
          >
            繳費
          </Link>
        </>
      )}
    </div>
  );
};
