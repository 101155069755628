import { Header, SideBar } from 'app/components';
import { BottomHeader } from 'app/components/Layout/BottomHeader';
import V1 from 'assets/images/vector-1.svg';
import V2 from 'assets/images/vector-2.svg';
import V3 from 'assets/images/vector-3.svg';
import V4 from 'assets/images/vector-4.svg';
import V5 from 'assets/images/vector-5.svg';
import 'assets/scss/layout/Layout.scss';
import { mobileNavRoutes } from 'config/mobileNavRoutes';
import { useValidMembership } from 'hooks/Membership';
import useBreakpoint from 'hooks/breakpoints';
import { useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import {
  getUserCurrentStatement,
  getUserPendingSum,
  updateCount,
} from 'store/App';
import { updateQRCodeColor } from 'store/Auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const MainLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isMobile } = useBreakpoint();
  const { auth, app } = useAppSelector((rootState: RootState) => rootState);
  const { MembershipPoint } = useAppSelector(
    (rootState: RootState) => rootState.auth,
  );
  const { Auth } = auth;
  const { isValid } = useValidMembership();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.Auth?.token) {
      dispatch(getUserCurrentStatement());
      dispatch(getUserPendingSum());
      dispatch(updateCount());
      dispatch(updateQRCodeColor());
    }
  }, [dispatch, auth.Auth?.token]);

  const NotShowNav = mobileNavRoutes.some(regex =>
    regex.test(location.pathname),
  );

  const isValidRegex = useMemo(() => {
    return [
      new RegExp(/^\/profile\/fee/),
      new RegExp(/^\/profile\/setting/),
      new RegExp(/^\/profile\/about/),
      new RegExp(/^.*\/profile\/guest-fee-payment/),
      new RegExp(/^.*\/profile\/guest-fee-payment-result/),
    ];
  }, []);

  useEffect(() => {
    if (Auth && !isValid) {
      if (!isValidRegex.some(regex => regex.test(location.pathname))) {
        navigate('/profile');
      }
    }
  }, [Auth, isValid, isValidRegex, location.pathname, navigate]);

  return (
    <div className="layout-container main">
      <img src={V1} alt="v1" className="bg-graphic-v1" draggable="false" />
      <img src={V2} alt="v2" className="bg-graphic-v2" draggable="false" />
      <img src={V3} alt="v3" className="bg-graphic-v3" draggable="false" />
      <img src={V4} alt="v4" className="bg-graphic-v4" draggable="false" />
      <img src={V5} alt="v5" className="bg-graphic-v5" draggable="false" />
      <div className="content" id="content">
        {!isMobile ? (
          <Header
            user={Auth?.user}
            statement={app.statement}
            pendingSum={app.pendingSum}
            MembershipPoint={MembershipPoint}
          />
        ) : !NotShowNav ? (
          !Auth || isValid ? (
            <BottomHeader user={Auth?.user} />
          ) : null
        ) : null}
        <Container>
          <Row>
            <Col
              lg={12}
              className="d-flex flex-row align-items-start justify-content-start"
            >
              {!Auth || isValid ? (
                <div className="d-none d-lg-flex">
                  <SideBar
                    user={Auth?.user}
                    statement={app.statement}
                    pendingSum={app.pendingSum}
                    MembershipPoint={MembershipPoint}
                  />
                </div>
              ) : null}
              <div className="flex-grow-1 content-container">
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
