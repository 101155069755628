import { IMAGE_URL } from 'config';
import { ScreenSize } from 'hooks/breakpoints';
import {
  Course,
  CourseApplicationData,
  Event,
  EventApplicationData,
  FormattedInfoCardType,
  MyCourseData,
  MyEventData,
  PreAgent,
  SliderCardData,
} from 'models';
import moment from 'moment';
import { AiFillCloseCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { MembershipPointRecord } from 'services/MembershipPointService';

moment.locale('en');

export const numberWithCommas = (num: number | undefined | null) => {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '0';
};

export const formatPrice = (price: number, withoutDigits?: boolean) => {
  if (price) {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: withoutDigits ? 0 : undefined,
      minimumFractionDigits: withoutDigits ? 0 : undefined,
    });
    return dollarUS.format(price);
  }
  return '$0';
};

export const changeDollarToPC = (originalString: string) => {
  let newString = originalString.replace('$', '');
  return newString + ' PC';
};

export const fromatEventToCardData = (event: Event): SliderCardData => {
  const {
    images,
    eventId,
    eventTitle,
    eventDescription,
    isBookmarked,
    startDate,
    eventFee,
    eventVacancy,
    memberOnly,
    //registrationDeadline,
    isCloseToDeadline,
    paymentOptions,
    isFewQuotaLeft,
  } = event;
  const data: SliderCardData = {
    images,
    eventId,
    courseId: null,
    title: eventTitle,
    courseCode: '',
    description: eventDescription,
    isBookmarked,
    displayDate: moment(startDate).locale('en').format('DD-MMM-YYYY'),
    fee: `HKD ${formatPrice(eventFee)}`,
    vacancy: eventVacancy,
    memberOnly,
    isEndSoon: isCloseToDeadline,
    paymentOptions,
    isFewQuotaLeft,
  };

  return data;
};

export const fromatCourseToCardData = (course: Course): SliderCardData => {
  const {
    images,
    courseId,
    courseTitle,
    courseCode,
    courseDescription,
    isBookmarked,
    startDate,
    endDate,
    courseFee,
    courseVacancy,
    //registrationDeadline,
    isCloseToDeadline,
    isFewQuotaLeft,
    paymentOptions,
  } = course;
  const courseStart = moment(startDate).locale('en');
  const courseEnd = moment(endDate).locale('en');
  let isSameDate = false;
  if (startDate && endDate && courseStart.isSame(courseEnd, 'date')) {
    isSameDate = true;
  }
  const data: SliderCardData = {
    images,
    eventId: null,
    courseId,
    title: courseTitle,
    courseCode,
    description: courseDescription,
    isBookmarked,
    displayDate: isSameDate
      ? courseStart.format('DD-MMM-YYYY')
      : `${courseStart.format('DD-MMM-YYYY')} 至 ${courseEnd.format(
          'DD-MMM-YYYY',
        )}`,
    fee: `HK ${formatPrice(courseFee)}`,
    vacancy: courseVacancy,
    memberOnly: false,
    isEndSoon: isCloseToDeadline,
    isFewQuotaLeft,
    paymentOptions,
  };

  return data;
};

export const formatTabWithEventData = (
  eventApplication: EventApplicationData,
): FormattedInfoCardType => {
  const { Event, eventApplicationId, Attendees } = eventApplication;

  const eventStart = moment(Event.startDate).locale('en').format('DD-MMM-YYYY');
  const eventStartTime = moment(Event.startDate).locale('en').format('HH:mm');
  const eventEnd = moment(Event.endDate).locale('en').format('DD-MMM-YYYY');
  const eventEndTime = moment(Event.endDate).locale('en').format('HH:mm');

  const data: FormattedInfoCardType = {
    Event: Event,
    eventApplicationId: eventApplicationId,
    title: Event.eventTitle,
    startDate: eventStart,
    endDate: eventEnd,
    startTime: eventStartTime,
    endTime: eventEndTime,
    // code: preagent.code,
    preAgentId: null,
    courseApplicationId: null,
    firstname: null,
    lastname: null,
    email: null,
    code: null,
    Url: Event.eventUrl,
    attendees: Attendees,
  };

  return data;
};

export const formatTabWithCourseData = (
  course: CourseApplicationData,
): FormattedInfoCardType => {
  const { Course, courseApplicationId, waitingListOrder } = course;

  const courseStart = moment(Course.startDate)
    .locale('en')
    .format('DD-MMM-YYYY');
  const courseStartTime = moment(Course.startDate).locale('en').format('HH:mm');
  const courseEnd = moment(Course.endDate).locale('en').format('DD-MMM-YYYY');
  const courseEndTime = moment(Course.endDate).locale('en').format('HH:mm');

  const data: FormattedInfoCardType = {
    preAgentId: null,
    courseApplicationId: courseApplicationId,
    eventApplicationId: null,
    title: Course.courseTitle,
    firstname: null,
    lastname: null,
    email: null,
    startDate: courseStart,
    endDate: courseEnd,
    startTime: courseStartTime,
    endTime: courseEndTime,
    code: null,
    Url: Course.courseUrl,
    waitingListOrder: waitingListOrder,
  };

  return data;
};

export const formatTabWithPreAgent = (preagent: PreAgent) => {
  const { preAgentId, firstName, lastName, email, code, hkId, studentNumber } =
    preagent;

  const data: FormattedInfoCardType = {
    preAgentId: preAgentId,
    firstname: firstName,
    lastname: lastName,
    email: email,
    code: code,
    studentNumber: studentNumber,
    hkId: hkId,

    courseApplicationId: null,
    eventApplicationId: null,
    title: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    Url: null,
  };

  return data;
};

export const formatTabWithMembershipPointRecord = (
  record: MembershipPointRecord,
) => {
  const { point, eventApplicationId, courseApplicationId, remarks } = record;

  const data: FormattedInfoCardType = {
    title: eventApplicationId ? '活動' : courseApplicationId ? '課程' : '系統',
    membershipPointEarned: point,
    courseApplicationId: courseApplicationId,
    eventApplicationId: eventApplicationId,
    remarks: remarks,
    startDate: '-',
    startTime: '-',
  };
  return data;
};

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const toastError = (text: string): void => {
  toast.error(text, { icon: AiFillCloseCircle });
};

export const toastSuccess = (text: string): void => {
  toast.success(text);
};

export const toServerPrice = (price: number): number => {
  if (price) {
    return price * 100;
  }
  return 0;
};

export const toClientPrice = (price: number): number => {
  if (price) {
    return price / 100;
  }
  return 0;
};

export const formatEventApplicationToMyEvent = (
  eventApplication: EventApplicationData,
) => {
  const {
    eventId,
    createdAt,
    deletedAt,
    updatedAt,
    eventApplicationId,
    Attendees,
  } = eventApplication;
  const MyEvent: MyEventData = {
    attendees: Attendees,
    eventId,
    eventTitle: eventApplication.Event.eventTitle,
    eventDate: moment(eventApplication.Event.startDate)
      .locale('en')
      .format('DD-MMM-YYYY'),
    eventTimeSlot:
      moment(eventApplication.Event.startDate).format('HH:mm') +
      ' - ' +
      moment(eventApplication.Event.endDate).format('HH:mm'),
    eventApplicationStatus: eventApplication.status,
    createdAt,
    deletedAt,
    updatedAt,
    eventApplicationId,
  };

  return MyEvent;
};

export const formatCourseApplicationToMyCourse = (
  courseApplication: CourseApplicationData,
) => {
  const {
    courseId,
    createdAt,
    deletedAt,
    updatedAt,
    status,
    courseApplicationId,
  } = courseApplication;
  let isSameDate = false;
  if (
    courseApplication.Course.startDate &&
    courseApplication.Course.endDate &&
    moment(courseApplication.Course.startDate).isSame(
      moment(courseApplication.Course.endDate),
      'date',
    )
  ) {
    isSameDate = true;
  }
  const MyCourse: MyCourseData = {
    courseId,
    courseTitle: courseApplication.Course.courseTitle,
    courseDatePeriod: isSameDate
      ? moment(courseApplication.Course.startDate)
          .locale('en')
          .format('DD-MMM-YYYY')
      : `${moment(courseApplication.Course.startDate)
          .locale('en')
          .format('DD-MMM-YYYY')} 至 ${moment(courseApplication.Course.endDate)
          .locale('en')
          .format('DD-MMM-YYYY')}`,
    courseTimeSlot:
      moment(courseApplication.Course.startDate).format('HH:mm') +
      ' - ' +
      moment(courseApplication.Course.endDate).format('HH:mm'),
    courseApplicationStatus: status,
    createdAt,
    deletedAt,
    updatedAt,
    courseApplicationId,
  };
  return MyCourse;
};

export const scrollToTop = () => {
  // window.scrollTo({
  //   top: 0,
  //   left: 0,
  //   behavior: 'auto',
  // });
  document.documentElement.scrollTop = 0;
};

type FontWeightType =
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold';

export const FontHelper = (
  size: ScreenSize,
  MobileFont: number,
  TabletFont: number,
  DesktopFont: number,
  FontWeight: FontWeightType,
  important?: boolean,
) => {
  if (!MobileFont && !TabletFont && !DesktopFont) {
    return '';
  }
  let TempFontWeight = '';
  switch (FontWeight) {
    case 400:
      TempFontWeight = 'normal';
      break;
    case 'normal':
      TempFontWeight = 'normal';
      break;
    case 500:
      TempFontWeight = 'medium';
      break;
    case 'medium':
      TempFontWeight = 'medium';
      break;
    case 600:
      TempFontWeight = 'semibold';
      break;
    case 'semibold':
      TempFontWeight = 'semibold';
      break;
    case 700:
      TempFontWeight = 'bold';
      break;
    case 'bold':
      TempFontWeight = 'bold';
      break;
    case 800:
      TempFontWeight = '800';
      break;
    case 900:
      TempFontWeight = '900';
      break;
  }
  switch (size) {
    case 'mobile':
      if (important) {
        return `font-${
          MobileFont ? MobileFont : DesktopFont ? DesktopFont : 16
        }px-${TempFontWeight}-important`;
      } else {
        return `font-${
          MobileFont ? MobileFont : DesktopFont ? DesktopFont : 16
        }px-${TempFontWeight}`;
      }

    case 'tablet':
      if (important) {
        return `font-${
          TabletFont ? TabletFont : DesktopFont ? DesktopFont : 16
        }px-${TempFontWeight}-important`;
      } else {
        return `font-${
          TabletFont ? TabletFont : DesktopFont ? DesktopFont : 16
        }px-${TempFontWeight}`;
      }
    case 'desktop':
      if (important) {
        return `font-${
          DesktopFont ? DesktopFont : 16
        }px-${TempFontWeight}-important`;
      } else {
        return `font-${DesktopFont ? DesktopFont : 16}px-${TempFontWeight}`;
      }
  }
};

export const CheckIsSameDate = (startDate: string, endDate: string) => {
  if (
    startDate &&
    endDate &&
    moment(startDate).locale('en').isSame(moment(endDate).locale('en'), 'date')
  ) {
    return true;
  } else {
    return false;
  }
};

export const IsEventEnded = (data: Course | Event) => {
  return moment(data.endDate).isBefore(moment(), 'day');
};
