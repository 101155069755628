//routes to hide bottom header
export const mobileNavRoutes = [
  /^.*\/profile\/fee/,
  /^.*\/profile\/guest-fee-payment/,
  // /^.*\/detail\/?/,
  /^.*\/events\/detail\/?/,
  /^.*\/events\/detail\/\d+\/application\/?/,
  /^.*\/events\/search(\?q=)?/,
  /^.*\/courses\/detail\/?/,
  /^.*\/courses\/detail\/\d+\/application\/?/,
  /^.*\/courses\/search(\?q=)?/,
  /^.*\/home\/banner\/?/,
  /^.*\/ranking\/?/,
  /^.*\/edit-password\/?/,
  /^.*\/profile\/setting\/edit\/?/,
  /^.*\/profile\/preagent\/?/,
  /^.*\/profile\/mycard\/?/,
  /^.*\/drive\/public(\?|\/)?/,
  /^.*\/drive\/private(\?|\/)?/,
  /^.*\/drive\/search\/?/,
  /^.*\/survey(\?|\/)?/,
];
