import 'assets/scss/Components/CommonScss/MobileBottomButton.scss';
import { CSSProperties } from 'react';

interface MobileBottomButtonProps {
  children: React.ReactNode;
  customContainerStyle?: Partial<CSSProperties>;
}

export const MobileBottomButton = ({
  children,
  customContainerStyle,
}: MobileBottomButtonProps) => {
  return (
    <div className="mobile-bottom-button" style={{ ...customContainerStyle }}>
      <>{children}</>
    </div>
  );
};
