import FolderIcon from 'assets/icons/drive_folder_icon.svg';
import 'assets/scss/Components/Drive/DriveEmptyPage.scss';

export const DriveEmptyPage = () => {
  return (
    <div className="empty-page-container noselect">
      <div className="image-div">
        <img
          src={FolderIcon}
          alt="folder-icon"
          width={75}
          height={75}
          draggable={false}
        />
      </div>
      <div className="text-div font-20px-semibold">資料夾暫無檔案</div>
    </div>
  );
};
