import { SurveyPage } from 'app/pages/HomePage/SurveyPage/loadable';
import { NotFoundPageWithUI } from 'app/pages/NotFoundPageWithUI/loadable';
import { Route, Routes } from 'react-router-dom';

import { EventApplicationDetailPage } from './EventApplicationDetailPage/loadable';
import { MyEventListPage } from './MyEventListPage/loadable';

export const MyEventRoutes = () => {
  return (
    <Routes>
      <Route index element={<MyEventListPage />}></Route>
      <Route
        path="/application/:eventApplicationId"
        element={<EventApplicationDetailPage />}
      ></Route>
      <Route
        path="/application/:eventApplicationId/survey/:surveyId"
        element={<SurveyPage />}
      ></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
