import { NotFoundPageWithUI } from 'app/pages/NotFoundPageWithUI/loadable';
import { Route, Routes } from 'react-router-dom';

import { NewsDetailPage } from './NewsDetailPage/loadable';
import { NewsListPage } from './NewsListPage/loadable';

export const NewsRoutes = () => {
  return (
    <Routes>
      <Route index element={<NewsListPage />}></Route>
      <Route path="/detail/:newsId" element={<NewsDetailPage />}></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
