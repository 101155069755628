import { activeCardType } from 'app/pages/ProfilePage/ProfileHistoryPage';
import Close from 'assets/icons/close.svg';
import 'assets/scss/Components/Modal/QRcodeModal.scss';
import { EventAttendees, FormattedInfoCardType, User } from 'models';
import { Dispatch, SetStateAction } from 'react';
import { Modal } from 'reactstrap';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { EffectCoverflow, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss/effect-coverflow';

import { QRcodeSliderCard } from '../../components/Event/QRcodeSliderCard';

interface QRcodeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  activeCard?: activeCardType | null;
  attendees?: EventAttendees[];
  preAgent?: FormattedInfoCardType;
  user?: User;
}

export const QRcodeModal = (props: QRcodeModalProps) => {
  const { isOpen, setIsOpen, attendees, preAgent, user } = props;

  const { qrCodeColor } = useAppSelector(
    (rootState: RootState) => rootState.auth,
  );

  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
      <div
        id={`qrcode-modal ${user ? 'user-qrcode-modal' : undefined}`}
        className={`qrcode-modal modal-body d-flex flex-column ${
          user ? 'user-qrcode-modal' : undefined
        }`}
      >
        {user ? null : (
          <div className="modal-header d-flex flex-column">
            <div
              className="close-btn align-self-end cursor-pointer drag-none"
              onClick={() => setIsOpen(false)}
            >
              <img src={Close} alt="close" className="drag-none" />
            </div>
            <div className="instruction-text align-self-start">
              {attendees ? '入場前請出示憑證' : null}
            </div>
          </div>
        )}
        <div className="qr-code-modal-content d-flex justify-content-center">
          {attendees || preAgent || user ? (
            user ? (
              <div className="qr-code-card-slide">
                <QRcodeSliderCard
                  isActive={false}
                  data={{
                    name: user.displayName,
                    code: user.qrcode || null,
                  }}
                  currentUse="user"
                  color={qrCodeColor?.color}
                  setIsOpen={setIsOpen}
                />
              </div>
            ) : (
              <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                allowTouchMove={true}
                slidesPerView={'auto'}
                navigation={true}
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="qrcodes-swiper"
              >
                {attendees
                  ? attendees.map((attendee, index) => {
                      return (
                        <SwiperSlide key={index} className="qr-code-card-slide">
                          {isActive => (
                            <QRcodeSliderCard
                              isActive={true}
                              data={{
                                name:
                                  attendee.User?.displayName || attendee.name,
                                code: attendee.code,
                              }}
                              checkin={attendee.checkInDateTime}
                              checkout={attendee.checkOutDateTime}
                            />
                          )}
                        </SwiperSlide>
                      );
                    })
                  : null}
                {preAgent ? (
                  <SwiperSlide className="qr-code-card-slide">
                    <QRcodeSliderCard
                      isActive={true}
                      data={{
                        name: preAgent.firstname ? preAgent.firstname : '',
                        code: preAgent.code ? preAgent.code : '',
                      }}
                      currentUse="preagent"
                    />
                  </SwiperSlide>
                ) : null}
              </Swiper>
            )
          ) : null}
          {/* <div className="swiper-pagination"></div> */}
        </div>
      </div>
    </Modal>
  );
};
