import * as Sentry from '@sentry/react';
import Logo from 'assets/icons/SV_middle_button.svg';
import Noti from 'assets/icons/noti.svg';
import VibesLogo from 'assets/icons/super_vibes.svg';
import 'assets/scss/layout/Header.scss';
import { useValidMembership } from 'hooks/Membership';
import useBreakpoint from 'hooks/breakpoints';
import { Statement, User } from 'models';
import { useCallback, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, NavLink } from 'react-router-dom';
import { ListAllNotification } from 'services/NotificationServices';
import { PendingSum } from 'services/UserService';
import { RootState } from 'store';
import { setHasUnreadMsg } from 'store/App';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { QRcodeModal } from '../Modal';
import { SideBar } from './SideBar';

interface HeaderProps {
  user?: User;
  statement: Statement | null;
  pendingSum: PendingSum | null;
  MembershipPoint: number | null;
}

export const Header = ({
  user,
  statement,
  pendingSum,
  MembershipPoint,
}: HeaderProps) => {
  const { Auth } = useAppSelector((rootState: RootState) => rootState.auth);
  const dispatch = useAppDispatch();
  const [isQrOpen, setIsQrOpen] = useState<boolean>(false);
  const [BurgerOpen, setBurgerOpen] = useState<boolean>(false);
  const { hasUnreadMsg } = useAppSelector(
    (rootState: RootState) => rootState.app,
  );
  const { size, isMobile } = useBreakpoint();
  const { isValid } = useValidMembership();
  const LGsize = 'lg';

  const getIsNotRead = useCallback(async () => {
    try {
      const notiRes = await ListAllNotification({
        limit: 5,
        offset: 0,
        isRead: false,
      });
      if (notiRes.count > 0) {
        dispatch(setHasUnreadMsg(true));
      } else {
        dispatch(setHasUnreadMsg(false));
      }
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isMobile && Auth) {
      getIsNotRead();
      const interval = setInterval(() => {
        getIsNotRead();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [getIsNotRead, Auth, isMobile]);

  useEffect(() => {
    setBurgerOpen(false);
  }, [size]);

  return (
    <Navbar collapseOnSelect expand={LGsize} bg="light" variant="light">
      {isQrOpen ? (
        <QRcodeModal isOpen={isQrOpen} setIsOpen={setIsQrOpen} user={user} />
      ) : null}
      <Container>
        {!Auth || isValid ? (
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${LGsize}`}
            className="nav-toggle-btn"
            onClick={() => setBurgerOpen(!BurgerOpen)}
          />
        ) : null}

        <Link to="/" className={`d-none d-${LGsize}-flex`}>
          <img
            src={VibesLogo}
            className="d-inline-block align-top"
            width={232}
            height={33}
            alt="Super Vibes logo"
          />
        </Link>

        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${LGsize}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${LGsize}`}
          placement="start"
          show={size === 'tablet' && BurgerOpen}
          onHide={() => setBurgerOpen(false)}
        >
          <Offcanvas.Body>
            <Nav
              className={`${
                size === 'tablet' ? undefined : 'justify-content-end'
              } flex-grow-1 d-${LGsize}-none`}
            >
              <SideBar
                user={user}
                statement={statement}
                pendingSum={pendingSum}
                MembershipPoint={MembershipPoint}
              />
            </Nav>
            <Nav
              className={`justify-content-end flex-grow-1 d-none d-${LGsize}-flex`}
            >
              {Auth ? (
                <NavLink to="/notification" className={'noti-icon-container'}>
                  <img
                    src={Noti}
                    alt="notification icon"
                    className="noti-icon"
                  />
                  {hasUnreadMsg ? (
                    <span className="noti-icon-red-dot"></span>
                  ) : null}
                </NavLink>
              ) : null}
              <button
                className="clean-btn ms-3"
                disabled={!(Auth && Auth.user?.userType === '會員')}
                onClick={() => {
                  setIsQrOpen(!isQrOpen);
                }}
              >
                <img src={Logo} alt="QR code icon" className="drag-none" />
              </button>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>

        <Nav
          className={`flex-row justify-content-end flex-grow-1 d-flex d-${LGsize}-none`}
        >
          {Auth ? (
            <NavLink to="/notification" className={'noti-icon-container'}>
              <img src={Noti} alt="notification icon" className="noti-icon" />
              {hasUnreadMsg ? (
                <span className="noti-icon-red-dot"></span>
              ) : null}
            </NavLink>
          ) : null}
          <button
            className="clean-btn ms-3"
            disabled={!(Auth && Auth?.user?.userType === '會員')}
            onClick={() => setIsQrOpen(!isQrOpen)}
          >
            <img src={Logo} alt="QR code icon" className="drag-none" />
          </button>
        </Nav>
      </Container>
    </Navbar>
  );
};
