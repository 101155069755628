import AddFolderIcon from 'assets/icons/black_add_new.svg';
import MoveFileIcon from 'assets/icons/drive_move_file_icon.svg';
import PrivateDeleteIcon from 'assets/icons/drive_private_remove_icon_black.svg';
import RenameIcon from 'assets/icons/drive_rename_icon.svg';
import 'assets/scss/Components/Drive/DriveMobileBottom.scss';
import {
  addToMyFolderHelper,
  deleteFileHelper,
  isFileIncludesEXT,
} from 'helpers/DriveHelper';

import Loading from '../Common/Loading';

interface DriveMobileBottomProps {
  publicPage: boolean;
  selectedItem: string[];
  setIsSelect: (select: boolean) => void;
  setSelectedItem: (value: string[]) => void;
  removeLoading: boolean;
  setIsMoveFolderDialog: (() => void) | undefined;
  setIsRenaming: React.Dispatch<React.SetStateAction<boolean>>;
  getDataList: (() => void) | undefined;
  setRemoveLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DriveMobileBottom = ({
  publicPage,
  setIsSelect,
  setSelectedItem,
  selectedItem,
  removeLoading,
  setIsMoveFolderDialog,
  setIsRenaming,
  getDataList,
  setRemoveLoading,
}: DriveMobileBottomProps) => {
  return (
    <div className="drive-mobile-bottom">
      {publicPage ? (
        <div className="drive-mobile-bottom-public">
          <div className="show-selected-number-div d-flex align-items-center">
            <span className="font-16px-bold selected-text-div">已選擇</span>
            <span className="">{`${selectedItem.length}個項目`}</span>
          </div>
          <hr className="drive-mobile-bottom-seperation-line w-100" />
          <div className="selected-div">
            <div
              className="add-to-my-folder-div d-flex align-items-center cursor-pointer noselect"
              onClick={() =>
                addToMyFolderHelper(
                  selectedItem,
                  () => setIsSelect(false),
                  () => setSelectedItem([]),
                )
              }
            >
              <img src={AddFolderIcon} alt="" className="drag-none" />
              <div className="font-16px-medium">新增至「我的文件」</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="drive-mobile-bottom-private ">
          <div className="show-selected-number-div d-flex align-items-center">
            <span className="font-16px-bold selected-text-div">已選擇</span>
            <span className="">{`${selectedItem.length}個項目`}</span>
          </div>
          <hr className="drive-mobile-bottom-seperation-line w-100" />
          <div className="selected-div">
            <div className="private-selected-div">
              <div
                className={`private-move-folder-div ${
                  selectedItem.length > 0 ? '' : 'disable-color unselectable'
                }`}
                onClick={() =>
                  selectedItem.length > 0 && setIsMoveFolderDialog?.()
                }
              >
                <img src={MoveFileIcon} alt="move-folder" draggable={false} />
                <div className="">移至</div>
              </div>
              <div
                className={`private-rename-folder-div ${
                  selectedItem.length > 0 &&
                  selectedItem.length < 2 &&
                  isFileIncludesEXT(selectedItem[0])
                    ? ''
                    : 'disable-color unselectable'
                }`}
                onClick={() =>
                  selectedItem.length > 0 &&
                  selectedItem.length < 2 &&
                  isFileIncludesEXT(selectedItem[0]) &&
                  setIsRenaming(true)
                }
              >
                <img src={RenameIcon} alt="move-folder" draggable={false} />
                <div className="">重新命名</div>
              </div>
              <div
                className={`private-delete-seleced-div ${
                  selectedItem.length > 0 ? '' : 'disable-color unselectable'
                }`}
                onClick={() =>
                  selectedItem.length > 0 &&
                  deleteFileHelper(
                    selectedItem,
                    getDataList!,
                    setRemoveLoading,
                    () => setIsSelect(false),
                    setSelectedItem,
                  )
                }
              >
                {removeLoading ? (
                  <div className="loading-div">
                    <Loading playerHeight={40} playerWidth={25} />
                  </div>
                ) : (
                  <>
                    <img
                      src={PrivateDeleteIcon}
                      alt="delete-seleced"
                      draggable={false}
                    />
                    <div>移除</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
