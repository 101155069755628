import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { toastError } from 'helpers';
import { QRCodeColor, User, UserLoginPayload } from 'models';
import { NavigateFunction } from 'react-router-dom';
import { getQRCodeColor, getUserProfile, logout } from 'services/UserService';
import { AppThunk } from 'store';

interface AuthState {
  error: string | null;
  success: string | null;
  loginLoading: boolean;
  loginError: string;
  Auth: UserLoginPayload | null;
  MembershipPoint: number | null;
  qrCodeColor: QRCodeColor | null;
}

const initialState = {
  error: '',
  success: 'ok',
  loginLoading: false,
  Auth: null,
  MembershipPoint: null,
  qrCodeColor: null,
} as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart(state) {
      state.loginLoading = true;
      state.loginError = '';
    },
    loginSuccess(state) {
      state.loginLoading = false;
      state.loginError = '';
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginLoading = false;
      state.loginError = action.payload;
    },
    setProfile(state, action: PayloadAction<UserLoginPayload | null>) {
      state.Auth = action.payload;
    },
    setMembershipPoint(state, action: PayloadAction<number | null>) {
      state.MembershipPoint = action.payload;
    },
    setUserQRCodeColor(state, action: PayloadAction<QRCodeColor | null>) {
      state.qrCodeColor = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFail,
  setProfile,
  setMembershipPoint,
  setUserQRCodeColor,
} = authSlice.actions;
export default authSlice.reducer;

export const updateUserProfile = (): AppThunk => async (dispatch, getState) => {
  const { auth } = getState();
  try {
    const userProfileRes = await getUserProfile();
    const tempAuth: UserLoginPayload = {
      token: auth.Auth?.token || '',
      user: userProfileRes,
    };
    dispatch(setProfile(tempAuth));
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const updateMembershipPoint =
  (profile?: User | null): AppThunk =>
  async (dispatch, getState) => {
    if (profile) {
      dispatch(setMembershipPoint(profile.membershipPoint));
    } else if (profile === null) {
      dispatch(setMembershipPoint(null));
    } else {
      try {
        const userProfileRes = await getUserProfile();
        dispatch(setMembershipPoint(userProfileRes.membershipPoint));
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  };

export const updateQRCodeColor = (): AppThunk => async dispatch => {
  try {
    const qrCodeColor = await getQRCodeColor();
    dispatch(setUserQRCodeColor(qrCodeColor));
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const Logout =
  (navigate: NavigateFunction): AppThunk =>
  async (dispatch, getState) => {
    try {
      await logout();
      dispatch(setProfile(null));
      dispatch(setMembershipPoint(null));
      dispatch(setUserQRCodeColor(null));
      navigate('/auth');
      localStorage.removeItem('LastPopUpDate');
    } catch (err) {
      Sentry.captureException(err);
      // console.log(err);
      toastError('登出失敗');
    }
  };
