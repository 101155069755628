import NoRankingDataIcon from 'assets/icons/no_ranking.svg';
import 'assets/scss/Components/Home/NoPerformanceRankData.scss';

export const NoPerformanceRankData = () => {
  return (
    <div className="h-100 w-100 d-flex align-items-center justify-content-center no-ranking-data">
      <img
        src={NoRankingDataIcon}
        alt="No Ranking Data"
        className="no-ranking-data-icon"
      />
      <div className="d-flex flex-column align-items-center font-18px-bold no-ranking-data-text">
        <span className="no-ranking-data-text-first">目前沒有相關資料顯示</span>
        <span className="no-ranking-data-text-second">
          請上傳業績總數以作計算
        </span>
      </div>
    </div>
  );
};
