import * as Sentry from '@sentry/react';
import { PageLoading } from 'app/components';
import { CustomBreadcrumbs } from 'app/components/Common/CustomBreadcrumbs';
import CustomPagination from 'app/components/Common/CustomPagination';
import { EmptyResultPage } from 'app/components/Common/EmptyResultPage';
import { MobileHeader } from 'app/components/Common/MobileHeader';
import { ScrollLoadingSpinner } from 'app/components/Common/ScrollLoadingSpinner';
import ContactCard from 'app/components/Contact/ContactCard';
import 'assets/scss/Pages/ContactPage/ContactDetailPage.scss';
import { PAGE_SIZE } from 'config';
import { toastError } from 'helpers';
import { resetInfiniteScrolling } from 'helpers/infiniteScrollHelper';
import useInfiniteScroll from 'hooks/InfiniteScroll';
import useBreakpoint from 'hooks/breakpoints';
import { ContactIndividual } from 'models/Contact';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getDetailContact, // getDetailContactParams,
  getOneContactCategory,
} from 'services/ContactService';

export const ContactDetailPage = () => {
  const params = useParams();
  const [contactListData, setContactListData] = useState<ContactIndividual[]>(
    [],
  );
  const [contactListDataMobile, setContactListDataMobile] = useState<
    ContactIndividual[]
  >([]);
  const [contactsCount, setContactCount] = useState<number>(0);
  const [ActivePage, setActivePage] = useState<number>(1);
  const [loadingContactList, setLoadingContactList] = useState<boolean>(true);
  const [LoadingCategoryName, setLoadingCategoryName] = useState<boolean>(true);
  const [CategoryName, setCategoryName] = useState<string>();
  const { size, isMobile } = useBreakpoint();
  const firstTimeLoading = useRef(true);
  const renderedMobilePage = useRef<number[]>([]);
  const [InfScrollInactive, setInfScrollInactive] = useState<boolean>(true);

  useInfiniteScroll({
    setActivePage: setActivePage,
    loading: loadingContactList,
    maxPage: Math.ceil(contactsCount / PAGE_SIZE),
    ActivePage: ActivePage,
    firstTimeLoading: firstTimeLoading.current,
    InActive: InfScrollInactive,
  });

  const getContactList = useCallback(async () => {
    setLoadingContactList(true);
    try {
      if (ActivePage > 0) {
        const listDataRes = await getDetailContact({
          limit: PAGE_SIZE,
          offset: (ActivePage - 1) * PAGE_SIZE,
          isDisplayOnListingOnly: false,
          asc: 'ordering',
          contactCategoryId: params
            ? Number(params.contactCategoryId)
            : undefined,
        });
        setContactListData(listDataRes.rows);
        if (
          !renderedMobilePage.current.includes(ActivePage) &&
          (renderedMobilePage.current.includes(ActivePage - 1) ||
            firstTimeLoading.current ||
            renderedMobilePage.current.length <= 0)
        ) {
          setContactListDataMobile(prevData =>
            prevData.concat(listDataRes.rows),
          );
          renderedMobilePage.current.push(ActivePage);
        }
        setContactCount(listDataRes.count);
        firstTimeLoading.current = false;
      }
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('獲取聯絡資料失敗');
    } finally {
      setLoadingContactList(false);
      setInfScrollInactive(false);
    }
  }, [ActivePage, params]);

  const getCategoryName = useCallback(async () => {
    setLoadingCategoryName(true);
    if (params) {
      try {
        const categoryNameRes = await getOneContactCategory(
          Number(params.contactCategoryId),
        );
        setCategoryName(categoryNameRes.categoryName);
      } catch (err) {
        // console.log(err);
        Sentry.captureException(err);
        toastError('獲取聯絡資料類別失敗');
      } finally {
        setLoadingCategoryName(false);
      }
    }
  }, [params]);

  useEffect(() => {
    if (ActivePage <= 0) {
      setActivePage(1);
    }
  }, [ActivePage]);

  useEffect(() => {
    if (!firstTimeLoading.current) {
      resetInfiniteScrolling<ContactIndividual>({
        setLoading: setLoadingContactList,
        firstTimeLoading: firstTimeLoading,
        renderedMobilePage: renderedMobilePage,
        setData: setContactListData,
        setDataMobile: setContactListDataMobile,
        setActivePage: setActivePage,
        setTotalCount: setContactCount,
        setInfScrollInactive: setInfScrollInactive,
      });
    }
  }, [size, params]);

  useEffect(() => {
    if (params) {
      getContactList();
    }
  }, [getContactList, params]);

  useEffect(() => {
    if (params) {
      getCategoryName();
    }
  }, [getCategoryName, params]);

  if (
    (loadingContactList || LoadingCategoryName) &&
    ((isMobile && firstTimeLoading.current) || !isMobile)
  ) {
    return (
      <div className="contact-detail-page-container page-container-with-header">
        {isMobile ? (
          <>
            <MobileHeader
              title={`${CategoryName ? CategoryName : '聯絡資訊'}`}
              prevPageRoute={'/home/contacts'}
            />
          </>
        ) : null}
        <PageLoading />
      </div>
    );
  }

  if (!loadingContactList) {
    if (isMobile && contactListDataMobile.length <= 0) {
      return (
        <>
          <MobileHeader
            title={`${CategoryName ? CategoryName : '聯絡資訊'}`}
            prevPageRoute={'/home/contacts'}
          />
          <EmptyResultPage />
        </>
      );
    } else if (!isMobile && contactListData.length <= 0) {
      return (
        <>
          <EmptyResultPage />
        </>
      );
    }
  }

  if (!loadingContactList && !LoadingCategoryName) {
    firstTimeLoading.current = false;
  }

  const Breadcrumb = [
    {
      title: '主頁',
      href: '/home',
    },
    {
      title: '常用聯絡',
      href: '/home/contacts',
    },
    {
      title: `${CategoryName ? CategoryName : '資訊'}`,
      active: true,
    },
  ];

  return (
    <div className="contact-detail-page-container page-container-with-header">
      {isMobile ? (
        <MobileHeader
          title={`${CategoryName ? CategoryName : '聯絡資訊'}`}
          prevPageRoute={'/home/contacts'}
        />
      ) : (
        <div className="contact-detail-page-breadcrumb w-100">
          <CustomBreadcrumbs items={Breadcrumb} />
        </div>
      )}
      <>
        {isMobile ? (
          <>
            {contactListDataMobile.map(
              (info: ContactIndividual, index: number) => (
                <ContactCard data={info} currentFlow="contact" key={index} />
              ),
            )}
            {loadingContactList ? <ScrollLoadingSpinner /> : null}
          </>
        ) : (
          contactListData.map((info: ContactIndividual, index: number) => (
            <ContactCard data={info} currentFlow="contact" key={index} />
          ))
        )}

        {isMobile ? null : (
          <CustomPagination
            count={contactsCount}
            current={ActivePage}
            onChange={(page: number) => {
              setActivePage(page);
            }}
          />
        )}
      </>
    </div>
  );
};
