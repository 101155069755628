import { default as PrizeBronze } from 'assets/images/prize-bronze.png';
import { default as PrizeGold } from 'assets/images/prize-gold.png';
import { default as PrizeSilver } from 'assets/images/prize-silver.png';
import 'assets/scss/Components/Home/PerformanceBodyFirstThree.scss';
import { FontHelper, changeDollarToPC, formatPrice } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { RankingData } from 'models';
import { Placeholder } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import UserAvatar from '../Common/UserAvatar';

interface PerformanceBodyFirstThreeProps {
  data?: RankingData;
  placement?: number;
  loading?: boolean;
}

export const PerformanceBodyFirstThree = ({
  data,
  placement,
  loading,
}: PerformanceBodyFirstThreeProps) => {
  const { size, width } = useBreakpoint();
  const navigate = useNavigate();

  const calcAvatarSize = (placement: number | undefined) => {
    if (placement && placement === 1) {
      if (width <= 768) return '110px';
      else if (width <= 1200 && width > 768) return '150px';
      else return '200px';
    } else {
      if (width <= 768) return '85px';
      else if (width <= 1200 && width > 768) return '130px';
      else return '172px';
    }
  };

  const calcPrizeSize = (placement: number | undefined) => {
    if (placement && placement === 1) {
      if (width <= 768) return '27px';
      else if (width <= 1200 && width > 768) return '38px';
      else return '49.17px';
    } else {
      if (width <= 768) return '25px';
      else if (width <= 1200 && width > 768) return '37px';
      else return '50px';
    }
  };

  const TopThreeOnClick = () => {
    if (data && data.user && data.user.userId && data.type) {
      navigate(`/home/ranking?userid=${data.user.userId}&type=${data.type}`);
    }
  };
  return (
    <div
      className={`${
        placement === 1 ? 'top-one-info' : 'second-third-info'
      } top-three-info`}
    >
      {loading ? (
        <div>
          <Placeholder animation="glow" className="name-placeholder">
            <Placeholder />
          </Placeholder>
          <div className="mb-3 position-relative">
            <UserAvatar
              loading={loading}
              width={calcAvatarSize(placement)}
              height={calcAvatarSize(placement)}
            />
            <img
              src={
                placement === 1
                  ? PrizeGold
                  : placement === 2
                  ? PrizeSilver
                  : PrizeBronze
              }
              alt={'prize'}
              className={`prize ${
                placement === 1 ? 'prize-first' : 'prize-other'
              }`}
              width={calcPrizeSize(placement)}
            />
          </div>
          <Placeholder animation="glow" className="title-placeholder">
            <Placeholder />
          </Placeholder>
          <Placeholder animation="glow" className="amount-placeholder">
            <Placeholder />
          </Placeholder>
        </div>
      ) : (
        <div className="text-dark cursor-pointer" onClick={TopThreeOnClick}>
          <div
            className={`mb-3 ${FontHelper(size, 14, 16, 18, 'bold')} ${
              placement === 1 ? 'text-color-darkorange' : undefined
            } top-three-text two-line-ellipsis`}
          >
            {data ? data.user.displayName : '-'}
          </div>
          <div className="mb-3 position-relative">
            <UserAvatar
              avatar={data ? data.user.avatar : null}
              displayName={data ? data.user.displayName : null}
              width={calcAvatarSize(placement)}
              height={calcAvatarSize(placement)}
            />
            <img
              src={
                placement === 1
                  ? PrizeGold
                  : placement === 2
                  ? PrizeSilver
                  : PrizeBronze
              }
              alt={'prize'}
              className={`prize ${
                placement === 1 ? 'prize-first' : 'prize-other'
              }`}
              width={calcPrizeSize(placement)}
            />
          </div>
          <div
            className={`mb-2 ${FontHelper(
              size,
              12,
              14,
              16,
              'medium',
            )} top-three-text ${
              placement === 1 ? 'text-color-darkorange' : undefined
            } one-line-ellipsis`}
          >
            {data ? (data.user.jobTitle ? data.user.jobTitle : '-') : '-'}
          </div>

          <div
            className={`text-nowrap amount-div ${FontHelper(
              size,
              14,
              18,
              24,
              'bold',
            )} one-line-ellipsis
              top-three-text ${
                placement === 1 ? 'text-color-darkorange' : undefined
              }`}
          >
            {data
              ? changeDollarToPC(formatPrice(data.totalAmount, true))
              : '0 PC'}
          </div>
        </div>
      )}
    </div>
  );
};
