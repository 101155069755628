import 'assets/scss/Components/CommonScss/Breadcrumbs.scss';
import { Breadcrumb, BreadcrumbProps } from 'react-bootstrap';

export interface BreadcrumbPropsItem extends BreadcrumbProps {
  href?: string;
}

interface CustomBreadcrumbsProps {
  items: BreadcrumbPropsItem[];
}

export const CustomBreadcrumbs = ({ items }: CustomBreadcrumbsProps) => {
  return (
    <div className="w-100 d-flex flex-row align-items-center justify-content-start breadcrumb-div">
      <Breadcrumb>
        {items.map((item, index) => (
          <Breadcrumb.Item {...item} key={index}>
            {item.title}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};
