import { scrollToTop } from 'helpers';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnRefresh = () => {
  const location = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [location]);

  useEffect(() => {
    window.addEventListener('unload', scrollToTop);
    return () => {
      window.removeEventListener('unload', scrollToTop);
    };
  }, []);

  return null;
};

export default ScrollToTopOnRefresh;
