import CancelBtn from 'assets/icons/input_cancel_btn.svg';
import clsx from 'clsx';
import flatpickr from 'flatpickr';
import locale from 'flatpickr/dist/l10n/zh-tw';
import 'flatpickr/dist/themes/confetti.css';
import { FieldHookConfig, useField } from 'formik';
import Flatpickr from 'react-flatpickr';

// @ts-ignore
flatpickr.localize(locale);

interface OtherProps {
  label: string;
  placeholder?: string;
  minDate?: Date | Date[] | string[] | null;
  maxDate?: Date | Date[] | string[] | null;
  onChange?: (value) => void;
  enableTimeInCAL?: boolean;
  disabled?: boolean;
  horizontal?: boolean;
  customPlaceHolder?: boolean;
  customPlaceHolderText?: string;
  requiredSymbol?: boolean;
}

const DateField = (props: OtherProps & FieldHookConfig<Date[] | null>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    minDate,
    maxDate,
    onChange,
    enableTimeInCAL,
    disabled,
    horizontal,
    customPlaceHolder,
    customPlaceHolderText,
    requiredSymbol,
  } = props;
  return (
    <div
      className={clsx(
        'form-group',
        meta.touched && meta.error && 'text-danger',
        horizontal && 'float-start',
      )}
    >
      <div className="d-flex justify-content-between w-100">
        {label ? (
          <div className="position-relative">
            <label className="font-16px-semibold">{props.label}</label>
            {requiredSymbol ? (
              <div className="required-symbol-div text-color-warning">*</div>
            ) : null}
          </div>
        ) : null}
        {customPlaceHolder ? (
          <div className="label-placeholder-div font-10px-medium text-color-purple ">
            {customPlaceHolderText}
          </div>
        ) : null}
      </div>
      <div className="d-flex flex-row w-100">
        <div className="w-100 input-div-wrapper">
          <Flatpickr
            className="form-control d-block"
            placeholder={placeholder || '日期'}
            options={{
              altInput: true,
              altFormat: enableTimeInCAL ? 'd-m-Y H:i' : 'd-m-Y',
              dateFormat: enableTimeInCAL ? 'd-m-Y H:i' : 'd-m-Y',
              minDate: minDate ? minDate[0] : null,
              maxDate: maxDate ? maxDate[0] : null,
              enableTime: enableTimeInCAL ? true : false,
              locale: 'zh_tw',
            }}
            disabled={disabled}
            value={field.value || undefined}
            onChange={date => {
              if (onChange) {
                onChange(date);
              } else {
                if (date[0]) {
                  helpers.setValue(date);
                } else {
                  helpers.setValue(null);
                }
              }
            }}
          />
          {field.value ? (
            <div className="cancel-input-btn-div">
              <img
                src={CancelBtn}
                alt=""
                className=""
                onClick={() => helpers.setValue(null)}
              />
            </div>
          ) : null}
        </div>
      </div>
      {meta.touched && meta.error ? (
        <div className="font-12px-medium mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default DateField;
