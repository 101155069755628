import { IMAGE_SIZE } from 'config';
import { FieldHookConfig, useField } from 'formik';
import { getImageURL, toastError } from 'helpers';
import { formatBytes } from 'helpers/FormHelper';
import useBreakpoint from 'hooks/breakpoints';
import { LocalFile } from 'models';
import { useDropzone } from 'react-dropzone';

import { CleanButton } from '../Common';
import UserAvatar from '../Common/UserAvatar';

interface OtherProps {
  label?: string;
  multiple?: boolean;
}

const ImageDrapzone = ({
  label,
  multiple,
  ...props
}: OtherProps & FieldHookConfig<LocalFile[] | null>) => {
  const { isMobile } = useBreakpoint();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);
  //   const [previewVisible, setPreviewVisible] = useState<string | null>(null);

  const onDrop = files => {
    let images: LocalFile[] = [];
    files.forEach(file => {
      if (file.size > IMAGE_SIZE) {
        toastError('通告圖片不可大於10MB');
      }
      file['preview'] = URL.createObjectURL(file);
      file['formattedSize'] = formatBytes(file.size);
      if (multiple) {
        images.push(file);
      } else {
        images = [file];
      }
      helper.setValue(images);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    multiple,
    noDragEventsBubbling: true,
    accept: {
      'image/png': ['.png', '.jpg', '.PNG', '.jpeg'],
    },
  });
  return (
    <div className="avatar-div">
      <UserAvatar
        avatar={
          field?.value?.[0]?.size
            ? field?.value?.[0]?.preview
            : getImageURL(field?.value?.[0]?.preview as string)
        }
        displayName={field.value?.[0]?.name}
        width={isMobile ? '80px' : '100px'}
        height={isMobile ? '80px' : '100px'}
        blobImg
      />
      <div className="button-div" {...getRootProps()}>
        <CleanButton
          containerStyle={{ width: '160px', height: '44px' }}
          customClass="font-14px-semibold text-color-purple"
        >
          <input {...getInputProps()} />
          更換個人資料照片
        </CleanButton>
      </div>
    </div>
  );
};

export default ImageDrapzone;
