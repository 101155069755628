import { Route, Routes } from 'react-router-dom';

import { NotFoundPageWithUI } from '../NotFoundPageWithUI/loadable';
import { BannerPage } from './BannerPage/loadable';
import { CompanyEventPage } from './CompanyEventPage/loadable';
import { ContactRoutes } from './ContactPage';
import { HomePage } from './HomePage/loadable';
import { InfoPage } from './InfoPage/loadable';
import { NewsRoutes } from './NewsPage';
import { RankingPage } from './RankingPage/loadable';
import { SurveyPage } from './SurveyPage/loadable';

export const HomeRoutes = () => {
  return (
    <Routes>
      <Route index element={<HomePage />}></Route>
      <Route path="/ranking" element={<RankingPage />}></Route>
      <Route path="/banner/:bannerId" element={<BannerPage />}></Route>
      <Route path="/news/*" element={<NewsRoutes />}></Route>
      <Route path="/survey/:surveyId" element={<SurveyPage />}></Route>
      <Route path="/contacts/*" element={<ContactRoutes />}></Route>
      <Route path="/info" element={<InfoPage />}></Route>
      <Route
        path="/company-events/:companyActivityId"
        element={<CompanyEventPage />}
      ></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
