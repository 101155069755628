import * as Sentry from '@sentry/react';
import { EventInfoCard, PageLoading } from 'app/components';
import { CustomBreadcrumbs } from 'app/components/Common/CustomBreadcrumbs';
import { MobileHeader } from 'app/components/Common/MobileHeader';
import { NoResultFound } from 'app/components/Common/NoResultFound';
import TrashCan from 'assets/icons/Trash_can.svg';
import 'assets/scss/Pages/EventPage/EventBookmarkPage.scss';
import { fromatEventToCardData, toastError } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { SliderCardData } from 'models';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserEventList, removeEventBookmark } from 'services/EventServices';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

export interface EventCardDataForBookmarkPage extends SliderCardData {
  selected: boolean;
}

export const EventBookmarkPage = () => {
  const [EventData, setEventData] = useState<EventCardDataForBookmarkPage[]>(
    [],
  );
  const [IsLoading, setIsLoading] = useState<boolean>(true);
  const [IsSelecting, setIsSelecting] = useState<boolean>(false);
  const { Auth } = useAppSelector((rootState: RootState) => rootState.auth);
  const { isMobile } = useBreakpoint();

  const handleSelectOnClick = () => {
    setIsSelecting(true);
  };

  const handleCancelOnClick = () => {
    let tempData = [...EventData];
    tempData.forEach((data, index) => {
      data.selected = false;
    });
    setEventData(tempData);
    setIsSelecting(false);
  };

  const handleRemoveAllSelectedOnClick = () => {
    let selectedEvents = EventData.filter(data => data.selected === true);
    if (selectedEvents.length <= 0) {
      setIsSelecting(false);
      return;
    }
    selectedEvents.forEach(data => (data.isBookmarked = false));
    let tempData = [...EventData];
    tempData.forEach((data, index) => {
      data.selected = false;
    });
    setEventData(tempData);
    removeBookmark(selectedEvents);
    setIsSelecting(false);
  };

  const handleEventInfoCardOnClick = (e, data) => {
    if (IsSelecting) {
      e.preventDefault();
      let newSelected = !data.selected;
      data = { ...data, selected: newSelected };
      let index = EventData.findIndex(
        EventDataItem => EventDataItem.eventId === data.eventId,
      );
      setEventData([
        ...EventData.slice(0, index),
        data,
        ...EventData.slice(index + 1),
      ]);
    }
  };

  const getEventData = useCallback(async () => {
    if (Auth) {
      setIsLoading(true);
      try {
        const eventRes = await getUserEventList({
          limit: 999,
          offset: 0,
          desc: 'createdAt',
          allowRegistration: true,
        });
        setEventData(
          eventRes.rows.map(row => {
            return { ...fromatEventToCardData(row), selected: false };
          }),
        );
      } catch (err) {
        // console.log(err);
        Sentry.captureException(err);
        toastError('獲取活動資料失敗');
      } finally {
        setIsLoading(false);
      }
    } else {
      toastError('請先登入');
    }
  }, [Auth]);

  const removeBookmark = useCallback(
    async selectedEvents => {
      if (Auth) {
        setIsLoading(true);
        try {
          for (let i = 0; i < selectedEvents.length; i++) {
            await removeEventBookmark(selectedEvents[i].eventId);
            selectedEvents.forEach(event => {
              let index = EventData.findIndex(
                EventDataItem => EventDataItem.eventId === event.eventId,
              );
              setEventData([
                ...EventData.slice(0, index),
                event,
                ...EventData.slice(index + 1),
              ]);
            });
          }
        } catch (err) {
          // console.log(err);
          Sentry.captureException(err);
          toastError('移除失敗，請重試');
        } finally {
          setIsLoading(false);
        }
      } else {
        toastError('請先登入');
      }
    },
    [EventData, Auth],
  );

  useEffect(() => {
    if (Auth) {
      getEventData();
    } else {
      setIsLoading(false);
    }
  }, [getEventData, Auth]);

  if (IsLoading) {
    return (
      <div className="event-bookmark-page page-container-with-header">
        {isMobile ? (
          <MobileHeader title="我的收藏" prevPageRoute="/events" />
        ) : null}
        <PageLoading />
      </div>
    );
  }

  const Breadcrumb = [
    {
      title: '活動預約',
      href: '/events',
    },
    {
      title: '我的收藏',
      active: true,
    },
  ];

  return (
    <div className="event-bookmark-page page-container-with-header">
      {isMobile ? (
        <MobileHeader
          title="我的收藏"
          prevPageRoute="/events"
          headerRight={
            EventData.length <= 0 ||
            !EventData.find(data => data.isBookmarked) ? null : !IsSelecting ? (
              <div className="event-bookmark-page-topright d-flex align-items-center">
                <button
                  className="text-nowrap bg-transparent select ms-4 font-16px-semibold"
                  onClick={handleSelectOnClick}
                >
                  選擇
                </button>
              </div>
            ) : (
              <div className="event-bookmark-page-topright d-flex align-items-center">
                <button
                  className="bg-transparent remove-all-selected font-16px-semibold d-flex align-items-center"
                  onClick={handleRemoveAllSelectedOnClick}
                >
                  <img
                    src={TrashCan}
                    alt="Search"
                    draggable="false"
                    className="remove-all-selected-icon text-nowrap"
                  />
                  <span className="text-nowrap">移除</span>
                </button>
                <button
                  className="bg-transparent cancel ms-4 font-16px-semibold text-nowrap"
                  onClick={handleCancelOnClick}
                >
                  取消
                </button>
              </div>
            )
          }
        />
      ) : (
        <div className="event-bookmark-page-top d-flex justify-content-between align-items-center w-100">
          <div className="event-bookmark-page-breadcrumb d-flex justify-content-between align-items-center w-100">
            <CustomBreadcrumbs items={Breadcrumb} />
          </div>

          {EventData.length <= 0 ||
          !EventData.find(data => data.isBookmarked) ? null : !IsSelecting ? (
            <div className="event-bookmark-page-topright d-flex align-items-center">
              <button
                className="text-nowrap bg-transparent select ms-4 font-16px-semibold"
                onClick={handleSelectOnClick}
              >
                選擇
              </button>
            </div>
          ) : (
            <div className="event-bookmark-page-topright d-flex align-items-center">
              <button
                className="bg-transparent remove-all-selected font-16px-semibold d-flex align-items-center"
                onClick={handleRemoveAllSelectedOnClick}
              >
                <img
                  src={TrashCan}
                  alt="Search"
                  draggable="false"
                  className="remove-all-selected-icon text-nowrap"
                />
                <span className="text-nowrap">移除</span>
              </button>
              <button
                className="bg-transparent cancel ms-4 font-16px-semibold text-nowrap"
                onClick={handleCancelOnClick}
              >
                取消
              </button>
            </div>
          )}
        </div>
      )}

      {EventData.length > 0 && EventData.find(data => data.isBookmarked) ? (
        <div className="event-bookmark-page-body">
          {EventData.map((data, index) =>
            data.isBookmarked ? (
              <div className="event-bookmark-page-selected" key={index}>
                <Link
                  className="inspect-all bg-transparent text-center"
                  to={`/events/detail/${data.eventId}`}
                  key={index}
                  onClick={e => {
                    handleEventInfoCardOnClick(e, data);
                  }}
                  draggable={false}
                >
                  <EventInfoCard
                    data={data}
                    key={index}
                    isFromBookmarkPage={true}
                    selected={data.selected}
                  />
                </Link>
              </div>
            ) : null,
          )}
        </div>
      ) : (
        <NoResultFound />
      )}
    </div>
  );
};
