import 'assets/scss/Components/Form.scss';
import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

interface SurveyMCInputFieldProps {
  label: string;
  options: string[];
  requiredSymbol?: boolean;
}

export const SurveyMCInputField = (
  props: SurveyMCInputFieldProps & FieldHookConfig<string>,
) => {
  const [field, meta, helpers] = useField(props);
  const { label, options, requiredSymbol } = props;
  return (
    <div
      className={clsx(
        'form-group w-100',
        meta.touched && meta.error && 'text-danger',
      )}
    >
      <div className="d-flex flex-column w-100">
        {label ? (
          <div className="position-relative" style={{ width: 'fit-content' }}>
            <label className="font-16px-semibold">{label}</label>
            {requiredSymbol ? (
              <div className="required-symbol-div text-color-warning">*</div>
            ) : null}
          </div>
        ) : null}
        <div className="d-flex flex-column align-items-start justify-content-start w-100">
          {options.map((option, index) => (
            <div
              key={index}
              className="d-flex flex-row align-items-start justify-content-start cursor-pointer mb-1"
              onClick={() => helpers.setValue(option)}
            >
              {option === field.value ? (
                <MdRadioButtonChecked color="#6b63c4" size={20} />
              ) : (
                <MdRadioButtonUnchecked size={20} />
              )}
              <div className="text-color-primary font-16px-semibold ms-2">
                {option}
              </div>
            </div>
          ))}
        </div>
      </div>
      {meta.touched && meta.error ? (
        <div className="font-12px-medium mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};
