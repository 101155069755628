import QR_CODE from 'assets/icons/qr_code.svg';
import QR_CODE_BLACK from 'assets/icons/qr_code_black.svg';
import CardVector from 'assets/images/card-effect.png';
import 'assets/scss/layout/MemberShipCard.scss';
import { MembershipLevelMap, User } from 'models';
import { Dispatch } from 'react';
import { useAppSelector } from 'store/hooks';

import { DisabledUser } from '../Common/DisabledUser';

interface MemberShipCardProps {
  user?: User;
  fromProfilePage: boolean;
  setIsQrOpen?: Dispatch<React.SetStateAction<boolean>>;
}

export const MemberShipCard = ({
  user,
  fromProfilePage,
  setIsQrOpen,
}: MemberShipCardProps) => {
  const { qrCodeColor } = useAppSelector(rootState => rootState.auth);

  if (user) {
    return (
      <div className="membership-card">
        <div
          className={`card-surface-layer ${
            user?.memberLevel ? MembershipLevelMap[user.memberLevel] : 'common'
          }`}
        />
        {/* <div className="gaussian-layer" /> */}
        <img
          src={CardVector}
          className="card-vector"
          draggable="false"
          alt="card vector style"
        />

        <div className="member-info-layer">
          <div className="w-100 d-flex flex-row align-items-start justify-content-between">
            <div className="mt-2">
              {/* <div
                className={`font-18px-bold ${
                  user.memberLevel === '鑽石'
                    ? 'text-color-card'
                    : 'text-color-white'
                }`}
              >
                {user.lastName} {user.firstName}
              </div> */}
              <div
                className={`font-14px-bold ${
                  user.memberLevel === '鑽石'
                    ? 'text-color-card'
                    : 'text-color-white'
                }`}
              >
                {user.displayName}
              </div>
            </div>

            {user.userType === '公眾' ? null : (
              <img
                src={user.memberLevel === '鑽石' ? QR_CODE_BLACK : QR_CODE}
                alt="qr code"
                className={`${setIsQrOpen ? 'cursor-pointer' : ''}`}
                onClick={() => setIsQrOpen?.(true)}
              />
            )}
          </div>

          <div
            className={`member-level-text ${
              user?.memberLevel
                ? MembershipLevelMap[user.memberLevel]
                : 'common'
            }`}
          >
            {user.memberLevel || '普通會員'}
          </div>
        </div>
        {/* {fromProfilePage && !qrCodeColor ? (
          <DisabledUser isColorError={true} />
        ) : fromProfilePage && qrCodeColor?.color !== 'black' ? (
          <DisabledUser />
        ) : null} */}
      </div>
    );
  }
  return null;
};
