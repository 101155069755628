import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { AxiosInterceptor } from 'services';
import { persistor, store } from 'store';

import ScrollToTopOnRefresh from './components/Common/ScrollToTopOnRefresh';
import Toastify from './components/Common/Toastify';
import Router from './routes';

export const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AxiosInterceptor>
          <PersistGate loading={null} persistor={persistor}>
            <Router />
            <ScrollToTopOnRefresh />
          </PersistGate>
        </AxiosInterceptor>
      </Provider>
      <Toastify />
    </BrowserRouter>
  );
};
