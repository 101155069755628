import EmptyResult from 'assets/images/EmptyResult.png';
import 'assets/scss/Components/CommonScss/EmptyResultPage.scss';
import { Link } from 'react-router-dom';

interface EmptyResultPageProps {
  PageHasTabBar?: boolean;
  error?: boolean;
}

export const EmptyResultPage = ({
  PageHasTabBar,
  error,
}: EmptyResultPageProps) => {
  return (
    <div
      className={`empty-result-page d-flex justify-content-center align-items-center ${
        PageHasTabBar ? 'empty-result-page-with-tabbar' : 'error-page-withUI'
      }`}
    >
      <div className="empty-result-body d-flex flex-column align-items-center">
        <img
          src={EmptyResult}
          alt="Empty Result"
          draggable="false"
          className="empty-result-icon"
        />
        <span className="empty-result-text">
          {error ? '發生錯誤' : '沒有資料顯示'}
        </span>
        {error ? (
          <button
            onClick={() => window.location.reload()}
            className="return-to-home-link"
          >
            <span className="return-to-home-text">請重新載入</span>
          </button>
        ) : (
          <Link to="/" className="return-to-home-link">
            <span className="return-to-home-text">回到主頁</span>
          </Link>
        )}
      </div>
    </div>
  );
};
