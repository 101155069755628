import 'assets/scss/Components/SliderCard.scss';
import { Placeholder } from 'react-bootstrap';

export const LoadingCard = () => {
  return (
    <div className="slider-card noselect">
      <div className="slide-image-container position-relative">
        <Placeholder className="loading-slide-image" animation="glow">
          <Placeholder xs={12} />
        </Placeholder>
      </div>
      <div className="w-100 slide-info-contaienr p-3">
        <Placeholder animation="glow">
          <Placeholder xs={6} style={{ width: 200 }} />
        </Placeholder>

        <Placeholder animation="glow">
          <Placeholder xs={6} style={{ width: 100 }} />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder xs={6} style={{ width: 200 }} />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder xs={6} style={{ width: 250 }} />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder xs={6} style={{ width: 250 }} />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder xs={6} style={{ width: 250 }} />
        </Placeholder>

        <Placeholder animation="glow">
          <Placeholder xs={6} style={{ width: 60 }} />{' '}
          <Placeholder xs={6} style={{ width: 60 }} />
        </Placeholder>
      </div>
    </div>
  );
};
