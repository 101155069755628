import Coin from 'assets/icons/coin.svg';
import LogoutIcon from 'assets/icons/logout.svg';
import Logo from 'assets/images/Logo.png';
import 'assets/scss/layout/SideBar.scss';
import { routesMap } from 'config/routes';
import { numberWithCommas } from 'helpers';
import _ from 'lodash';
import { Statement, User } from 'models';
import moment from 'moment';
import { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { PendingSum } from 'services/UserService';
import { RootState } from 'store';
import { Logout } from 'store/Auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { QRcodeModal } from '../Modal';
import { MemberShipCard } from './MemberShipCard';

interface SideBarProps {
  user?: User;
  statement: Statement | null;
  pendingSum: PendingSum | null;
  MembershipPoint: number | null;
}

export const SideBar = ({
  user,
  statement,
  pendingSum,
  MembershipPoint,
}: SideBarProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Auth } = useAppSelector((rootState: RootState) => rootState.auth);
  const [isQrOpen, setIsQrOpen] = useState<boolean>(false);

  return (
    <div className="side-bar">
      <>
        {isQrOpen && Auth && Auth?.user?.userType === '會員' ? (
          <QRcodeModal
            isOpen={isQrOpen}
            setIsOpen={setIsQrOpen}
            user={Auth.user}
          />
        ) : null}
      </>
      <div className="w-100">
        {user ? (
          <div className="p-3">
            {user ? (
              <MemberShipCard
                user={user}
                fromProfilePage={false}
                setIsQrOpen={setIsQrOpen}
              />
            ) : null}
            {user ? (
              <>
                <div className="d-flex flex-row align-items-center justify-content-between mt-2">
                  <div className="font-15px-bold">會籍管理</div>
                  <div className="d-flex flex-row">
                    <img src={Coin} alt="coin icon" />
                    <div className="ms-1 font-18px-bold">
                      {MembershipPoint
                        ? numberWithCommas(MembershipPoint)
                        : '-'}
                    </div>
                  </div>
                </div>

                {user.userType === '會員' ? (
                  <div className="d-flex flex-row align-items-end justify-content-between mt-2">
                    <div>
                      <div className="font-14px-semibold">
                        未繳費用：
                        <span className="text-primary">
                          {pendingSum?.totalAmount && pendingSum.totalAmount > 0
                            ? _.round(pendingSum.totalAmount / 100, 2)
                            : '$ -'}
                        </span>
                      </div>
                      <div className="font-14px-medium text-color-secondary mt-2">
                        繳費到期日：
                        {pendingSum?.oldestDueDate
                          ? moment(pendingSum.oldestDueDate)
                              .locale('en')
                              .format('DD-MMM-YYYY')
                          : ' - '}
                      </div>
                    </div>
                    {pendingSum &&
                    pendingSum.totalAmount &&
                    pendingSum.totalAmount > 0 ? (
                      <Link to={`/profile/fee`} rel="noreferrer">
                        <Button>繳費</Button>
                      </Link>
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : (
              <div className="w-100 d-flex flex-row align-items-center justify-content-center pt-4">
                <img src={Logo} width={80} height={80} alt="sv logo" />
              </div>
            )}
          </div>
        ) : (
          <div className="w-100 d-flex flex-row align-items-center justify-content-center pt-4">
            <img src={Logo} width={80} height={80} alt="sv logo" />
          </div>
        )}

        <div className="d-flex flex-column align-items-start justify-content-start mt-3">
          {routesMap.map(route => (
            <NavLink
              to={route.path}
              key={route.path}
              className="d-flex flex-row align-items-center justify-content-start nav-item my-2"
            >
              {({ isActive }) => (
                <Fragment>
                  <img
                    src={isActive ? route.activeIcon : route.icon}
                    alt={`${route.title} icon`}
                  />
                  <div className="nav-item-title">{route.title}</div>
                </Fragment>
              )}
            </NavLink>
          ))}
        </div>
      </div>
      {user && (
        <div className="d-flex flex-column align-items-center justify-content-end flex-1 mb-3 w-100">
          {/* <NavLink
            to="/logout"
            className="d-flex flex-row align-items-center justify-content-start nav-item"
          >
            <img src={LogoutIcon} alt="logout icon" />
            <div className="nav-item-title">登出</div>
          </NavLink> */}
          <div
            onClick={() => dispatch(Logout(navigate))}
            className="d-flex flex-row align-items-center justify-content-start nav-item cursor-pointer"
          >
            <img src={LogoutIcon} alt="logout icon" />
            <div className="nav-item-title">登出</div>
          </div>
        </div>
      )}
    </div>
  );
};
