import 'assets/scss/Components/EventApplicationDetailCard.scss';
import { FontHelper } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { EventApplication } from 'models';
import moment from 'moment';
import React from 'react';

import { EventApplicationDetailPricing } from './EventApplicationDetailPricing';
import { EventAttendeeInfoCard } from './EventAttendeeInfoCard';
import { EventPleaseShowQR } from './EventPleaseShowQR';

moment.locale('en');
export interface EventApplicationDetailCardProps {
  eventApplicationData: EventApplication;
}

export const EventApplicationDetailCard = ({
  eventApplicationData,
}: EventApplicationDetailCardProps) => {
  const { size } = useBreakpoint();
  return (
    <div className="event-application-detail-body user-select-none w-100">
      <EventPleaseShowQR eventApplicationData={eventApplicationData} />

      <div className="attendee-info-cards-container">
        {eventApplicationData.Attendees.map((attendee, index) => {
          if (!attendee.User) {
            return (
              <EventAttendeeInfoCard
                attendeeData={attendee}
                index={index}
                key={index}
              />
            );
          }
          return <React.Fragment key={index}></React.Fragment>;
        })}
      </div>
      <div className="event-application-detail-infocard-container">
        {eventApplicationData.Attendees.some(attendee => !attendee.User) ? (
          <hr className="event-application-detail-seperation-line w-100" />
        ) : null}
        <div className="event-application-detail-infocard">
          <div className="event-application-detail-heading yellow-heading mb-3">
            活動資訊
          </div>
          <div className="event-application-detail-info">
            <div className="event-application-detail-eventname mb-4">
              <div className="font-16px-semibold w-100 mb-2">活動名稱：</div>
              <div
                className={`${FontHelper(
                  size,
                  14,
                  16,
                  16,
                  'medium',
                )} one-line-ellipsis`}
              >
                {eventApplicationData?.Event.eventTitle
                  ? eventApplicationData.Event.eventTitle
                  : 'Event Title'}
              </div>
            </div>
            <div className="event-application-detail-date-and-time d-flex">
              <div className="event-application-detail-date w-50 mb-4">
                <div className="font-16px-semibold mb-2">日期：</div>
                <div className={`${FontHelper(size, 14, 16, 16, 'medium')}`}>
                  {eventApplicationData?.Event.startDate
                    ? moment(eventApplicationData.Event.startDate)
                        .locale('en')
                        .format('DD-MMM-YYYY')
                    : 'Event Date'}
                </div>
              </div>
              <div className="event-application-detail-time w-50">
                <div className="font-16px-semibold mb-2">時間：</div>
                <div className={`${FontHelper(size, 14, 16, 16, 'medium')}`}>
                  {(eventApplicationData?.Event.startDate
                    ? moment(eventApplicationData.Event.startDate).format(
                        'HH:mm',
                      )
                    : 'Event Start Time') +
                    '-' +
                    (eventApplicationData?.Event.endDate
                      ? moment(eventApplicationData.Event.endDate).format(
                          'HH:mm',
                        )
                      : 'Event End Time')}
                </div>
              </div>
            </div>
            <div className="event-application-detail-attendee-count">
              <div className="font-16px-semibold">報名人數：</div>
              {eventApplicationData ? (
                eventApplicationData.willMemberAttend ? (
                  <div
                    className={`${FontHelper(
                      size,
                      14,
                      16,
                      16,
                      'medium',
                    )} d-flex justify-content-between align-items-center attendee-count-text mb-4`}
                  >
                    會員
                    <span className="attendee-number font-14px-medium">
                      {' X   1 '}
                    </span>
                  </div>
                ) : null
              ) : null}
              {eventApplicationData ? (
                eventApplicationData.Attendees.length > 0 ? (
                  <div
                    className={`${FontHelper(
                      size,
                      14,
                      16,
                      16,
                      'medium',
                    )} d-flex justify-content-between align-items-center attendee-count-text mb-4`}
                  >
                    訪客
                    <span className="attendee-number font-14px-medium">
                      {' X   ' +
                        (eventApplicationData.Attendees.length -
                          (eventApplicationData.willMemberAttend ? 1 : 0))}
                      &nbsp;
                    </span>
                  </div>
                ) : null
              ) : null}
            </div>
            {eventApplicationData ? (
              eventApplicationData.totalPaymentAmount ||
              eventApplicationData.totalMembershipPointUsed ? (
                <>
                  <hr className="event-application-detail-seperation-line w-100" />
                  <EventApplicationDetailPricing
                    totalPaymentAmount={eventApplicationData.totalPaymentAmount}
                    totalMembershipPointUsed={
                      eventApplicationData.totalMembershipPointUsed
                    }
                  />
                </>
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
