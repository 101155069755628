import 'assets/scss/Components/Home/NewsCard.scss';
import { FontHelper, getImageURL } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { News } from 'models';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';

interface NewsCardProps {
  news: News;
}

export const NewsCard = ({ news }: NewsCardProps) => {
  const { size } = useBreakpoint();
  const quillRef = useRef<ReactQuill>(null);
  const [quillContent, setQuillContent] = useState<string | null>(null);

  useEffect(() => {
    setQuillContent(
      quillRef && quillRef.current && quillRef.current.getEditor()
        ? quillRef.current.getEditor().getText()
        : null,
    );
  }, [quillRef]);

  return (
    <Link
      to={
        news.surveyId
          ? `/home/survey/${news.surveyId}`
          : `/home/news/detail/${news.newsId}`
      }
      className="news-card-container"
    >
      <div className="news-card-wrapper">
        <div className="news-image-container">
          <img
            src={getImageURL(news.bannerImage, 768)}
            draggable={false}
            alt={news.title}
          />
        </div>
        <div className="news-info-container">
          <div className="news-title">
            <div
              className={`${FontHelper(
                size,
                16,
                18,
                18,
                'bold',
              )} text-color-primary mb-2 title-text one-line-ellipsis`}
            >
              {news.title}
            </div>
          </div>
          <div className="news-preview-text-container mb-2">
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={quillContent ? quillContent : news.content}
              readOnly
              modules={{
                toolbar: false,
              }}
              className="news-rice-text"
            />
          </div>
          <div className="text-date">
            {moment(news.publishedDate).locale('en').format('DD-MMM-YYYY')}
          </div>
        </div>
      </div>
    </Link>
  );
};
