import * as Sentry from '@sentry/react';
import DriveAddtoMyFolderIcon from 'assets/icons/drive_add_to_myfolder_icon.svg';
import DownloadIcon from 'assets/icons/drive_download_icon.svg';
import HollowHeartIcon from 'assets/icons/drive_heart_black_icon.svg';
import MoveFileIcon from 'assets/icons/drive_move_file_icon.svg';
import OptionMenuIcon from 'assets/icons/drive_option_menu_icon.svg';
import PrivateDeleteIcon from 'assets/icons/drive_private_remove_icon_black.svg';
import RenameIcon from 'assets/icons/drive_rename_icon.svg';
import ShareLinkIcon from 'assets/icons/drive_share_link_icon.svg';
import 'assets/scss/Components/Drive/DropDownMenu.scss';
import copy from 'copy-to-clipboard';
import { toastError, toastSuccess } from 'helpers';
import {
  addToMyFolderHelper,
  deleteFileHelper,
  downloadFile,
  getShareDrivePath,
} from 'helpers/DriveHelper';
import { DriveFile, DriveObjectType, DriveType, FileType } from 'models/Drive';
import { Dispatch, useCallback, useMemo, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify';
import {
  addToBookmarkInDrive,
  getOneShareFileType,
  getShareFolderToken,
  mvFileToDrive,
} from 'services/DriveService';

import Loading from '../Common/Loading';
import { Dialog } from '../Modal';
import {
  RenameOrAddFolderForm,
  RenameOrAddFolderFormItemParams,
} from './RenameOrAddFolderForm';

interface Props {
  data: DriveFile;
  getDriveListData?: () => void;
  isPrivate?: boolean;
  setIsMoveFolderDialog?: () => void;
  setSelectedItem?: (item: string[]) => void;
  setSelectedItemObject?: Dispatch<React.SetStateAction<DriveFile[]>>;
  itemLink?: string;
  notBookmarked?: boolean;
  setBookmarked?: Dispatch<React.SetStateAction<boolean>>;
  fileType?: DriveObjectType;
  isShareItem?: boolean;
  token?: string;
}

interface DropDownDataProps {
  imageSrc?: any;
  item: string;
  display: boolean;
  link?: string;
  eventOnClick?: () => void;
}

export const DropDownMenu = ({
  data,
  getDriveListData,
  isPrivate,
  setIsMoveFolderDialog,
  setSelectedItem,
  setSelectedItemObject,
  itemLink,
  notBookmarked,
  setBookmarked,
  fileType,
  isShareItem,
  token,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isRenaming, setIsRenaming] = useState<boolean>(false);

  const formOnSubmit = async (values: RenameOrAddFolderFormItemParams) => {
    const { path } = values;
    setLoading(true);
    try {
      const params = {
        source: {
          folderType: 'private' as FileType,
          driveType: 'private' as DriveType,
          path: `/${data.dir ? data.dir + '/' : ''}${data.fileName}`,
        },
        dest: {
          folderType: 'private' as FileType,
          driveType: 'private' as DriveType,
          path: `/${data.dir ? data.dir + '/' : ''}${path}`,
        },
      };
      await mvFileToDrive(params);
      getDriveListData?.();
      setLoading(false);
      setIsRenaming(false);
      toastSuccess('更改資料夾名稱成功');
    } catch (err) {
      setLoading(false);
      // console.log(err);
      Sentry.captureException(err);
      toastError('更改失敗，請重試');
    } finally {
      setLoading(false);
    }
  };

  const getToken = useCallback(async () => {
    try {
      const tokenRes = await getShareFolderToken({
        folderType: data.folderType,
        driveType: data.driveType,
        path: `${data.dir ? `${data.dir}/` : ''}${data.fileName}`,
      });
      const fileType = await getOneShareFileType({
        folderType: data.folderType,
        driveType: data.driveType,
        path: `${data.dir ? `${data.dir}/` : ''}${data.fileName}`,
        shareToken: tokenRes.token,
      });
      return getShareDrivePath(tokenRes, fileType);
    } catch (err) {
      // console.log('請檢查你的網絡。');
      Sentry.captureException(err);
    }
  }, [data]);

  const handleBookmarkOnClick = useCallback(async () => {
    try {
      const { folderType, driveType, dir, fileName } = data;
      await addToBookmarkInDrive({
        folderType: folderType,
        driveType: driveType,
        path: `${dir ? `${dir}/` : ''}${fileName}`,
      });
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('新增失敗，請重試');
    }
  }, [data]);

  const DropDownItemArray = useMemo(() => {
    const DownloadDropDownItem: DropDownDataProps = {
      imageSrc: DownloadIcon,
      item: '下載',
      display: true,
      eventOnClick: async () => {
        await downloadFile(data, token);
      },
    };
    const ShareDropDownItem: DropDownDataProps = {
      imageSrc: ShareLinkIcon,
      item: '分享連結',
      display: true,
      eventOnClick: async () => {
        const sharePath = await getToken();
        if (sharePath) {
          copy(sharePath);
          toast.success('連結已複製');
        }
      },
    };

    const AddToDropDownItem: DropDownDataProps = {
      imageSrc: DriveAddtoMyFolderIcon,
      item: '新增至「我的文件」',
      eventOnClick: () =>
        addToMyFolderHelper([
          `${data.folderType}/${data.dir ? data.dir + '/' : ''}${
            data.fileName
          }`,
        ]),
      display: true,
    };

    const BookMarkDropDownItem: DropDownDataProps = {
      imageSrc: HollowHeartIcon,
      item: `新增至「我的最愛」`,

      eventOnClick: () => {
        if (setBookmarked) {
          setBookmarked(true);
          handleBookmarkOnClick();
        }
      },
      display: notBookmarked ? true : false,
    };

    const MoveToDropDownItem: DropDownDataProps = {
      imageSrc: MoveFileIcon,
      item: '移至',
      display: true,
      eventOnClick: () => {
        setIsMoveFolderDialog?.();
        setSelectedItem?.([
          `${data.folderType}/${data.dir ? data.dir + '/' : ''}${
            data.fileName
          }`,
        ]);
        setSelectedItemObject?.([data]);
      },
    };

    const RenameDropDownItem: DropDownDataProps = {
      imageSrc: RenameIcon,
      item: '重新命名',
      display: data.type === 'Folder' ? true : false,
      eventOnClick: () => {
        setIsRenaming(true);
      },
    };

    const DeleteDropDownItem: DropDownDataProps = {
      imageSrc: PrivateDeleteIcon,
      item: '移除',
      display: true,
      eventOnClick: () =>
        deleteFileHelper(
          [
            `${data.driveType}/${data.dir ? data.dir + '/' : ''}${
              data.fileName
            }`,
          ],
          getDriveListData!,
          setLoading,
        ),
    };
    const dropDownItems: DropDownDataProps[] = [];
    if (isShareItem) {
      dropDownItems.push(DownloadDropDownItem);
    } else if (isPrivate) {
      dropDownItems.push(ShareDropDownItem);
      dropDownItems.push(MoveToDropDownItem);
      if (fileType === 'Folder') {
        dropDownItems.push(RenameDropDownItem);
      }
      dropDownItems.push(DeleteDropDownItem);
    } else {
      if (fileType === 'File') {
        dropDownItems.push(DownloadDropDownItem);
        dropDownItems.push(AddToDropDownItem);
        dropDownItems.push(BookMarkDropDownItem);
      }
    }
    return dropDownItems;
  }, [
    isShareItem,
    isPrivate,
    fileType,
    data,
    getDriveListData,
    getToken,
    notBookmarked,
    setBookmarked,
    setIsMoveFolderDialog,
    setSelectedItem,
    setSelectedItemObject,
    handleBookmarkOnClick,
    token,
  ]);

  return (
    <>
      <Dropdown
      //  tabIndex={-1}
      >
        <Dropdown.Toggle variant="" id="dropdown-basic" tabIndex={-1}>
          <img
            src={OptionMenuIcon}
            draggable={false}
            alt={'menu-icon'}
            className="cursor-pointer"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            height: 'fit-content',
          }}
        >
          {DropDownItemArray.filter(item => item.display).map(
            ({ imageSrc, item, eventOnClick, link }, index) => (
              <Dropdown.Item
                href={link ? link : '#'}
                target={link ? '_blank' : '_parent'}
                key={index}
                onClick={eventOnClick}
                style={{ userSelect: 'none' }}
              >
                <div className="d-flex drop-down-item">
                  {loading ? (
                    <div className="d-flex">
                      <div style={{ marginRight: '12px' }}>
                        <Loading playerHeight={24} playerWidth={24} />
                      </div>
                      <div>{item}</div>
                    </div>
                  ) : (
                    <>
                      <img src={imageSrc} alt={item} draggable={false} />
                      <div>{item}</div>
                    </>
                  )}
                </div>
              </Dropdown.Item>
            ),
          )}
        </Dropdown.Menu>
      </Dropdown>
      {isRenaming && (
        <Dialog
          visible={isRenaming}
          title={'重新命名文件夾'}
          onClose={() => setIsRenaming(false)}
          size={'w-580'}
        >
          <RenameOrAddFolderForm
            formSubmit={formOnSubmit}
            loading={loading}
            data={data}
            isRenaming={true}
          />
        </Dialog>
      )}
    </>
  );
};
