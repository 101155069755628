import { AuthLayout, MainLayout } from 'app/layouts';
import {
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
} from 'app/pages/AuthPage';
import { CalendarPage } from 'app/pages/CalendarPage/loadable';
import { CourseRoutes } from 'app/pages/CoursePage';
import { DriveRoutes } from 'app/pages/DrivePage';
import { DriveSharePage } from 'app/pages/DrivePage/DriveSharePage';
import { EventRoutes } from 'app/pages/EventPage';
import { GuestFeePaymentPage } from 'app/pages/GuestFeePaymentPage/loadable';
import { GuestFeePaymentSuccessPage } from 'app/pages/GuestFeePaymentSuccessPage/loadable';
import { HistoryRoutes } from 'app/pages/HistoryPage';
import { HomeRoutes } from 'app/pages/HomePage';
import { MaintenancePage } from 'app/pages/MaintenancePage/loadable';
import { NotFoundPage } from 'app/pages/NotFoundPage/loadable';
import { NotificationRoutes } from 'app/pages/NotificationPage';
import { ProfileRoutes } from 'app/pages/ProfilePage';
import useMaintenance from 'hooks/useMaintenance';
import { Navigate, Route, Routes } from 'react-router-dom';

const Router = () => {
  useMaintenance();

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/home/*" element={<HomeRoutes />} />
        <Route path="/events/*" element={<EventRoutes />} />

        <Route path="/courses/*" element={<CourseRoutes />} />

        <Route path="/drive/*" element={<DriveRoutes />} />

        <Route path="/history/*" element={<HistoryRoutes />} />

        <Route path="/calendar" element={<CalendarPage />} />

        <Route path="/profile/*" element={<ProfileRoutes />} />

        <Route path="/notification/*" element={<NotificationRoutes />} />
        <Route path="/" element={<Navigate to="/home" replace />} />
      </Route>

      <Route path="/auth" element={<AuthLayout />}>
        <Route index element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="forgot" element={<ForgotPasswordPage />} />
      </Route>
      <Route path="/share" element={<DriveSharePage />}></Route>
      <Route path="/maintenance" element={<MaintenancePage />} />
      {/* <Route path="/logout" element={<LogoutPage />} /> */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
