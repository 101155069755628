import { SurveyPage } from 'app/pages/HomePage/SurveyPage';
import { NotFoundPageWithUI } from 'app/pages/NotFoundPageWithUI/loadable';
import { Route, Routes } from 'react-router-dom';

import { CourseApplicationDetailPage } from './CourseApplicationDetailPage/loadable';
import { MyCourseListPage } from './MyCourseListPage/loadable';

export const MyCourseRoutes = () => {
  return (
    <Routes>
      <Route index element={<MyCourseListPage />}></Route>
      <Route
        path="/application/:courseApplicationId"
        element={<CourseApplicationDetailPage />}
      ></Route>
      <Route
        path="/application/:courseApplicationId/schedule/:courseScheduleId/survey/:surveyId"
        element={<SurveyPage />}
      ></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
