import 'assets/scss/Components/BannerCard.scss';
import { FontHelper, getImageURL } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { BannerData } from 'models';
import { Link } from 'react-router-dom';

interface BannerCardProps {
  banner: BannerData;
}

export const BannerCard = ({ banner }: BannerCardProps) => {
  const { size } = useBreakpoint();
  let link = `/home/banner/${banner.bannerId}`;
  if (banner.eventId) {
    link = `/events/detail/${banner.eventId}`;
  } else if (banner.courseId) {
    link = `/courses/detail/${banner.courseId}`;
  } else if (banner.newsId) {
    link = `/home/news/detail/${banner.newsId}`;
  } else if (banner.surveyId) {
    link = `/home/survey/${banner.surveyId}`;
  }
  return (
    <Link to={link}>
      <div
        className="banner-card noselect drag-none"
        style={{
          backgroundImage: banner?.images?.[0]
            ? `url(${getImageURL(banner?.images?.[0], 768)})`
            : 'white',
        }}
      >
        <div className="w-100 overlay-layer">
          <div
            className={`title-text ${FontHelper(
              size,
              16,
              20,
              24,
              'semibold',
            )} text-color-white two-line-ellipsis`}
          >
            {banner.title}
          </div>
        </div>
      </div>
    </Link>
  );
};
