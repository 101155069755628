import 'assets/scss/Components/EventApplicationEventInfoCard.scss';
import {
  Event,
  EventApplication,
  EventPaymentOptions,
  FormAttendees,
} from 'models';
import moment from 'moment';

import { EventApplicationDetailPricing } from '../EventApplicationDetailPricing';

interface EventApplicationEventInfoCardProps {
  data: Event;
  paymentOption?: EventPaymentOptions;
  isFree?: boolean;
  setFieldValue?: (field: string, value: any, bool?: boolean) => void;
  attendees?: FormAttendees[];
  application: EventApplication;
}

export const EventApplicationDisplayCard = (
  props: EventApplicationEventInfoCardProps,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, paymentOption, isFree, setFieldValue, attendees, application } =
    props;

  const numberOfGuest = application.Attendees.filter(
    attendee => !attendee.User,
  ).length;

  return (
    <div className="event-application-page-one-info-card">
      <span className="event-heading yellow-heading">活動資訊</span>
      <div className="event-name">
        <div className="event-subheading font-16px-semibold">活動名稱：</div>
        <div className="event-info-text">{data.eventTitle}</div>
      </div>
      <div className="event-date-and-time">
        <div className="event-date">
          <div className="event-subheading font-16px-semibold">日期：</div>
          <div className="event-info-text">
            {moment(data.startDate).locale('en').format('DD-MMM-YYYY')}
          </div>
        </div>
        <div className="event-time">
          <div className="event-subheading font-16px-semibold">時間：</div>
          <div className="event-info-text">
            {moment(data.startDate).format('HH:mm') +
              ' - ' +
              moment(data.endDate).format('HH:mm')}
          </div>
        </div>
      </div>

      <div className="event-applicants">
        <div className="event-subheading font-16px-semibold">報名人數：</div>
        <div className="event-applicants-flexbox">
          <div className="member-flexbox">
            <div className="member-flexbox w-100">
              <div className="event-info-text">會員</div>
              <span className="attendee-number font-14px-medium">
                {' X   ' + (application.willMemberAttend ? '1 ' : '0 ')}
              </span>
            </div>
          </div>
          <div className="guest-flexbox">
            <div className="event-info-text">訪客</div>
            <span className="attendee-number font-14px-medium">
              {' X   ' + (numberOfGuest + ' ')}
            </span>
          </div>
        </div>
      </div>
      {!isFree && attendees && paymentOption ? (
        <div>
          <hr className="seperation-line w-100"></hr>
          <EventApplicationDetailPricing
            totalPaymentAmount={paymentOption.cash * attendees.length}
            totalMembershipPointUsed={paymentOption.point * attendees.length}
            pointEarnAfterApply={data.pointEarnAfterApply}
          />
        </div>
      ) : null}
    </div>
  );
};
