import { CheckBox } from 'app/components/Common/CheckBox';
import { EventPricingOptions } from 'app/components/Event/EventPricingOptions';
import Minus from 'assets/icons/minus.svg';
import Plus from 'assets/icons/plus.svg';
import 'assets/scss/Components/EventApplicationEventInfoCard.scss';
import { FieldHookConfig, useField } from 'formik';
import { Event, EventPaymentOptions, FormAttendees } from 'models';
import moment from 'moment';

import { EventApplicationDetailPricing } from '../EventApplicationDetailPricing';

interface EventApplicationEventInfoCardProps {
  data: Event;
  isInputing: boolean;
  paymentOption?: EventPaymentOptions;
  isFree?: boolean;
  setFieldValue?: (field: string, value: any, bool?: boolean) => void;
  attendees?: FormAttendees[];
}

const attendeeItem = {
  name: '',
  phoneNumber: '',
  email: '',
};

export const EventApplicationEventInfoCard = (
  props: EventApplicationEventInfoCardProps &
    FieldHookConfig<{
      willMemberAttend: boolean;
      numberOfGuest: number;
    }>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props);
  const { data, isInputing, paymentOption, isFree, setFieldValue, attendees } =
    props;
  const handleMinusOnClick = () => {
    if (field.value.numberOfGuest > 0) {
      helpers.setValue({
        ...field.value,
        numberOfGuest: field.value.numberOfGuest - 1,
      });
      if (attendees && setFieldValue) {
        const tempAttendees = [...attendees];
        tempAttendees.pop();
        setFieldValue('attendees', tempAttendees);
      }
    }
  };

  const handlePlusOnClick = () => {
    helpers.setValue({
      ...field.value,
      numberOfGuest: field.value.numberOfGuest + 1,
    });
    if (attendees && setFieldValue) {
      setFieldValue('attendees', [...attendees, { ...attendeeItem }]);
    }
  };

  return (
    <div className="event-application-page-one-info-card">
      <span className="event-heading yellow-heading">活動資訊</span>
      <div className="event-name">
        <div className="event-subheading font-16px-semibold">活動名稱：</div>
        <div className="event-info-text">{data.eventTitle}</div>
      </div>
      <div className="event-date-and-time">
        <div className="event-date">
          <div className="event-subheading font-16px-semibold">日期：</div>
          <div className="event-info-text">
            {moment(data.startDate).locale('en').format('DD-MMM-YYYY')}
          </div>
        </div>
        <div className="event-time">
          <div className="event-subheading font-16px-semibold">時間：</div>
          <div className="event-info-text">
            {moment(data.startDate).format('HH:mm') +
              ' - ' +
              moment(data.endDate).format('HH:mm')}
          </div>
        </div>
      </div>
      {isInputing ? (
        <div className={`event-price event-application-event-info-price`}>
          <div className="event-subheading font-16px-semibold">價錢</div>
          <EventPricingOptions PaymentOptions={data.paymentOptions} />
        </div>
      ) : null}
      <div className="event-applicants">
        <div className="event-subheading font-16px-semibold">報名人數：</div>
        <div className="event-applicants-flexbox">
          <div className="member-flexbox">
            {isInputing ? (
              <label className="member-flexbox w-100">
                <div className="event-info-text">會員</div>
                <div className="checkbox-container">
                  <CheckBox
                    className="checkbox"
                    checked={field.value.willMemberAttend}
                    onChange={() => {
                      helpers.setValue({
                        ...field.value,
                        willMemberAttend: !field.value.willMemberAttend,
                      });
                    }}
                  />
                </div>
              </label>
            ) : (
              <div className="member-flexbox w-100">
                <div className="event-info-text">會員</div>
                <span className="attendee-number font-14px-medium">
                  {' X   ' + (field.value.willMemberAttend ? '1 ' : '0 ')}
                </span>
              </div>
            )}
          </div>
          {data.allowGuest ? (
            <div className="guest-flexbox">
              <div className="event-info-text">訪客</div>
              {isInputing ? (
                <div className="guest-count">
                  <button onClick={handleMinusOnClick} type="button">
                    <img src={Minus} alt="-" draggable={false} />
                  </button>
                  <input
                    placeholder="0"
                    value={field.value.numberOfGuest}
                    readOnly={true}
                  ></input>
                  <button
                    onClick={handlePlusOnClick}
                    type="button"
                    disabled={
                      data.maxNoOfGuest
                        ? field.value.numberOfGuest >= data.maxNoOfGuest
                          ? true
                          : false
                        : false
                    }
                  >
                    <img src={Plus} alt="+" draggable={false} />
                  </button>
                </div>
              ) : (
                <span className="attendee-number font-14px-medium">
                  {' X   ' + (field.value.numberOfGuest + ' ')}
                </span>
              )}
            </div>
          ) : null}
        </div>
      </div>
      {!(isFree || isInputing) && attendees && paymentOption ? (
        <div>
          <hr className="seperation-line w-100"></hr>
          <EventApplicationDetailPricing
            totalPaymentAmount={
              paymentOption.cash *
              (attendees.length + (field.value.willMemberAttend ? 1 : 0))
            }
            totalMembershipPointUsed={
              paymentOption.point *
              (attendees.length + (field.value.willMemberAttend ? 1 : 0))
            }
            pointEarnAfterApply={data.pointEarnAfterApply}
          />
        </div>
      ) : null}
    </div>
  );
};
