import { Route, Routes } from 'react-router-dom';

import { NotFoundPageWithUI } from '../NotFoundPageWithUI/loadable';
import { DriveBookmarkPage } from './DriveBookmarkPage/loadable';
import { DriveListPage } from './DriveListPage/loadable';
import { DriveMobileSearch } from './DriveMobileSearch/loadable';
import { DrivePrivatePage } from './DrivePrivatePage/loadable';
import { DrivePublicPage } from './DrivePublicPage/loadable';

export const DriveRoutes = () => {
  return (
    <Routes>
      <Route index element={<DriveListPage />}></Route>
      <Route path="/public" element={<DrivePublicPage />}></Route>
      <Route path="/private" element={<DrivePrivatePage />}></Route>
      <Route path="/bookmark" element={<DriveBookmarkPage />}></Route>
      <Route path="/search" element={<DriveMobileSearch />}></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
