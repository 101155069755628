import 'assets/scss/Components/BannerSlider.scss';
import useBreakpoint from 'hooks/breakpoints';
import { BannerData } from 'models';
import { Placeholder } from 'react-bootstrap';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BannerCard } from './BannerCard';

interface BannerSliderProps {
  banners: BannerData[];
  loading: boolean;
}

export const BannerSlider = ({ banners, loading }: BannerSliderProps) => {
  const { isMobile } = useBreakpoint();
  if (loading) {
    return (
      <div className="loading-slide-container">
        <Placeholder animation="glow" className="loading-banner-card">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder animation="glow" className="loading-banner-card">
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder animation="glow" className="loading-banner-card">
          <Placeholder xs={12} />
        </Placeholder>
      </div>
    );
  }
  return (
    <>
      {isMobile ? (
        <Swiper
          effect={'coverflow'}
          spaceBetween={0}
          slidesPerView={1.6}
          modules={[Pagination, EffectCoverflow, Autoplay]}
          centeredSlides
          autoplay={{
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }}
          pagination={{
            dynamicBullets: true,
          }}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          loop={true}
          className="banner-slider"
        >
          {banners.map(banner => (
            <SwiperSlide key={banner.bannerId}>
              <BannerCard banner={banner} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Swiper
          spaceBetween={0}
          slidesPerView={1.6}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }}
          centeredSlides
          pagination={{
            dynamicBullets: true,
          }}
          loop={true}
          className="banner-slider"
        >
          {banners.map(banner => (
            <SwiperSlide key={banner.bannerId}>
              <BannerCard banner={banner} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};
