import 'assets/scss/Components/CommonScss/ApplicationPopUp.scss';

interface PopUpProps {
  onCancel: () => void;
  onConfirm?: () => void;
  onBack?: () => void;
  content: string | null;
}

export const ApplicationPopUp = ({
  onCancel,
  onConfirm,
  onBack,
  content,
}: PopUpProps) => {
  return (
    <div className="pop-up">
      <div className="pop-up-content">{content}</div>
      <div className="pop-up-buttons">
        <button
          type="button"
          onClick={onCancel}
          className="pop-up-buttons-cancel"
        >
          取消
        </button>
        {onBack ? (
          <button
            type="button"
            onClick={onBack}
            className="pop-up-buttons-confirm"
          >
            返回資訊頁
          </button>
        ) : (
          <button
            type="button"
            onClick={onConfirm}
            className="pop-up-buttons-confirm"
          >
            確認
          </button>
        )}
      </div>
    </div>
  );
};
