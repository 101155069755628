import _ from 'lodash';
import { useEffect, useState } from 'react';

export type ScreenSize = 'mobile' | 'tablet' | 'desktop';

interface Props {
  size: ScreenSize;
  width: number;
  isMobile: boolean;
}

export const SIZE_XS = 'xs';
export const SIZE_SM = 'sm';
export const SIZE_MD = 'md';
export const SIZE_LG = 'lg';
export const SIZE_XL = 'xl';
export const SIZE_XXL = 'xxl';

const resolveBreakpoint = (width: number): ScreenSize => {
  if (width < 576) {
    return 'mobile';
  } else if (width >= 576 && width < 992) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};

const resolveIsMobile = (width: number): boolean => {
  if (width < 576) {
    return true;
  } else {
    return false;
  }
};

const useBreakpoint = (): Props => {
  const [size, setSize] = useState<ScreenSize>(() =>
    resolveBreakpoint(window.innerWidth),
  );
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isMobile, setIsMobile] = useState<boolean>(() =>
    resolveIsMobile(window.innerWidth),
  );
  useEffect(() => {
    const calcInnerWidth = _.debounce(() => {
      setSize(resolveBreakpoint(window.innerWidth));
      setWidth(window.innerWidth);
      setIsMobile(resolveIsMobile(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return { size, width, isMobile };
};

export default useBreakpoint;
