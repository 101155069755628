import { Player } from '@lottiefiles/react-lottie-player';
import Logo from 'assets/images/Logo.png';
import Loading from 'assets/lottie/loading-dots.json';
import 'assets/scss/Components/CommonScss/PageLoading.scss';

export const PageLoading = () => {
  return (
    <div className="page-loading-container">
      <img src={Logo} alt="sv logo" className="sv-logo" />
      <Player
        autoplay
        loop
        src={Loading}
        style={{
          height: '80px',
          width: '80px',
        }}
      ></Player>
    </div>
  );
};
