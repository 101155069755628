import { NotFoundPageWithUI } from 'app/pages/NotFoundPageWithUI/loadable';
import { Route, Routes } from 'react-router-dom';

import { ContactDetailPage } from './ContactDetailPage';
import { ContactListPage } from './ContactListPage/loadable';

export const ContactRoutes = () => {
  return (
    <Routes>
      <Route index element={<ContactListPage />}></Route>
      <Route
        path="/detail/:contactCategoryId"
        element={<ContactDetailPage />}
      ></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
