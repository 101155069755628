import SVbanner from 'assets/icons/SV_small_banner.svg';
import 'assets/scss/Components/DownloadAppFirst.scss';
import useBreakpoint from 'hooks/breakpoints';

export const DownloadAppFirst = () => {
  const { size } = useBreakpoint();
  return (
    <div className="download-app-first">
      <span className="download-app-first-text font-16px-semibold">
        * 請提早下載程式
      </span>
      {size === 'desktop' ? (
        <div className="google-play-and-app-store">
          <img src={SVbanner} alt="SV Banner" className="download-sv-banner" />
          <a
            href="https://apps.apple.com/us/app/super-vibes/id1665802171?itsct=apps_box_badge&amp;itscg=30200"
            className="app-store"
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1674777600"
              alt="Download on the App Store"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.appicidea.svmobileapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            className="google-play"
          >
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
        </div>
      ) : (
        <>
          <div className="download-app-flexbox">
            <img
              src={SVbanner}
              alt="SV Banner"
              className="download-sv-banner"
            />
            <div className="google-play-and-app-store">
              <a
                href="https://apps.apple.com/us/app/super-vibes/id1665802171?itsct=apps_box_badge&amp;itscg=30200"
                className="app-store"
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1674777600"
                  alt="Download on the App Store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.appicidea.svmobileapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                className="google-play"
              >
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
