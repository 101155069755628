import { default as CloseBtn } from 'assets/icons/close-button.svg';
import 'assets/scss/Components/Modal/dialog.scss';
import React from 'react';
import Modal from 'react-modal';

interface Props {
  visible: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'fullscreen' | 'w-580' | 'w-700' | 'w-800';
  title: string;
  onClose: () => void;
  children: React.ReactChild;
  onConfirm?: () => void;
  loading?: boolean;
  btnType?:
    | 'danger'
    | 'success'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'dark'
    | 'light'
    | 'none';
  confirmBtnText?: string;
  isStatic?: boolean;
  className?: string;
}

Modal.setAppElement('#root');

const Dialog = ({
  visible,
  size = 'md',
  title,
  onClose,
  onConfirm,
  loading,
  children,
  btnType,
  confirmBtnText,
  isStatic = false,
  className,
}: Props) => {
  return (
    <Modal
      isOpen={visible}
      className={`dialog-modal ${size ? size : ''} ${className}`}
      overlayClassName="dialog-overlay"
      onRequestClose={isStatic ? () => null : onClose}
      closeTimeoutMS={100}
    >
      <div className="modal-header ">
        <h5 className="modal-title font-18px-semibold noselect">{title}</h5>
        <div className="modal-close-btn" onClick={onClose}>
          <img src={CloseBtn} alt="close-btn" draggable={false} />
        </div>
      </div>

      <div className="modal-content">{children}</div>

      {onConfirm ? (
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light me-2 cancel"
            onClick={onClose}
            disabled={loading ? true : false}
          >
            取消
          </button>
          <button
            type="button"
            className={`btn btn-${btnType ? btnType : 'danger'} confirm`}
            onClick={onConfirm}
            disabled={loading ? true : false}
          >
            <div>
              {loading ? (
                <i className="bx bx-loader-circle bx-spin" />
              ) : (
                `${confirmBtnText ? confirmBtnText : '確定'}`
              )}
            </div>
          </button>
        </div>
      ) : null}
    </Modal>
  );
};

export default Dialog;
