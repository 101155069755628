import { Event } from 'models';

export const isEventFree = (event: Event): boolean => {
  if (event.paymentOptions.length <= 0) {
    return true;
  } else {
    if (
      event.paymentOptions.some(
        option => option.cash === 0 && option.point === 0,
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
};
