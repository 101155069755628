import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LayoutState {
  bgImg: string | null;
}

const initialState = {
  bgImg: null,
} as LayoutState;

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    changeAuthBackground(state, action: PayloadAction<string>) {
      state.bgImg = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { changeAuthBackground } = layoutSlice.actions;
export default layoutSlice.reducer;
