import { default as QrCode } from 'assets/icons/history_qr_code.svg';
import 'assets/scss/Components/MyInfoCard.scss';
import { FontHelper } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { MyCourseData, MyEventData } from 'models';
import { Placeholder } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export interface MyEventInfoCardProps {
  myEventData?: MyEventData | null;
  myCourseData?: MyCourseData | null;
  isLoading?: boolean;
  openModal?: () => void;
  setActiveCard?: () => void;
}

export const MyEventInfoCard = ({
  myEventData,
  myCourseData,
  isLoading,
  openModal,
  setActiveCard,
}: MyEventInfoCardProps) => {
  const { size } = useBreakpoint();
  const navigate = useNavigate();
  const myCardOnClick = () => {
    if (myEventData) {
      navigate(
        `/events/my-events/application/${myEventData.eventApplicationId}`,
      );
    } else if (myCourseData) {
      navigate(
        `/courses/my-courses/application/${myCourseData.courseApplicationId}`,
      );
    }
  };
  return (
    <div className="my-card" onClick={() => myCardOnClick()}>
      <div className="d-flex flex-column w-100">
        {isLoading ? (
          <div className="title">
            <Placeholder animation="glow">
              <Placeholder />
            </Placeholder>
          </div>
        ) : (
          <div className="title">
            <div className="yellow-vector"></div>
            <span
              className={`title-text one-line-ellipsis ${FontHelper(
                size,
                14,
                16,
                16,
                'bold',
              )} text-left`}
            >
              {myEventData
                ? myEventData.eventTitle
                : myCourseData
                ? myCourseData.courseTitle
                : null}
            </span>
          </div>
        )}

        {isLoading ? (
          <div className="date">
            <Placeholder
              animation="glow"
              className="my-event-info-date-placeholder"
            >
              <Placeholder />
            </Placeholder>
          </div>
        ) : (
          <div className="date">
            <span className="font-14px-bold">日期 : </span>
            <span className="font-14px-medium">
              {myEventData
                ? myEventData.eventDate
                : myCourseData
                ? myCourseData.courseDatePeriod
                : null}
            </span>
          </div>
        )}

        <div className="d-flex flex-row w-100 justify-content-between align-items-center">
          <div className="myinfo-event-detail">
            {isLoading ? (
              <div className="time">
                <Placeholder
                  animation="glow"
                  className="my-event-info-time-placeholder"
                >
                  <Placeholder />
                </Placeholder>
              </div>
            ) : (
              <div className="time">
                <span className="font-14px-bold">時間 : </span>
                <span className="font-14px-medium">
                  {myEventData
                    ? myEventData.eventTimeSlot
                      ? myEventData.eventTimeSlot
                      : null
                    : myCourseData
                    ? myCourseData.courseTimeSlot
                      ? myCourseData.courseTimeSlot
                      : '請細閱課節資料'
                    : null}
                </span>
              </div>
            )}
          </div>
          {!isLoading ? (
            myEventData ? (
              myEventData.attendees[0].code ? (
                <div
                  className="text-center image-div qrcode-preview-btn"
                  // style={{ top: data?.preAgentId ? '34px' : '48px' }}
                  onClick={e => {
                    openModal?.();
                    setActiveCard?.();
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <img src={QrCode} alt="qr-code" className="drag-none" />
                  <div className="font-14px-semibold text-color-purple">
                    預覽
                  </div>
                </div>
              ) : null
            ) : myCourseData ? (
              myCourseData.courseApplicationStatus === 'reserved' ? (
                <div className="booking-status booked">已預約</div>
              ) : myCourseData.courseApplicationStatus === 'canceled' ? (
                <div className="booking-status pending">已取消</div>
              ) : myCourseData.courseApplicationStatus === 'pendingReview' ? (
                <div className="booking-status pending">待評價</div>
              ) : (
                <div className="booking-status pending">等候中</div>
              )
            ) : null
          ) : null}
        </div>
      </div>
    </div>
  );
};
