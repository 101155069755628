import ArrowRightPurple from 'assets/icons/arrow_right_purple.svg';
import 'assets/scss/Components/Button.scss';
import 'assets/scss/Components/InfoButton.scss';
import useBreakpoint from 'hooks/breakpoints';
import { Link } from 'react-router-dom';

export interface InfoButtonProps {
  link: string;
  src: string;
  text: string;
}

export const InfoButton = ({ link, src, text }: InfoButtonProps) => {
  const { width } = useBreakpoint();
  return (
    <Link to={link}>
      <div className="info-button-container">
        <img
          src={src}
          alt="icon"
          draggable={false}
          className={'info-button-icon'}
        />
        <div
          className={`${
            width <= 1200 ? 'font-16px-semibold' : 'font-24px-semibold '
          } text-color-primary`}
        >
          {text}
        </div>
        {width <= 768 ? null : (
          <img src={ArrowRightPurple} alt="arrow icon" draggable={false} />
        )}
      </div>
    </Link>
  );
};
