import { Player } from '@lottiefiles/react-lottie-player';
import Loading from 'assets/lottie/sv-loading.json';
import 'assets/scss/Components/Button.scss';
import { Link } from 'react-router-dom';

export interface ButtonProps extends ButtonCoreProps {
  link?: string;
  onClick?: () => void;
}

export interface ButtonCoreProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  customClass?: string;
  type?: 'submit' | 'button';
  disabled?: boolean;
}

export const Button = (props: ButtonProps) => {
  if (props.link) {
    return (
      <Link to={props.link} className="w-100">
        <ButtonCore {...props}></ButtonCore>
      </Link>
    );
  }
  return <ButtonCore {...props}></ButtonCore>;
};

export const CleanButton = ({
  loading,
  children,
  containerStyle,
  customClass,
  type,
  ...props
}: ButtonCoreProps) => (
  <button
    className={`clean-btn ${customClass}`}
    {...props}
    disabled={loading}
    style={containerStyle}
    type={type ? type : 'button'}
  >
    <div className="clean-button-content">
      {loading ? (
        <Player
          autoplay
          loop
          src={Loading}
          style={{ height: '20px', width: '40px' }}
        ></Player>
      ) : (
        children
      )}
    </div>
    <div className="img-layer"></div>
  </button>
);

export const ButtonCore = ({
  loading,
  children,
  containerStyle,
  customClass,
  type,
  disabled,
  ...props
}: ButtonCoreProps) => {
  return (
    <button
      className={`button ${customClass}`}
      {...props}
      disabled={loading || disabled}
      style={{
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        ...containerStyle,
      }}
      type={type ? type : 'button'}
    >
      {loading ? (
        <Player
          autoplay
          loop
          src={Loading}
          style={{ height: '50px', width: '50px' }}
        ></Player>
      ) : (
        children
      )}
      <div className="img-layer"></div>
    </button>
  );
};
