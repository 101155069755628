import { default as Checkmark } from 'assets/icons/checkmark-circle.svg';
import 'assets/scss/Components/SubmitSuccess.scss';
import { FontHelper } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { useNavigate } from 'react-router-dom';

interface Props {
  customText: string;
  email?: string;
  isResgister?: boolean;
  onClose?: () => void;
  customBtnClass?: string;
  customBtnText?: string;
}

export const SubmitSuccess = (props: Props) => {
  const { size } = useBreakpoint();
  const navigate = useNavigate();
  const {
    customText,
    email,
    isResgister,
    onClose,
    customBtnClass,
    customBtnText,
  } = props;
  return (
    <div className="success-page">
      <div className="success-content-wrapper">
        <div className="image-div">
          <img src={Checkmark} alt="" className="drag-none" />
        </div>
        <div
          className={`text-wrapper ${
            isResgister
              ? `${FontHelper(size, 16, 20, 20, 'medium')} `
              : 'font-28px-900 text-color-purple'
          }`}
        >
          {customText}
        </div>
        <div
          className={`${FontHelper(
            size,
            16,
            20,
            20,
            600,
          )} text-color-purple email-wrapper`}
        >
          {email}
        </div>
        <div className={`button-wrapper ${customBtnClass}`}>
          <button
            className="button font-18px-semibold text-color-purple"
            onClick={() => {
              onClose ? onClose() : navigate('/profile');
            }}
          >
            {customBtnText ? customBtnText : '確認'}
          </button>
        </div>
      </div>
    </div>
  );
};
