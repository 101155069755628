import 'assets/scss/Components/CommonScss/Toastify.scss';
import { TOAST_TIME } from 'config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={TOAST_TIME}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        progressClassName="toastProgress"
        bodyClassName="toastBody"
      />
    </>
  );
};

export default Toastify;
