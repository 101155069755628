import {
  CourseApplicationStatusType,
  Event,
  EventApplication,
  EventApplicationData,
  EventApplicationStatus,
  EventPaymentOptions,
  EventPaymentStatus,
  Filter,
  FormAttendees,
  ListResult,
  PaymentMethods,
} from 'models';

import Axios from '.';

/**
 * Get all Event list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses)
 */

export interface GetEventListParams extends Filter<Event> {
  isBookmarked?: boolean;
  applicationStatus?: CourseApplicationStatusType;
  startDate?: string;
  endDate?: string;
  allowRegistration?: boolean;
  eventTitle?: string;
}

export const getUserEventList = async (params: GetEventListParams) => {
  const { data } = await Axios.get<ListResult<Event>>('/me/events', {
    params,
  });
  return data;
};

export const getEventList = async (params: GetEventListParams) => {
  const { data } = await Axios.get<ListResult<Event>>('/events', {
    params,
  });
  return data;
};

/**
 * Get Event list Application User Only (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/me/events/applications)
 */

export interface GetMeEventListApplicationParams
  extends Filter<EventApplicationData> {
  status?: EventApplicationStatus | EventApplicationStatus[];
  eventId?: number | string;
  paymentStatus?: EventPaymentStatus;
  startDate?: string;
  endDate?: string;
}

export const getMeEventListApplication = async (
  params: GetMeEventListApplicationParams,
) => {
  const { data } = await Axios.get<ListResult<EventApplicationData>>(
    '/me/events/applications',
    {
      params,
    },
  );
  return data;
};

/**
 * Get One event by eventId (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/events/-eventId-)
 */

export const getOneEventByEventId = async (eventId: string | number) => {
  const { data } = await Axios.get<Event>(`/events/${eventId}`);
  return data;
};

/**
 * Get one Event Application (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/me/events/applications/-eventApplicationId-)
 */

export const getOneEventApplication = async (
  eventApplicationId: string | number,
) => {
  const { data } = await Axios.get<EventApplication>(
    `/me/events/applications/${eventApplicationId}`,
  );
  return data;
};

/**
 * Add One Event Bookmark (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/me/events/bookmarks/-eventId-)
 */

export const addEventBookmark = async (eventId: string | number | null) => {
  const { data } = await Axios.post<any>(`/me/events/bookmarks/${eventId}`);
  return data;
};

/**
 * Delete One Event Bookmark (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/me/events/bookmarks/-eventId-)
 */

export const removeEventBookmark = async (eventId: string | number | null) => {
  const { data } = await Axios.delete<any>(`/me/events/bookmarks/${eventId}`);
  return data;
};

/**
 * Submit One Event Application By Event ID (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/me/events/applications/-eventId-)
 */

export interface EventRequestBodyData {
  paymentOption?: {
    cash: number;
    point: number;
  };
  willMemberAttend: boolean;
  attendees: FormAttendees[];
  paymentMethod?: PaymentMethods;
}

export const applyForOneEvent = async (
  eventId: string | number,
  requestBody: EventRequestBodyData,
) => {
  const { data } = await Axios.post<EventApplicationData>(
    `/me/events/applications/${eventId}`,
    requestBody,
  );
  return data;
};

export interface ValidateEventApplicationParams {
  paymentOption?: EventPaymentOptions;
  willMemberAttend: boolean;
  attendees: { name: string; email: string; phoneNumber: string }[];
}

export interface ValidEventApplicationResponse {
  eventVacancy: number;
  isExceedQuota: boolean;
  totalMembershipPoint: number;
  totalPaymentAmount: number;
}

export const validateEventApplication = async (
  eventId: number,
  params: ValidateEventApplicationParams,
) => {
  const { data } = await Axios.post<ValidEventApplicationResponse>(
    `/me/events/applications/${eventId}/validate`,
    params,
  );
  return data;
};

/**
 * Verify For One Event , Optional Login (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/events/-eventId-/verifyPermission)
 */

export interface CanApplyResponse {
  canApply: boolean;
}

export const verifyPermissionForEvent = async (eventId: number) => {
  const { data } = await Axios.post<CanApplyResponse>(
    `/events/${eventId}/verifyPermission`,
  );
  return data;
};

/**
 * Cancel One Event Application, AppUser Only (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/me/events/applications/-eventApplicationId-/cancel)
 */

export const cancelOneEventApplication = async (eventApplicationId: number) => {
  const { data } = await Axios.post<any>(
    `/me/events/applications/${eventApplicationId}/cancel`,
  );
  return data;
};
