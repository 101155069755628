import 'assets/scss/Components/Drive/RenameOrAddFolderForm.scss';
import { Form, Formik } from 'formik';
import { toastError } from 'helpers';
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { ButtonCore } from '../Common';
import { InputField } from '../Form';

interface Props {
  formSubmit: (values: RenameOrAddFolderFormItemParams) => void;
  loading: boolean;
  data?: any;
  isRenaming?: boolean;
}

// form type
export interface RenameOrAddFolderFormItemParams {
  path: string;
}

// form initialValues
const FORM_ITEM = {
  path: '',
};

// define change name scehma
const PRIVATE_RENAME_FOLDER_SCHEMA = Yup.object({
  path: Yup.string()
    .min(1, '不能少於1位字元')
    .max(20, '不能多於20位字元')
    .matches(/^[^/.\u2026]+$/, '不能包含特殊符號')
    .required('必填項目'),
});

export const RenameOrAddFolderForm = ({
  formSubmit,
  loading,
  data,
  isRenaming,
}: Props) => {
  const [form, setForm] = useState<RenameOrAddFolderFormItemParams>(FORM_ITEM);

  const initFormData = useCallback(() => {
    if (data) {
      setForm({
        path: data.fileName || data,
      });
    } else {
      toastError('獲取文件夾名稱失敗，請重試');
    }
  }, [data]);

  useEffect(() => {
    if (isRenaming) {
      initFormData();
    }
  }, [initFormData, isRenaming]);

  return (
    <div className="drive-private-dialog">
      <Formik
        initialValues={form}
        validationSchema={PRIVATE_RENAME_FOLDER_SCHEMA}
        onSubmit={formSubmit}
        enableReinitialize
      >
        <Form>
          <div className="dialog-input-div">
            <InputField
              name={'path'}
              label={'新文件夾名稱：'}
              placeholder={''}
            />
          </div>
          <ButtonCore loading={loading} type={'submit'}>
            確定
          </ButtonCore>
        </Form>
      </Formik>
    </div>
  );
};
