import 'assets/scss/Components/EventSlider.scss';
import useBreakpoint from 'hooks/breakpoints';
import { SliderCardData } from 'models';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

import { LoadingCard } from './LoadingCard';
import { SliderCard } from './SliderCard';

export const EventSlider = ({
  data,
  loading,
}: {
  data: SliderCardData[];
  loading: boolean;
}) => {
  const { isMobile } = useBreakpoint();
  return (
    <>
      {data ? (
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={isMobile ? 24 : 36}
          className="event-slider"
        >
          {loading
            ? [1, 2, 3].map(item => (
                <SwiperSlide key={item}>
                  <LoadingCard />
                </SwiperSlide>
              ))
            : data.map(item => (
                <SwiperSlide key={item.eventId || item.courseId}>
                  <SliderCard data={item} loading={loading} />
                </SwiperSlide>
              ))}
        </Swiper>
      ) : null}
    </>
  );
};
