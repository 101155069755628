export const API_URL =
  process.env.REACT_APP_API_URL || 'https://svgroup-app-dev.appicidea.com';
export const IMAGE_URL =
  process.env.REACT_APP_IMAGE_URL || 'https://static.appicidea.com';
export const PAGE_SIZE = 10;
export const GRID_PAGE_SIZE = 20;
export const TOAST_TIME = 5000;
export const APP_NAME = '宏星集團 – SV Group – 亞太區頂尖財富管家';
export const IMAGE_SIZE = 1024 * 10000;
export const APP_URL =
  process.env.REACT_APP_URL || 'https://svgroup-user-dev.appicidea.com';
export const PDF_VIEWER_URL =
  process.env.REACT_PDF_VIEWER_URL ||
  'https://static.appicidea.com/public/pdf/web/viewer.html';

// versioning
export const VERSION_NUMBER = process.env.REACT_APP_VERSION || '';
export const CURRENT_ENV = process.env.REACT_APP_ENV || 'Dev';

// Sentry
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || 'development';
export const SENTRY_REL = process.env.REACT_APP_SENTRY_REL || 'DEV_REL';
