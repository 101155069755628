import { Filter, ListResult } from 'models';
import { NotificationData } from 'models/Notification';

import Axios from '.';

/**
 * List all notification of current User, Optional Login (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/me/notifications)
 */

export interface ListAllNotificationParams extends Filter<NotificationData> {
  isRead?: boolean;
}

export const ListAllNotification = async (
  params: ListAllNotificationParams,
) => {
  const { data } = await Axios.get<ListResult<NotificationData>>(
    '/me/notifications',
    { params },
  );
  return data;
};

/**
 * Read One Notification, User Only (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/me/notifications/-notificationId-/read)
 */

export const ReadOneNotification = async (notificationId: number) => {
  const { data } = await Axios.post<NotificationData>(
    `/me/notifications/${notificationId}/read`,
  );
  return data;
};

/**
 * Read All Notification, User Only (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/me/notifications/readAll)
 */

export const ReadAllNotification = async () => {
  await Axios.post<any>(`/me/notifications/readAll`);
};
