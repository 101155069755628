import 'assets/scss/Components/CommonScss/Tabbar.scss';
import { CSSProperties } from 'react';

export interface TabData {
  titleId: number;
  title: string;
  icon?: string;
  value?: string;
  activeIcon?: string;
}

export interface TabbarProps {
  data?: TabData[];
  isActiveSlideNum: number;
  btnOnClick: (titleId: number) => void;
  btnHeight?: number;
  activeBarHeight?: string;
  activeBarWidth?: string;
  justifyCenter?: boolean;
  buttonClass?: string;
  activeButtonClass?: string;
  barStyle?: CSSProperties;
  mode?: 'tab' | 'filter';
}

const Tabbar = ({
  data,
  isActiveSlideNum,
  btnHeight,
  activeBarHeight,
  activeBarWidth,
  justifyCenter = false,
  btnOnClick,
  buttonClass,
  activeButtonClass,
  barStyle,
  mode = 'tab',
}: TabbarProps) => {
  if (!data || data.length <= 0) {
    return <></>;
  }
  const buttonWidth = `calc(100% / ${data.length})`;
  const fromLeft = (100 / data.length) * (isActiveSlideNum - 1);

  return (
    <div className="toggle-nav-title-div w-100">
      <div className="w-100 d-flex flex-row justify-content-start title-container">
        {data.map(({ titleId, title, icon, activeIcon }, index) => (
          <div className={`item-div noselect ${mode}`} key={index}>
            <button
              className={`${
                index === isActiveSlideNum - 1 &&
                `${
                  activeButtonClass ? activeButtonClass : 'font-18px-semibold'
                }`
              } clean-btn button-div ${buttonClass} ${mode}`}
              style={{
                height: btnHeight ? btnHeight : '43px',
              }}
              onClick={() => btnOnClick(titleId)}
            >
              <div
                className={`shared-title-div ${
                  isActiveSlideNum === titleId ? 'title-active' : 'title-div'
                }`}
              >
                {icon && activeIcon ? (
                  index !== isActiveSlideNum - 1 ? (
                    <div className="icon">
                      <img src={icon} alt="btn-icon" draggable={false} />
                    </div>
                  ) : (
                    <div className="icon">
                      <img src={activeIcon} alt="btn-icon" draggable={false} />
                    </div>
                  )
                ) : icon ? (
                  <div className="icon">
                    <img src={icon} alt="btn-icon" draggable={false} />
                  </div>
                ) : null}
                {/* {icon && index !== isActiveSlideNum - 1 ? (
                  <div className="icon">
                    <img src={icon} alt="btn-icon" draggable={false} />
                  </div>
                ) : null}
                {activeIcon && index === isActiveSlideNum - 1 ? (
                  <div className="icon">
                    <img src={activeIcon} alt="btn-icon" draggable={false} />
                  </div>
                ) : null} */}
                <div className="title">{title}</div>
              </div>
            </button>
            <div
              className={`${mode}-bar ${
                index === isActiveSlideNum - 1 ? 'active' : ''
              }`}
            />
          </div>
        ))}
      </div>
      {mode === 'tab' ? (
        <div
          className={`btn-active-bar`}
          style={{
            left: `calc(${fromLeft}% + ${
              justifyCenter
                ? activeBarWidth
                  ? `(${buttonWidth} - ${activeBarWidth}) / 2`
                  : '0px'
                : '0px'
            })`,
            width: activeBarWidth ? activeBarWidth : buttonWidth,
            bottom: activeBarHeight ? activeBarHeight : '-6px',
          }}
        >
          <div className="bar" style={barStyle} />
        </div>
      ) : null}
    </div>
  );
};

export default Tabbar;
