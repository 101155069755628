import { InputField } from 'app/components/Form';
import 'assets/scss/Components/EventApplicationGuestInfoCard.scss';
import { FieldHookConfig, useField } from 'formik';
import useBreakpoint from 'hooks/breakpoints';
import { FormAttendees } from 'models';

interface EventApplicationGuestInfoCardProps {
  index: number;
}

export const EventApplicationGuestInfoCard = (
  props: EventApplicationGuestInfoCardProps & FieldHookConfig<FormAttendees>,
) => {
  const [field] = useField(props);
  const { isMobile } = useBreakpoint();
  const { index } = props;
  return (
    <div className="event-application-guest-info-card">
      <span className="guest-info-heading yellow-heading">{`訪客資訊 (${index})`}</span>

      {isMobile ? (
        <div className="event-application-guest-info-card-body-mobile w-100">
          <div className="guest-name">
            <InputField
              name={`${field.name}.name`}
              label="英文姓名："
              required
              placeholder="英文姓名"
            />
          </div>

          <div className="guest-email">
            <InputField
              name={`${field.name}.email`}
              label="電郵地址："
              required
              placeholder="電郵地址"
              type="email"
            />
          </div>
          <div className="guest-contact">
            <InputField
              name={`${field.name}.phoneNumber`}
              label="聯絡電話："
              required
              placeholder="聯絡電話"
            />
          </div>
        </div>
      ) : (
        <div className="event-application-guest-info-card-body row w-100">
          <div className="name-and-email-flexbox">
            <div className="guest-name">
              <InputField
                name={`${field.name}.name`}
                label="英文姓名："
                required
                placeholder="英文姓名"
              />
            </div>

            <div className="guest-email">
              <InputField
                name={`${field.name}.email`}
                label="電郵地址："
                required
                placeholder="電郵地址"
                type="email"
              />
            </div>
          </div>
          <div className="guest-contact">
            <InputField
              name={`${field.name}.phoneNumber`}
              label="聯絡電話："
              required
              placeholder="聯絡電話"
            />
          </div>
        </div>
      )}
      {/* <div className="name-and-email-flexbox">
        <div className="guest-name">
          <div className="guest-info-subheading">
            英文姓名：<span className="text-danger">*</span>
          </div>
          <div className="input-field">
            <input type="text" className="guest-input"></input>
            <img
              src={Cancel}
              alt="X"
              onClick={handleCancelOnClick}
              className="cancel-button"
            />
          </div>
        </div>
        <div className="guest-email">
          <div className="guest-info-subheading">
            電郵地址：<span className="text-danger">*</span>
          </div>
          <div className="input-field">
            <input type="email" className="guest-input"></input>
            <img
              src={Cancel}
              alt="X"
              onClick={handleCancelOnClick}
              className="cancel-button"
            />
          </div>
        </div>
      </div>
      <div className="guest-contact">
        <div className="guest-info-subheading">
          聯絡電話：<span className="text-danger">*</span>
        </div>
        <div className="input-field">
          <input type="text" className="guest-input"></input>
          <img
            src={Cancel}
            alt="X"
            onClick={handleCancelOnClick}
            className="cancel-button"
          />
        </div>
      </div> */}
    </div>
  );
};
