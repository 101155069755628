import SVMiddleButton from 'assets/icons/SV_middle_button.svg';
import ActiveDot from 'assets/icons/active_dot.svg';
import BottomHeaderShape from 'assets/images/bottom_header.svg';
import 'assets/scss/layout/BottomHeader.scss';
import { routesMap } from 'config/routes';
import { User } from 'models';
import { Fragment, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { QRcodeModal } from '../Modal';

interface BottomHeaderProps {
  user?: User;
}

export const BottomHeader = ({ user }: BottomHeaderProps) => {
  const [isQrOpen, setIsQrOpen] = useState<boolean>(false);
  const { Auth } = useAppSelector((rootState: RootState) => rootState.auth);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="bottom-header">
      {isQrOpen ? (
        <QRcodeModal isOpen={isQrOpen} setIsOpen={setIsQrOpen} user={user} />
      ) : null}
      <img
        src={BottomHeaderShape}
        alt="bottom-header"
        className="bottom-header-shape"
      />

      <button
        className="bottom-header-middle-button"
        onClick={() => {
          if (Auth && Auth?.user?.userType === '會員') {
            setIsQrOpen(!isQrOpen);
          } else if (!Auth) {
            navigate('/auth');
          }
        }}
      >
        <img
          src={SVMiddleButton}
          alt="notification icon"
          className="bottom-header-middle-button-logo"
        />
      </button>

      <div className="bottom-header-container">
        <div className="bottom-header-container-left">
          {routesMap.map((route, index) => {
            if (route.path === '/home' || route.path === '/drive') {
              return (
                <NavLink to={route.path} key={index}>
                  {({ isActive }) => (
                    <div className="bottom-header-icon-container" key={index}>
                      <img
                        src={
                          isActive ||
                          (route.path === '/home' &&
                            /^\/events\/?$/.test(location.pathname)) ||
                          (route.path === '/home' &&
                            /^\/courses\/?$/.test(location.pathname))
                            ? route.activeIcon
                            : route.icon
                        }
                        alt={`${route.title} icon`}
                      />
                      <img
                        src={ActiveDot}
                        alt="."
                        className="icon-active-dot"
                        style={{
                          display: `${
                            isActive ||
                            (route.path === '/home' &&
                              /^\/events\/?$/.test(location.pathname)) ||
                            (route.path === '/home' &&
                              /^\/courses\/?$/.test(location.pathname))
                              ? 'block'
                              : 'none'
                          }`,
                        }}
                      />
                    </div>
                  )}
                </NavLink>
              );
            } else return <Fragment key={index}></Fragment>;
          })}
        </div>
        <div className="bottom-header-container-right">
          {routesMap.map((route, index) => {
            if (route.path === '/calendar' || route.path === '/profile') {
              return (
                <NavLink to={route.path} key={index}>
                  {({ isActive }) => (
                    <div className="bottom-header-icon-container" key={index}>
                      <img
                        src={isActive ? route.activeIcon : route.icon}
                        alt={`${route.title} icon`}
                      />
                      <img
                        src={ActiveDot}
                        alt="."
                        className="icon-active-dot"
                        style={{ display: `${isActive ? 'block' : 'none'}` }}
                      />
                    </div>
                  )}
                </NavLink>
              );
            } else return <Fragment key={index}></Fragment>;
          })}
        </div>
      </div>
    </div>
  );
};
