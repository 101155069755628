import 'assets/scss/Components/CommonScss/ApplicationStepper.scss';
import { StepperMenu } from 'models';
import React from 'react';

interface ApplicationStepperProps {
  menu: StepperMenu[];
  activeIndex: number;
  isFree: boolean;
}

export const ApplicationStepper = ({
  menu,
  activeIndex,
  isFree,
}: ApplicationStepperProps) => {
  return (
    <div className="stepper">
      {menu.map(item => (
        <React.Fragment key={item.index}>
          <div className="stepper-step">
            <button
              type="button"
              className={`stepper-button ${
                activeIndex === item.index ? 'active' : null
              }`}
            >
              {item.index === 4 && isFree ? item.index - 1 : item.index}
            </button>
            <div className="stepper-label">{item.title}</div>
          </div>
          {item.index < menu.length ? <span className="stepper-line" /> : null}
        </React.Fragment>
      ))}
    </div>
  );
};
