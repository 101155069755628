import 'assets/scss/Components/CommonScss/NoResultFound.scss';

interface NoResultFoundProps {
  isFromNoti?: boolean;
}

export const NoResultFound = ({ isFromNoti }: NoResultFoundProps) => {
  return (
    <div className="no-result-found">
      <span className="no-result-found-first">{`${
        isFromNoti ? '暫時未能找到任何通知' : '未能找到任何結果'
      }`}</span>
      <span className="no-result-found-second">請選擇其他類別或刷新</span>
    </div>
  );
};
