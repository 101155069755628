import 'assets/scss/Components/CommonScss/RadioButton.scss';

interface RadioButtonProps {
  onChange?: (e) => void;
  name?: string;
  id?: string;
  className?: string;
  checked?: boolean;
}

export const RadioButton = ({
  onChange,
  name,
  id,
  className,
  checked,
}: RadioButtonProps) => {
  return (
    <div className={`custom-radio-container ${className}`}>
      <input
        className="custom-radio-before"
        type="radio"
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <span className="custom-radio-after"></span>
    </div>
  );
};
