// import { DriveObject, DriveType, FileType } from 'app/models';
import { Filter, ListResult } from 'models';
import {
  BookmarkData,
  DriveObject,
  DriveType,
  FileType,
  GetOneFileTypeRes,
  ShareDriveTokenResponse,
} from 'models/Drive';

import Axios from '.';

/**
 * Get folder list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive)
 */

export interface GetFolderListParams {
  folderType: FileType;
  driveType: DriveType;
  path: string;
}

export const getFolderList = async (params: GetFolderListParams) => {
  const { data } = await Axios.get<DriveObject[]>('/drive', {
    params,
  });
  return data;
};

/**
 * Get one file (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/file)
 */

export const getOneDriveFile = async (params: GetFolderListParams) => {
  const { data } = await Axios.get<Blob>('/drive/file', {
    params,
  });
  return data;
};

/**
 * delete one file (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/drive/file)
 */

export const deleteOneDriveFile = async (params: GetFolderListParams) => {
  const { data } = await Axios.delete<any>('/drive/file', { params });
  return data;
};

/**
 * upload one file to drive (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/upload)
 */

export const uploadOneFileToDrive = async (
  params: GetFolderListParams,
  file: FormData,
) => {
  const { data } = await Axios.post<any>('/drive/upload', file, {
    params,
    timeout: 10000,
  });
  return data;
};

/**
 * Move/ Rename File/Folder folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/rename)
 */

export const mvFileToDrive = async (params: {
  source: GetFolderListParams;
  dest: GetFolderListParams;
}) => {
  const { data } = await Axios.post<any>('/drive/rename', params);
  return data;
};

/**
 * copy File/Folder folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/copy)
 */

export const copyFileToDrive = async (params: {
  source: GetFolderListParams;
  dest: GetFolderListParams;
}) => {
  const { data } = await Axios.post<any>('/drive/copy', params);
  return data;
};

/**
 * create folder (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/createFolder)
 */

export const createFolderInDrive = async (params: GetFolderListParams) => {
  const { data } = await Axios.post<any>(
    '/drive/createFolder',
    {},
    {
      params,
    },
  );
  return data;
};

/**
 * delete folder in drive (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/drive/file)
 */

export const deleteFolderInDrive = async (params: GetFolderListParams) => {
  const { data } = await Axios.delete<null>('/drive/file', {
    params,
  });
  return data;
};

/**
 * search in folder (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/search)
 */

export interface SearchFileInDriveParams {
  folderType?: FileType;
  driveType: DriveType;
  path?: string;
  q: string;
}

export const searchFileInDrive = async (params: SearchFileInDriveParams) => {
  const { data } = await Axios.get<DriveObject[]>('/drive/search', { params });
  return data;
};

/**
 * get all bookmark list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/bookmarks)
 */

export interface GetBookmarkListParams extends Filter<BookmarkData> {}

export const getBookmarkList = async (params: GetBookmarkListParams) => {
  const { data } = await Axios.get<ListResult<BookmarkData>>(
    '/drive/bookmarks',
    { params },
  );
  return data;
};

/**
 * Add to bookmark (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/drive/bookmarks)
 */

export interface AddToBookmarkInDriveParams {
  folderType: FileType;
  driveType: DriveType;
  path: string;
}

export const addToBookmarkInDrive = async (
  params: AddToBookmarkInDriveParams,
) => {
  const { data } = await Axios.post<AddToBookmarkInDriveParams>(
    '/drive/bookmarks',
    params,
  );
  return data;
};

/**
 * Remove from bookmark (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/drive/bookmarks)
 */

export interface removeFromBookmarkInDriveParams {
  folderType: FileType;
  driveType: DriveType;
  path: string;
}

export const removeFromBookmarkInDrive = async (
  params: removeFromBookmarkInDriveParams,
) => {
  const { data } = await Axios.delete<string>('/drive/bookmarks', { params });
  return data;
};

/**
 * Get one file type (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/drive/fileType)
 */

export interface getOneFileTypeParams {
  folderType: FileType;
  driveType: DriveType;
  path: string;
}

export const getOneFileType = async (params: getOneFileTypeParams) => {
  const { data } = await Axios.get<GetOneFileTypeRes>('/drive/fileType', {
    params,
  });
  return data;
};

export const downloadOneDriveFile = async (url: string, fileName?: string) => {
  fetch(url, {
    method: 'GET',
    headers: {},
  })
    .then(response => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName || 'image.png';
        link.click();
      });
    })
    .catch(err => {
      throw err;
    });
};

export interface GetOneShareFileTypeParams extends getOneFileTypeParams {
  shareToken?: string;
}

export const getShareFolderToken = async (params: getOneFileTypeParams) => {
  const { data } = await Axios.post<ShareDriveTokenResponse>(
    '/drive/share',
    params,
  );
  return data;
};

export interface GetShareFolderListParams extends GetFolderListParams {
  shareToken?: string;
}

export const getShareFolderList = async (params: GetShareFolderListParams) => {
  const { data } = await Axios.get<DriveObject[]>('/drive/share', {
    params,
  });
  return data;
};

export const getOneShareFileType = async (
  params: GetOneShareFileTypeParams,
) => {
  const { data } = await Axios.get<GetOneFileTypeRes>('/drive/share/fileType', {
    params,
  });
  return data;
};
