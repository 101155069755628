import { API_URL } from 'config';
import {
  CalendarItem,
  Filter,
  ListResult,
  Payment,
  PaymentMethods,
  PreAgent,
  QRCodeColor,
  Statement,
  StatementStatus,
  User,
  UserLoginError,
  UserLoginPayload,
  UserPerformance,
} from 'models';
import qs from 'qs';

import Axios from '.';

export const uploadFCMToken = async (fcmToken: string) => {
  const response = await Axios.post('/me/setFcm', {
    notificationToken: fcmToken,
  });
  return response;
};

export const logout = async () => {
  await Axios.post('/me/logout');
};

export const getUserProfile = async () => {
  const { data } = await Axios.get<User>('/me/profile');
  return data;
};

export interface UpdateUserProfileParams {
  displayName: string;
  contactPhone: string;
  birthDate: string | null;
  entryDate: string | null;
  quote: string;
  chineseName: string;
  avatar: string | null;
}

export const updateUserProfile = async (params: UpdateUserProfileParams) => {
  const { data } = await Axios.patch<User>('/me/profile', params);
  return data;
};

export const getOneTimeToken = async (params: {
  email: string;
  type: 'Register' | 'ResetPassword';
}) => {
  const { data } = await Axios.post('/me/sendOTPEmail', params);
  return data;
};

export interface SignInParams {
  email?: string;
  agentLicenseNumber?: string;
  password: string;
}

export const signIn = async (params: SignInParams) => {
  const { data } = await Axios.post<UserLoginPayload>('/me/signIn', params);
  return data;
};

export const signUp = async (params: {
  email: string;
  password: string;
  token: string;
}) => {
  const { data } = await Axios.post<UserLoginPayload | UserLoginError>(
    '/me/signUp',
    params,
  );
  return data;
};

export const validateToken = async (params: {
  email: string;
  token: string;
}) => {
  const { data } = await Axios.post<boolean>(
    '/me/validateForgetEmailToken',
    params,
  );
  return data;
};

export const resetPassword = async (params: {
  email: string;
  password: string;
  token: string;
}) => {
  const { data } = await Axios.post<UserLoginPayload | UserLoginError>(
    '/me/resetPassword',
    params,
  );
  return data;
};

export const changePassword = async (params: {
  oldPassword: string;
  newPassword: string;
}) => {
  await Axios.post('/me/resetPasswordWithoutToken', params);
};

export interface PreAgentParams {
  firstName: string;
  lastName: string;
  hkId: string;
  email: string;
  studentNumber: string;
}

export const createPreAgent = async (params: PreAgentParams) => {
  const { data } = await Axios.post<PreAgent>('/me/preAgents', params);
  return data;
};

export interface GetPreAgentFilter extends Filter<PreAgent> {}

export const getPreAgentList = async (params?: GetPreAgentFilter) => {
  const { data } = await Axios.get<ListResult<PreAgent>>('/me/preAgents', {
    params,
  });
  return data;
};

export const uploadTodayPerformance = async (performance: number) => {
  await Axios.put('/performance', {
    amount: performance,
  });
};

export interface GetLatestPerformanceFilter extends Filter<UserPerformance> {}

export const getLatestPerformance = async (
  params?: GetLatestPerformanceFilter,
) => {
  const { data } = await Axios.get<ListResult<UserPerformance>>(
    '/performance/latest',
    {
      params,
    },
  );
  return data;
};

export const setUpBiometrics = async (publicKey: string) => {
  const { data } = await Axios.post<UserLoginPayload>(
    '/me/registerBiometrics',
    { publicKey },
  );
  return data;
};

export const signInWithBiometrics = async (params: {
  payload: string;
  signature: string;
  userId: number;
  rememberMe?: boolean;
}) => {
  const { data } = await Axios.post<UserLoginPayload>(
    '/me/signInWithBiometrics',
    params,
  );
  return data;
};

// Get QR Code Color of Current User, App User Only (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/me/qrcodeColor)

export const getQRCodeColor = async () => {
  const { data } = await Axios.get<QRCodeColor>('/me/qrcodeColor');
  return data;
};

export interface GetCalendarItemParams {
  startDate: string;
  endDate: string;
}

export const getCalendarItem = async (params: GetCalendarItemParams) => {
  const { data } = await Axios.get<CalendarItem[]>('/me/calendar', { params });
  return data;
};

export const terminateUser = async () => {
  await Axios.post('/me/terminate');
};

export interface GetStatementsFilter extends Filter<Statement> {
  startDate?: string;
  endDate?: string;
  isVisibleToUser?: boolean;
  status?: StatementStatus;
  'access-token'?: string;
}

export const getStatementsList = async (params?: GetStatementsFilter) => {
  const { data } = await Axios.get<ListResult<Statement>>('/me/statements', {
    params,
  });
  return data;
};

export const getOneStatement = async (statementId: number | string) => {
  const { data } = await Axios.get<Statement>(`/statements/${statementId}`);
  return data;
};

export const getCurrentStatement = async () => {
  const { data } = await Axios.get<Statement>('/me/currentStatement');
  return data;
};

export interface GetPaymentsFilter extends Filter<Payment> {}

export const getPaymentsList = async (params?: GetPaymentsFilter) => {
  const { data } = await Axios.get<ListResult<Payment>>('/me/payments', {
    params,
  });
  return data;
};

export const updatePreAgent = async (
  preAgentId: number,
  params: PreAgentParams,
) => {
  const { data } = await Axios.patch<PreAgent>(
    `/preAgents/${preAgentId}`,
    params,
  );
  return data;
};

export const deletePreAgent = async (preAgentId: number) => {
  const { data } = await Axios.delete<PreAgent>(`/preAgents/${preAgentId}`);
  return data;
};

export const getStatementPdfUri = async (params: { statementId: number }) => {
  return `${API_URL}/statements/statement.pdf?${qs.stringify(params)}`;
};

export interface SettleStatementResponse {
  paymentIntent: string;
  ephemeralKey: string;
  customer: string;
  publishableKey: string;
  eventApplicationId?: number;
  courseApplicationId?: number;
  statementId?: number;
  paymentMethod: {};
}

interface settleStatementProps {
  paymentMethod: PaymentMethods;
  statementId: number;
}

export const settleStatementById = async ({
  paymentMethod,
  statementId,
}: settleStatementProps) => {
  const { data } = await Axios.post<SettleStatementResponse>(
    `/me/statements/${statementId}/settle`,
    { paymentMethod },
  );
  return data;
};

export const settleAllPendingStatement = async (
  paymentMethod: PaymentMethods,
  token?: string,
) => {
  const { data } = await Axios.post<SettleStatementResponse>(
    '/me/statements/settleAllPending',
    { paymentMethod },
    token ? { params: { 'access-token': token } } : undefined,
  );
  return data;
};

export const getAccessToken = async () => {
  const { data } = await Axios.get<string>('/me/statements/accessToken');
  return data;
};

export interface PendingSum {
  totalAmount: number;
  oldestDueDate: string;
}
/*
 * Get Pending Sum Statements of Current User, App User Only (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/me/statements/pendingSum)
 */

export const getPendingSum = async () => {
  const { data } = await Axios.get<PendingSum>(`/me/statements/pendingSum`);
  return data;
};
