import RightArrow from 'assets/icons/arrow-right-small.svg';
import RightPurpleArrow from 'assets/icons/arrow_right_purple_small.svg';
import FirstLastArrowDisabled from 'assets/icons/first_or_last_page_arrow-disabled.svg';
import FirstLastArrow from 'assets/icons/first_or_last_page_arrow.svg';
import 'assets/scss/Components/CommonScss/CustomPagination.scss';
import { GRID_PAGE_SIZE, PAGE_SIZE } from 'config';
import useBreakpoint from 'hooks/breakpoints';
import { Pagination } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  count: number;
  gridPageSize?: boolean;
  customSize?: number;
  current: number;
  onChange?: (page: number) => void;
}

const CustomPagination = ({
  count,
  current,
  onChange,
  gridPageSize,
  customSize,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { size, width } = useBreakpoint();

  const pageOnChange = (page: number) => {
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'auto',
    // });
    document.documentElement.scrollTop = 0;
    if (onChange) {
      onChange(page);
    } else {
      const params = new URLSearchParams(location.search);
      params.set('page', page.toString());
      navigate({ search: params.toString() });
    }
  };
  const total = Math.ceil(
    count /
      (customSize ? customSize : gridPageSize ? GRID_PAGE_SIZE : PAGE_SIZE),
  );
  let pages: number[] = [];
  for (let i = 0; i < total; i++) {
    pages.push(i + 1);
  }
  const nextDisable = current === total;
  const prevDisable = current === 1;

  let start = current - (size === 'desktop' ? 5 : width > 768 ? 4 : 3),
    end = current + (size === 'desktop' ? 5 : width > 768 ? 4 : 3);
  if (start < 0) {
    start = 0;
    end = start + (size === 'desktop' ? 10 : width > 768 ? 8 : 6);
  } else if (end > total) {
    end = total;
    start = end - (size === 'desktop' ? 10 : width > 768 ? 8 : 6);
  }

  return (
    <div className="pagination-container noselect">
      <Pagination>
        {/* First Pagination */}
        {pages.length > 0 ? (
          <Pagination.Item
            disabled={prevDisable}
            className={`double-arrow-left${prevDisable ? '-disabled' : ''}`}
            onClick={() => {
              if (!prevDisable) {
                pageOnChange(1);
              }
            }}
          >
            <img
              src={prevDisable ? FirstLastArrowDisabled : FirstLastArrow}
              alt="arrow-right-icon"
              draggable={false}
            />
          </Pagination.Item>
        ) : null}

        {/* <Pagination.Prev /> */}
        {pages.length > 0 ? (
          <Pagination.Item
            disabled={prevDisable}
            className={`arrow-left`}
            onClick={() => {
              if (!prevDisable) {
                pageOnChange(current - 1);
              }
            }}
          >
            <img
              src={prevDisable ? RightArrow : RightPurpleArrow}
              alt="arrow-right-icon"
              draggable={false}
            />
          </Pagination.Item>
        ) : null}

        {/* {Page items} */}
        {pages.map((page, index) => {
          if (index >= start && index < end) {
            return (
              <Pagination.Item
                active={page === current}
                onClick={() => {
                  if (!(page === current)) pageOnChange(page);
                }}
                key={page}
                title={page.toString()}
              >
                {page}
              </Pagination.Item>
            );
          }
          return null;
        })}

        {/* <Pagination.Next /> */}
        {pages.length > 0 ? (
          <Pagination.Item
            disabled={nextDisable}
            className={`arrow-right`}
            onClick={() => {
              if (!nextDisable) {
                pageOnChange(current + 1);
              }
            }}
          >
            <img
              src={nextDisable ? RightArrow : RightPurpleArrow}
              alt="left-arrow-icon"
              draggable={false}
            />
          </Pagination.Item>
        ) : null}

        {/* Last Pagination */}
        {pages.length > 0 ? (
          <Pagination.Item
            disabled={nextDisable}
            className={`double-arrow-right${nextDisable ? '-disabled' : ''}`}
            onClick={() => {
              if (!nextDisable) {
                pageOnChange(pages.length);
              }
            }}
          >
            <img
              src={nextDisable ? FirstLastArrowDisabled : FirstLastArrow}
              alt="arrow-right-icon"
              draggable={false}
            />
          </Pagination.Item>
        ) : null}
      </Pagination>
    </div>
  );
};

export default CustomPagination;
