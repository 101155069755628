import { NotFoundPageWithUI } from 'app/pages/NotFoundPageWithUI/loadable';
import { Route, Routes } from 'react-router-dom';

import { EventApplicationCompletePage } from './EventApplicationCompletePage/loadable';
import { EventApplicationPage } from './EventApplicationPage';
import { EventDetailPage } from './EventDetailPage/loadable';

export const EventDetailRoutes = () => {
  return (
    <Routes>
      <Route index element={<EventDetailPage />}></Route>
      <Route path="/application" element={<EventApplicationPage />}></Route>
      <Route
        path="/complete/:applicationId"
        element={<EventApplicationCompletePage />}
      ></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
