import DefaultAvatar from 'assets/images/avatar.png';
import 'assets/scss/Components/Home/UserAvatar.scss';
import { getImageURL } from 'helpers';
import React from 'react';
import { Placeholder } from 'react-bootstrap';

interface Props {
  avatar?: null | string;
  displayName?: string | null;
  AvatarSize?: string | null;
  width?: number | string;
  height?: number | string;
  blobImg?: boolean;
  loading?: boolean;
}

const UserAvatar = ({
  avatar,
  displayName,
  AvatarSize,
  width,
  height,
  blobImg,
  loading,
}: Props) => {
  if (loading) {
    return (
      <div
        className="avatar"
        style={{
          zIndex: 1,
          width: `${width ? width : '172px'}`,
          height: `${height ? height : '180px'}`,
        }}
      >
        <Placeholder
          className="rounded-circle"
          animation="glow"
          style={{
            zIndex: 1,
            width: `${width ? width : '172px'}`,
            height: `${height ? height : '180px'}`,
          }}
        >
          <Placeholder style={{ height: '100%', width: '100%' }} />
        </Placeholder>
      </div>
    );
  }
  return (
    <div
      className="avatar"
      style={{
        zIndex: 1,
        width: `${width ? width : '172px'}`,
        height: `${height ? height : '180px'}`,
      }}
    >
      {
        <img
          className="rounded-circle bg-transparent drag-none "
          src={
            avatar ? (blobImg ? avatar : getImageURL(avatar)) : DefaultAvatar
          }
          style={{
            zIndex: 1,
            width: `${width ? width : '172px'}`,
            height: `${height ? height : '180px'}`,
          }}
          alt={'-'}
        />
      }
    </div>
  );
};

export default UserAvatar;
