import ActiveLockIcon from 'assets/icons/active_lock.svg';
import LockIcon from 'assets/icons/drive_private_lock.svg';
import 'assets/scss/Components/Drive/DriveSearchResult.scss';
import { FontHelper } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import _ from 'lodash';
import { DriveFile } from 'models/Drive';
import { useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { getSearchDataFail } from 'store/Drive';
import { useAppSelector } from 'store/hooks';
import { Navigation, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Loading from '../Common/Loading';
import { NoResultFound } from '../Common/NoResultFound';
import Tabbar from '../Common/Tabbar';
import FileListItem from './FileListItem';
import { FileViewer } from './FileViewer';

const menuData = [
  { titleId: 1, title: '公共文件', type: 'public' },
  {
    titleId: 2,
    title: '我的文件',
    type: 'private',
    icon: LockIcon,
    activeIcon: ActiveLockIcon,
  },
];

interface DriveSearchResultProps {
  isStickToBar?: boolean;
}

export const DriveSearchResult = ({ isStickToBar }: DriveSearchResultProps) => {
  const {
    isSearchLoading,
    isDataSearched,
    searchPublicDataResult,
    searchPrivateDataResult,
  } = useAppSelector((rootState: RootState) => rootState.drive);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [isPreviewOpen, setPreviewOpen] = useState<boolean>(false);
  const [isActiveSlideNum, setIsActiveSlideNum] = useState<number>(1);
  const swiperRef = useRef<SwiperType>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { size } = useBreakpoint();

  let groupByPublicSearchData = _.groupBy(searchPublicDataResult, 'folderType');

  // preview file
  const previewFile = (item: DriveFile) => {
    const index = _.findIndex(
      searchPublicDataResult.filter(file => file.type === 'File'),
      file => file.fileName === item.fileName,
    );
    if (index > -1) {
      setPreviewIndex(index);
      setPreviewOpen(true);
    }
  };

  const dataOnClick = (
    e: React.MouseEvent<HTMLElement>,
    dataInfo: DriveFile,
  ) => {
    const { type, folderType, driveType, fileName, dir } = dataInfo;

    if (type === 'File') {
      previewFile(dataInfo);
    } else {
      const params = new URLSearchParams(location.search);
      params.set('type', folderType);
      params.set('path', `${dir === '.' ? '' : dir}/${fileName}`);
      navigate(`/drive/${driveType}?${params.toString()}`);
      dispatch(getSearchDataFail());
    }
  };

  const titleOnClick = (titleId: number) => {
    setIsActiveSlideNum(titleId);
    swiperRef.current?.slideTo(titleId);
  };

  return (
    <>
      <div
        className={`drive-search-result-container ${
          isStickToBar ? 'drive-search-result-container-stick' : undefined
        }`}
      >
        <div className="drive-type-menu-div">
          <Tabbar
            data={menuData}
            isActiveSlideNum={isActiveSlideNum}
            btnOnClick={titleOnClick}
            buttonClass={'font-16px-semibold'}
            activeButtonClass={`${FontHelper(size, 16, 16, 18, 'semibold')}`}
          />
        </div>

        {
          <div className="search-result-div">
            <Swiper
              spaceBetween={100}
              slidesPerView={1}
              allowTouchMove={false}
              centeredSlides
              loop
              className="score-board-slider"
              modules={[Navigation]}
              onBeforeInit={swiper => {
                swiperRef.current = swiper;
              }}
            >
              <>
                {menuData.map((item, Itemindex) => (
                  <SwiperSlide key={Itemindex}>
                    {item.type === 'public' ? (
                      isSearchLoading ? (
                        <div className="is-search-loading-div">
                          <Loading />
                        </div>
                      ) : !isDataSearched ? (
                        <NoResultFound />
                      ) : (
                        <>
                          {Object.keys(groupByPublicSearchData).map(
                            (title, titleIndex) => (
                              <div
                                className="search-result-group-div"
                                key={titleIndex}
                              >
                                <div className="content-div font-20px-semibold">
                                  <div className="title-div noselect">
                                    {title === 'image'
                                      ? '相片庫'
                                      : title === 'video'
                                      ? '影音庫'
                                      : '文件庫'}
                                    <div className="title-bottom-bar"></div>
                                  </div>
                                  <div className="list-item-div">
                                    <Table>
                                      <tbody>
                                        {groupByPublicSearchData[title].map(
                                          (data, valueIndex) => {
                                            return (
                                              <FileListItem
                                                isSearching
                                                data={data}
                                                isSelect={false}
                                                dataOnClick={e =>
                                                  dataOnClick(e, data)
                                                }
                                                key={valueIndex}
                                              />
                                            );
                                          },
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            ),
                          )}
                        </>
                      )
                    ) : isSearchLoading ? (
                      <div className="is-search-loading-div">
                        <Loading />
                      </div>
                    ) : !isDataSearched ? (
                      <NoResultFound />
                    ) : (
                      <>
                        {
                          <div className="content-div font-20px-semibold">
                            <div className="list-item-div">
                              <Table>
                                <tbody>
                                  {searchPrivateDataResult.map(
                                    (data, valueIndex) => {
                                      return (
                                        <FileListItem
                                          isSearching
                                          data={data}
                                          isSelect={false}
                                          dataOnClick={e =>
                                            dataOnClick(e, data)
                                          }
                                          key={valueIndex}
                                        />
                                      );
                                    },
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        }
                      </>
                    )}
                  </SwiperSlide>
                ))}
              </>
            </Swiper>
          </div>
        }

        {isPreviewOpen && (
          <div className="modal-div">
            <FileViewer
              visible={isPreviewOpen}
              files={searchPublicDataResult || []}
              onClose={() => setPreviewOpen(false)}
              previewIndex={previewIndex}
            />
          </div>
        )}
      </div>
    </>
  );
};
