import { Filter, ListResult } from 'models';
import { ContactCategory, ContactIndividual, InfoItem } from 'models/Contact';

import Axios from '.';

export const getAllContactCategory = async (
  params: Filter<ContactCategory>,
) => {
  const { data } = await Axios.get<ListResult<ContactCategory>>(
    '/contacts/category',
    {
      params,
    },
  );
  return data;
};

export interface getDetailContactParams extends Filter<ContactIndividual> {
  isDisplayOnListingOnly?: boolean;
  contactCategoryId?: number;
}

export const getDetailContact = async (params: getDetailContactParams) => {
  const { data } = await Axios.get<ListResult<ContactIndividual>>('/contacts', {
    params,
  });
  return data;
};

export const getAllInfo = async (params: Filter<InfoItem>) => {
  const { data } = await Axios.get<ListResult<InfoItem>>('/infos', { params });
  return data;
};

export const getOneContactCategory = async (
  contactCategoryId: string | number,
) => {
  const { data } = await Axios.get<ContactCategory>(
    `/contacts/category/${contactCategoryId}`,
  );
  return data;
};
