import 'assets/scss/Components/EventPleaseShowQR.scss';
import useBreakpoint from 'hooks/breakpoints';
import { EventApplication } from 'models';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DownloadAppFirst } from './DownloadAppFirst';
import { QRcodeSliderCard } from './QRcodeSliderCard';

interface EventPleaseShowQRProps {
  eventApplicationData: EventApplication;
  isFromCompletePage?: boolean;
}

export const EventPleaseShowQR = ({
  eventApplicationData,
  isFromCompletePage,
}: EventPleaseShowQRProps) => {
  const { width } = useBreakpoint();

  const SlidesPerView = () => {
    if (width <= 480) return 1;
    else if (width > 480 && width <= 576) return 1.5;
    else if (width > 576 && width <= 768) return 1.6;
    else if (width > 768 && width <= 992) return 1.9;
    else if (width > 992 && width <= 1200) return 2;
    else if (width > 1200 && width <= 1400) return 2.4;
    else return 2.7;
  };
  return (
    <>
      {isFromCompletePage ? (
        <hr className="event-application-detail-seperation-line w-100" />
      ) : null}
      {eventApplicationData ? (
        eventApplicationData.Event.isOnline &&
        eventApplicationData.Event.zoomRoomNumber ? (
          <>
            <div className="please-show-QR font-16px-semibold">
              選擇現場入場
            </div>
            <div className="please-show-ID font-16px-semibold">
              * 會員請出示會員碼
            </div>
            <div className="please-show-QR font-16px-semibold">
              選擇網上入場
            </div>
            <DownloadAppFirst />
            {isFromCompletePage ? null : (
              <hr className="event-application-detail-seperation-line w-100" />
            )}
          </>
        ) : (
          <>
            <div className="please-show-QR font-16px-semibold">
              入場前請出示憑證
            </div>
            <div
              className="QR-swiper-container"
              style={
                eventApplicationData?.Event.isOnline &&
                !eventApplicationData?.Event.zoomRoomNumber
                  ? { marginBottom: '0px' }
                  : undefined
              }
            >
              <Swiper
                spaceBetween={24}
                slidesPerView={SlidesPerView()}
                allowTouchMove={true}
                centeredSlides
                className="QR-swiper"
              >
                {eventApplicationData.Attendees.map((attendee, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {({ isActive }) => (
                        <QRcodeSliderCard
                          data={{
                            name: attendee.name
                              ? attendee.name
                              : attendee.User.displayName,
                            code: attendee.code,
                          }}
                          isActive={isActive}
                          key={index}
                          checkin={attendee.checkInDateTime}
                          checkout={attendee.checkOutDateTime}
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </>
        )
      ) : null}
    </>
  );
};
