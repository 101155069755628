import * as Sentry from '@sentry/react';
import { ButtonCore, PageLoading } from 'app/components';
import { ApplicationPopUp } from 'app/components/Common/ApplicationPopUp';
import { ApplicationStepper } from 'app/components/Common/ApplicationStepper';
import { CustomBreadcrumbs } from 'app/components/Common/CustomBreadcrumbs';
import { EmptyResultPage } from 'app/components/Common/EmptyResultPage';
import { MobileBottomButton } from 'app/components/Common/MobileBottomButton';
import { MobileHeader } from 'app/components/Common/MobileHeader';
import { EventApplicationEventInfoCard } from 'app/components/Event/EventApplication/EventApplicationEventInfoCard';
import { EventApplicationGuestInfoCard } from 'app/components/Event/EventApplication/EventApplicationGuestInfoCard';
import { EventApplicationPaymentMethodCard } from 'app/components/Event/EventApplication/EventApplicationPaymentMethodCard';
import { EventApplicationPaymentOptionsCard } from 'app/components/Event/EventApplication/EventApplicationPaymentOptionsCard';
import { EventAttendeeInfoCard } from 'app/components/Event/EventAttendeeInfoCard';
import { BottomHeader } from 'app/components/Layout/BottomHeader';
import { Dialog } from 'app/components/Modal';
import 'assets/scss/Pages/EventPage/EventApplicationPage.scss';
import { APP_URL } from 'config';
import { FieldArray, Form, Formik } from 'formik';
import { toastError } from 'helpers';
import { isEventFree } from 'helpers/Event';
import useBreakpoint from 'hooks/breakpoints';
import {
  Event,
  EventApplicationData,
  FormAttendees,
  PaymentMethods,
  StepperMenu,
  StripePaymentResponse,
} from 'models';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  EventRequestBodyData,
  applyForOneEvent,
  getMeEventListApplication,
  getOneEventByEventId,
  validateEventApplication,
  verifyPermissionForEvent,
} from 'services/EventServices';
import { getUserProfile } from 'services/UserService';
import { RootState } from 'store';
import { updateMembershipPoint } from 'store/Auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as Yup from 'yup';

interface FormItem {
  attendees: FormAttendees[];
  info: {
    willMemberAttend: boolean;
    numberOfGuest: number;
  };
  paymentOption?: {
    cash: number;
    point: number;
  };
  paymentMethod?: string;
}

const FORM_ITEM = {
  info: {
    willMemberAttend: false,
    numberOfGuest: 0,
  },
  paymentOption: {
    cash: 0,
    point: 0,
  },
  attendees: [],
  paymentMethod: 'creditCard',
};

const SCHEMA = Yup.object({
  info: Yup.object({
    willMemberAttend: Yup.boolean(),
    numberOfGuest: Yup.number(),
  }),
  attendees: Yup.array().of(
    Yup.object({
      name: Yup.string()
        .min(2, '最少輸入2個字母')
        .required('必填項目')
        .matches(/^[a-zA-Z ]*$/, '錯誤格式'),
      email: Yup.string().email('錯誤格式').required('必填項目'),
      phoneNumber: Yup.string()
        .required('必填項目')
        .matches(/^[0-9]{8}$/, '錯誤格式'),
    }),
  ),
});

const MENU_DATA: StepperMenu[] = [
  { index: 1, title: '基本資料' },
  { index: 2, title: '核對資料' },
  { index: 3, title: '付款' },
  { index: 4, title: '完成' },
];

export const EventApplicationPage = () => {
  const params = useParams();
  const [EventData, setEventData] = useState<Event>();
  const [IsLoading, setIsLoading] = useState<boolean>(true);
  const [stepIndex, setStepIndex] = useState<number>(1);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [DialogVisible, setDialogVisible] = useState<boolean>(false);
  const [isFull, setIsFull] = useState<boolean>(false);
  const [isAlreadyApplied, setIsAlreadyApplied] = useState<boolean>(false);
  const [menu, setMenu] = useState<StepperMenu[]>([]);
  const [isFree, setFree] = useState<boolean>(false);
  const [notEnoughPoints, setNotEnoughPoints] = useState<boolean>(false);
  const [NoAttendee, setNoAttendee] = useState<boolean>(false);
  const [paymentInfo, setPaymentInfo] = useState<StripePaymentResponse | null>(
    null,
  );
  const { isMobile } = useBreakpoint();
  const dispatch = useAppDispatch();
  const { Auth } = useAppSelector((rootState: RootState) => rootState.auth);
  const [CanApply, setCanApply] = useState<boolean>(false);
  const [LoadingPermission, setLoadingPermission] = useState<boolean>(true);
  const [LoadingChecking, setLoadingChecking] = useState<boolean>(true);
  const [HasApplied, setHasApplied] = useState<EventApplicationData>();
  const [HasError, setHasError] = useState<boolean>(false);
  const [applicationId, setApplicationId] = useState<number>();
  const firstTimeLoading = useRef<boolean>(true);
  const [stepOneLoading, setStepOneLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const getEventData = useCallback(async () => {
    setIsLoading(true);
    try {
      const eventRes = await getOneEventByEventId(params.eventId || '');
      setEventData(eventRes);
      const free = isEventFree(eventRes);
      setFree(free);
      const tempMenu = [...MENU_DATA];
      if (free) {
        tempMenu.splice(2, 1);
      }
      setMenu(tempMenu.map((item, index) => ({ ...item })));
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('獲取活動資料失敗');
    } finally {
      setIsLoading(false);
    }
  }, [params.eventId]);

  const getPermission = useCallback(async () => {
    setLoadingPermission(true);
    try {
      const canApplyRes = await verifyPermissionForEvent(
        Number(params.eventId),
      );
      setCanApply(canApplyRes.canApply);
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('獲取報名權限失敗');
    } finally {
      setLoadingPermission(false);
    }
  }, [params]);

  const checkHasApplied = useCallback(async () => {
    setLoadingChecking(true);
    try {
      const appliedRes = await getMeEventListApplication({
        limit: 5,
        offset: 0,
        eventId: Number(params.eventId),
      });
      if (appliedRes.count > 0) {
        setHasApplied(appliedRes.rows[0]);
      }
    } catch (err) {
      setHasError(true);
      // console.log(err);
      Sentry.captureException(err);
      toastError('檢查報名記錄失敗');
    } finally {
      setLoadingChecking(false);
    }
  }, [params]);

  const applyForEvent = useCallback(
    async (data: EventRequestBodyData) => {
      try {
        const applyRes = await applyForOneEvent(params.eventId || '', data);
        return applyRes.eventApplicationId;
      } catch (err) {
        throw err;
      }
    },
    [params.eventId],
  );

  useEffect(() => {
    getEventData();
  }, [getEventData, params]);

  useEffect(() => {
    if (Auth) {
      getPermission();
      checkHasApplied();
    } else {
      setLoadingChecking(false);
      setLoadingPermission(false);
    }
  }, [getPermission, checkHasApplied, Auth]);

  const formSubmit = async (values: FormItem) => {
    const requestData: EventRequestBodyData = {
      paymentOption: values.paymentOption,
      willMemberAttend: values.info.willMemberAttend,
      attendees: values.attendees,
      paymentMethod: values.paymentMethod as PaymentMethods,
    };
    try {
      const applicationId = await applyForEvent(requestData);
      return applicationId;
    } catch (err: any) {
      if (err.response.data.message === 'Event is already full') {
        setIsFull(true);
        setDialogVisible(true);
        throw err;
      } else if (
        err.response.data.message === 'User already applied for this event'
      ) {
        setIsAlreadyApplied(true);
        setDialogVisible(true);
        throw err;
      } else if (err.response.data.message === 'No attendees added') {
        setNoAttendee(true);
        setDialogVisible(true);
        throw err;
      } else {
        toastError('申請時發生錯誤');
        throw err;
      }
    }
  };

  useEffect(() => {
    if (!IsLoading && !LoadingChecking && !LoadingPermission) {
      firstTimeLoading.current = false;
    }
  }, [IsLoading, LoadingChecking, LoadingPermission]);

  if (
    IsLoading ||
    (LoadingPermission && firstTimeLoading.current) ||
    (LoadingChecking && firstTimeLoading.current)
  ) {
    return (
      <div className="event-application-page page-container-with-header">
        {isMobile ? (
          <>
            <MobileHeader
              title="預約活動"
              prevPageRoute={`/events/detail/${params.eventId}`}
            />
            <BottomHeader />
          </>
        ) : null}
        <PageLoading />
      </div>
    );
  }

  if (HasError) {
    return (
      <>
        {isMobile ? (
          <>
            <MobileHeader
              title="預約活動"
              prevPageRoute={`/events/detail/${params.eventId}`}
            />
            <BottomHeader />
          </>
        ) : null}
        <EmptyResultPage error={true} />
      </>
    );
  }

  if (!EventData) {
    return (
      <>
        {isMobile ? (
          <>
            <MobileHeader
              title="預約活動"
              prevPageRoute={`/events/detail/${params.eventId}`}
            />
            <BottomHeader />
          </>
        ) : null}
        <EmptyResultPage />
      </>
    );
  }

  const stepOneValidation = async (values: FormItem) => {
    if (Auth && CanApply && !HasApplied) {
      setStepOneLoading(true);
      if (!values.info.numberOfGuest && !values.info.willMemberAttend) {
        toastError('最少一人出席');
        setStepOneLoading(false);
        return;
      }
      if (
        values.paymentOption &&
        values.paymentOption.cash <= 0 &&
        values.paymentOption.point <= 0
      ) {
        if (!isFree) {
          toastError('請選擇付款組合');
          setStepOneLoading(false);
          return;
        }
      }
      setStepOneLoading(false);
      setStepIndex(2);
    } else {
      if (!Auth) toastError('請先登入');
      else if (HasApplied) toastError('無法再次報名');
      else toastError('權限不足');
      setStepOneLoading(false);
    }
  };

  const stepTwoValidation = async (values: FormItem) => {
    if (Auth && CanApply && !HasApplied) {
      setConfirmLoading(true);
      if (isFree) {
        try {
          const applicationId = await formSubmit({
            attendees: values.attendees,
            info: values.info,
          });
          navigate(
            `/events/detail/${EventData.eventId}/complete/${applicationId}`,
          );
        } catch (err) {
          setConfirmLoading(false);
          // console.log(err);
          Sentry.captureException(err);
          return;
        } finally {
          setConfirmLoading(false);
        }
        // setStepIndex(4);
      } else {
        try {
          setConfirmLoading(true);
          const data = await validateEventApplication(EventData.eventId, {
            attendees: values.attendees,
            willMemberAttend: values.info.willMemberAttend,
            paymentOption: values.paymentOption,
          });

          if (data.isExceedQuota) {
            setIsFull(true);
            setDialogVisible(true);
          } else if (data.totalPaymentAmount === 0) {
            const profileRes = await getUserProfile();
            if (
              profileRes.membershipPoint &&
              profileRes.membershipPoint >= data.totalMembershipPoint
            ) {
              const applicationId = await formSubmit({
                ...values,
                paymentMethod: 'creditCard',
                paymentOption: values.paymentOption,
              });
              dispatch(updateMembershipPoint());
              // setStepIndex(4);
              navigate(
                `/events/detail/${EventData.eventId}/complete/${applicationId}`,
              );
            } else {
              setNotEnoughPoints(true);
              setDialogVisible(true);
            }
          } else {
            // payment
            try {
              const applyRes = await applyForOneEvent(params.eventId || '', {
                attendees: values.attendees,
                willMemberAttend: values.info.willMemberAttend,
                paymentOption: values.paymentOption,
                paymentMethod: 'creditCard',
              });
              const { customer, ephemeralKey, paymentIntent, publishableKey } =
                applyRes;
              setApplicationId(applyRes.eventApplicationId);
              setPaymentInfo({
                customer: customer || '',
                ephemeralKey: ephemeralKey || '',
                paymentIntent: paymentIntent || '',
                publishableKey: publishableKey || '',
              });
              setStepIndex(3);
            } catch (err: any) {
              throw err;
            }
          }
        } catch (err: any) {
          if (
            err.response.data.message === 'User already applied for this event'
          ) {
            setIsAlreadyApplied(true);
            setDialogVisible(true);
          } else if (
            err.response.data.message ===
            'User does not have enough membership point'
          ) {
            setNotEnoughPoints(true);
            setDialogVisible(true);
          } else if (err.response.data.message === 'No attendees added') {
            setNoAttendee(true);
            setDialogVisible(true);
          } else if (err.response.data.message === 'Event is already full') {
            setIsFull(true);
            setDialogVisible(true);
          } else {
            toastError('申請時發生錯誤');
            // console.log(err);
            Sentry.captureException(err);
          }

          setConfirmLoading(false);
          return;
        } finally {
          setConfirmLoading(false);
        }
      }
    } else {
      if (!Auth) toastError('請先登入');
      else if (HasApplied) toastError('無法再次報名');
      else toastError('權限不足');
    }
  };

  const Breadcrumb = [
    {
      title: '活動預約',
      href: '/events',
    },
    {
      title: `${EventData.eventTitle}`,
      href: `/events/detail/${EventData.eventId}`,
    },
    {
      title: '預約活動',
      active: true,
    },
  ];

  return (
    <div className="event-application-page user-select-none page-container-with-header-and-tabbar">
      {NoAttendee ? (
        <Dialog
          visible={DialogVisible}
          title={'沒有參加者'}
          onClose={() => {
            setNoAttendee(false);
            setDialogVisible(false);
          }}
          size={`${isMobile ? 'sm' : 'md'}`}
          children={
            <ApplicationPopUp
              onCancel={() => {
                setDialogVisible(false);
                setNoAttendee(false);
              }}
              onBack={() => navigate(`/events/detail/${params.eventId}`)}
              content={`請重新輸入參加者資料`}
            />
          }
        />
      ) : isFull ? (
        <Dialog
          visible={DialogVisible}
          title={'超出剩餘名額'}
          onClose={() => {
            setIsFull(false);
            setDialogVisible(false);
          }}
          size={`${isMobile ? 'sm' : 'md'}`}
          children={
            <ApplicationPopUp
              onCancel={() => {
                setDialogVisible(false);
                setIsFull(false);
              }}
              onBack={() => navigate(`/events/detail/${params.eventId}`)}
              content={`剩餘名額為 ${EventData.eventVacancy}`}
            />
          }
        />
      ) : isAlreadyApplied ? (
        <Dialog
          visible={DialogVisible}
          title={'無法重複報名'}
          onClose={() => {
            setIsAlreadyApplied(false);
            setDialogVisible(false);
          }}
          size={`${isMobile ? 'sm' : 'md'}`}
          children={
            <ApplicationPopUp
              onCancel={() => {
                setDialogVisible(false);
                setIsAlreadyApplied(false);
              }}
              onBack={() => navigate(`/events/detail/${params.eventId}`)}
              content={`會員已經報名此活動`}
            />
          }
        />
      ) : notEnoughPoints ? (
        <Dialog
          visible={DialogVisible}
          title={'用户積分不足'}
          onClose={() => {
            setNotEnoughPoints(false);
            setDialogVisible(false);
          }}
          size={`${isMobile ? 'sm' : 'md'}`}
          children={
            <ApplicationPopUp
              onCancel={() => {
                setDialogVisible(false);
                setNotEnoughPoints(false);
              }}
              onBack={() => navigate(`/events/detail/${params.eventId}`)}
              content={`用户積分不足無法報名此活動`}
            />
          }
        />
      ) : null}
      {isMobile ? (
        <MobileHeader
          title="預約活動"
          prevPageRoute={`/events/detail/${params.eventId}`}
        />
      ) : (
        <div className="event-application-breadcrumb w-100">
          <CustomBreadcrumbs items={Breadcrumb} />
        </div>
      )}

      <ApplicationStepper
        menu={menu}
        isFree={isEventFree(EventData)}
        activeIndex={stepIndex}
      />

      {paymentInfo ? (
        <EventApplicationPaymentMethodCard
          paymentInfo={paymentInfo}
          url={`${APP_URL}/events/detail/${EventData.eventId}/complete/${applicationId}`}
        />
      ) : stepIndex !== 4 ? (
        <Formik
          initialValues={FORM_ITEM}
          validationSchema={SCHEMA}
          onSubmit={async (values, actions) => {
            await getPermission();
            await checkHasApplied();
            if (stepIndex === 1) {
              await stepOneValidation(values);
            } else {
              await stepTwoValidation(values);
            }
          }}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({ values, setFieldValue, errors, validateForm }) => (
            <Form>
              <div className="event-application-body">
                {stepIndex === 1 ? (
                  <div className="event-application-body-content">
                    <EventApplicationEventInfoCard
                      data={EventData}
                      isInputing={true}
                      name="info"
                      setFieldValue={setFieldValue}
                      attendees={values.attendees}
                    />
                    {isFree ? null : (
                      <EventApplicationPaymentOptionsCard
                        EventPaymentData={EventData.paymentOptions}
                        numberOfPeople={
                          values.info.numberOfGuest +
                          (values.info.willMemberAttend ? 1 : 0)
                        }
                        name="paymentOption"
                      />
                    )}

                    <FieldArray
                      name="attendees"
                      render={arrayHelpers =>
                        values.attendees.map((attendee, index) => (
                          <EventApplicationGuestInfoCard
                            name={`attendees[${index}]`}
                            key={index}
                            index={index + 1}
                          />
                        ))
                      }
                    />
                  </div>
                ) : stepIndex === 2 ? (
                  <div className="event-application-body-content">
                    <EventApplicationEventInfoCard
                      data={EventData}
                      isInputing={false}
                      isFree={isFree}
                      paymentOption={values.paymentOption}
                      attendees={values.attendees}
                      name="info"
                    />
                    {values.attendees.map((attendee, index) => (
                      <EventAttendeeInfoCard
                        inputAttendeeData={attendee}
                        index={index}
                        key={index}
                      />
                    ))}
                  </div>
                ) : null}
                {stepIndex < 3 ? (
                  <div className="text-danger plz-double-check font-14px-semibold">
                    <span>*</span> 請細心核對以上資料
                  </div>
                ) : null}
              </div>

              {isMobile ? (
                <MobileBottomButton
                  children={
                    <div className="event-application-bottom">
                      {stepIndex === 1 ? (
                        <div className="event-application-bottom-buttons">
                          <Link
                            to={`/events/detail/${params.eventId}`}
                            className="prev-button"
                          >
                            <span>返回</span>
                          </Link>
                          <ButtonCore
                            className="confirm-button"
                            type="submit"
                            loading={
                              stepOneLoading ||
                              LoadingChecking ||
                              LoadingPermission
                            }
                            containerStyle={{
                              padding: '9px 0',
                              height: '24.5px',
                              width: isMobile ? '132px' : '158px',
                            }}
                          >
                            {EventData.eventVacancy <= 0
                              ? '名額已滿'
                              : '下一步'}
                          </ButtonCore>
                          {/* <button
                            className="confirm-button"
                            type="button"
                            onClick={async () => {
                              await getPermission();
                              await checkHasApplied();
                              await stepOneValidation(values, validateForm);
                            }}
                          >
                            {EventData.eventVacancy <= 0
                              ? '名額已滿'
                              : '下一步'}
                          </button> */}
                        </div>
                      ) : stepIndex === 2 ? (
                        <div className="event-application-bottom-buttons">
                          <button
                            className="prev-button"
                            onClick={() => setStepIndex(1)}
                          >
                            <span>返回</span>
                          </button>
                          <ButtonCore
                            className="submit-button"
                            type="submit"
                            loading={confirmLoading}
                            containerStyle={{
                              padding: '9px 0',
                              height: '24.5px',
                              width: isMobile ? '132px' : '158px',
                            }}
                          >
                            <span>確認</span>
                          </ButtonCore>
                        </div>
                      ) : stepIndex === 3 ? (
                        <div className="event-application-bottom-buttons">
                          <button
                            className="prev-button"
                            onClick={() => setStepIndex(2)}
                          >
                            <span>返回</span>
                          </button>
                          <ButtonCore
                            className="submit-button"
                            type="button"
                            loading={confirmLoading}
                            containerStyle={{
                              padding: '9px 0',
                              height: '24.5px',
                              width: isMobile ? '132px' : '158px',
                            }}
                          >
                            <span>確認</span>
                          </ButtonCore>
                        </div>
                      ) : null}
                    </div>
                  }
                />
              ) : (
                <div className="event-application-bottom">
                  {stepIndex === 1 ? (
                    <div className="event-application-bottom-buttons">
                      <Link
                        to={`/events/detail/${params.eventId}`}
                        className="prev-button"
                      >
                        <span>返回</span>
                      </Link>
                      {/* <button
                        className="confirm-button"
                        type="button"
                        onClick={async () => {
                          await getPermission();
                          await checkHasApplied();
                          await stepOneValidation(values, validateForm);
                        }}
                      >
                        {EventData.eventVacancy <= 0 ? '名額已滿' : '下一步'}
                      </button> */}
                      <ButtonCore
                        className="confirm-button"
                        type="submit"
                        loading={
                          stepOneLoading || LoadingChecking || LoadingPermission
                        }
                        containerStyle={{
                          padding: '9px 0',
                          height: '24.5px',
                          width: '158px',
                        }}
                      >
                        {EventData.eventVacancy <= 0 ? '名額已滿' : '下一步'}
                      </ButtonCore>
                    </div>
                  ) : stepIndex === 2 ? (
                    <div className="event-application-bottom-buttons">
                      <button
                        className="prev-button"
                        onClick={() => setStepIndex(1)}
                      >
                        <span>返回</span>
                      </button>
                      <ButtonCore
                        className="submit-button"
                        type="submit"
                        loading={confirmLoading}
                        containerStyle={{
                          padding: '9px 0',
                          height: '24.5px',
                          width: '158px',
                        }}
                      >
                        <span>確認</span>
                      </ButtonCore>
                    </div>
                  ) : stepIndex === 3 ? (
                    <div className="event-application-bottom-buttons">
                      <button
                        className="prev-button"
                        onClick={() => setStepIndex(2)}
                      >
                        <span>返回</span>
                      </button>
                      <ButtonCore
                        className="submit-button"
                        type="button"
                        loading={confirmLoading}
                        containerStyle={{
                          padding: '9px 0',
                          height: '24.5px',
                          width: '158px',
                        }}
                      >
                        <span>確認</span>
                      </ButtonCore>
                    </div>
                  ) : null}
                </div>
              )}
            </Form>
          )}
        </Formik>
      ) : null}

      {/* {stepIndex === 4 && ApplicationData ? (
        <EventApplicationCompletePage
          EventData={EventData}
          ApplicationData={ApplicationData}
        />
      ) : null} */}
    </div>
  );
};
