import 'assets/scss/Components/Home/PerformanceBody.scss';
import { RankingData } from 'models/Performance';
import { Placeholder } from 'react-bootstrap';

import { PerformanceBodyFirstThree } from './PerformanceBodyFirstThree';
import { PerformanceTopTen } from './PerformanceTopTen';

interface Props {
  data?: RankingData[];
  loading?: boolean;
}

const PerformanceBody = ({ data, loading }: Props) => {
  const RenderFirstThree = () => {
    if (loading) {
      return (
        <>
          <PerformanceBodyFirstThree loading={loading} placement={1} />
          <PerformanceBodyFirstThree loading={loading} placement={2} />
          <PerformanceBodyFirstThree loading={loading} placement={3} />
        </>
      );
    }
    if (!data || data.length <= 0) {
      return null;
    } else if (data.length >= 3) {
      return (
        <>
          <PerformanceBodyFirstThree data={data[0]} placement={1} />
          <PerformanceBodyFirstThree data={data[1]} placement={2} />
          <PerformanceBodyFirstThree data={data[2]} placement={3} />
        </>
      );
    } else if (data.length === 2) {
      return (
        <>
          <PerformanceBodyFirstThree data={data[0]} placement={1} />
          <PerformanceBodyFirstThree data={data[1]} placement={2} />
          <PerformanceBodyFirstThree placement={3} />
        </>
      );
    } else if (data.length === 1) {
      return (
        <>
          <PerformanceBodyFirstThree data={data[0]} placement={1} />
          <PerformanceBodyFirstThree placement={2} />
          <PerformanceBodyFirstThree placement={3} />
        </>
      );
    }
  };
  return (
    <div className="ranking-body">
      <div className="d-flex justify-content-between align-items-end mb-5 ranking-top3-container">
        {RenderFirstThree()}
      </div>

      <div className="list-ranking">
        {loading ? (
          <>
            <Placeholder animation="glow" className="list-ranking-placeholder">
              <Placeholder style={{ width: '100%' }} />
            </Placeholder>
            <Placeholder animation="glow" className="list-ranking-placeholder">
              <Placeholder style={{ width: '100%' }} />
            </Placeholder>
            <Placeholder animation="glow" className="list-ranking-placeholder">
              <Placeholder style={{ width: '100%' }} />
            </Placeholder>
            <Placeholder animation="glow" className="list-ranking-placeholder">
              <Placeholder style={{ width: '100%' }} />
            </Placeholder>
            <Placeholder animation="glow" className="list-ranking-placeholder">
              <Placeholder style={{ width: '100%' }} />
            </Placeholder>
          </>
        ) : data ? (
          data.length > 4 ? (
            data
              .slice(3)
              .map((rankItem, index) => (
                <PerformanceTopTen index={index} data={rankItem} key={index} />
              ))
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export default PerformanceBody;
