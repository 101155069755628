import { NotFoundPageWithUI } from 'app/pages/NotFoundPageWithUI/loadable';
import { Route, Routes } from 'react-router-dom';

import { CourseApplicationCompletePage } from './CourseApplicationCompletePage/loadable';
import { CourseApplicationPage } from './CourseApplicationPage/loadable';
import { CourseDetailPage } from './CourseDetailPage/loadable';

export const CourseDetailRoutes = () => {
  return (
    <Routes>
      <Route index element={<CourseDetailPage />}></Route>
      <Route path="/application" element={<CourseApplicationPage />}></Route>
      <Route
        path="/complete/:applicationId"
        element={<CourseApplicationCompletePage />}
      ></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
