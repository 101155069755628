import * as Sentry from '@sentry/react';
import { DriveEmptyPage, FileViewer, PageLoading } from 'app/components';
import FileListItem from 'app/components/Drive/FileListItem';
import FileListItemMobile from 'app/components/Drive/FileListItemMobile';
import VibesLogo from 'assets/icons/super_vibes.svg';
import 'assets/scss/Pages/DrivePage/DrivePublicPage.scss';
import { toastError } from 'helpers';
import {
  getFileType, // , getPrevPageRoute
} from 'helpers/DriveHelper';
import useBreakpoint from 'hooks/breakpoints';
import _ from 'lodash';
import { DriveFile, DriveObject, DriveType, FileType } from 'models/Drive';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  GetShareFolderListParams,
  getShareFolderList,
} from 'services/DriveService';

export const DriveSharePage = () => {
  const [driveData, setDriveData] = useState<DriveFile[]>([]);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [isPreviewOpen, setPreviewOpen] = useState<boolean>(false);
  const [Loading, setLoading] = useState<boolean>(true);
  const { isMobile } = useBreakpoint();
  const firstTimeLoading = useRef<boolean>(true);
  const [token, setToken] = useState<string>();
  const [SearchParams] = useSearchParams();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      (SearchParams.get('fileName') || SearchParams.get('url')) &&
      (SearchParams.get('type') === 'image' ||
        SearchParams.get('type') === 'video' ||
        SearchParams.get('type') === 'others' ||
        SearchParams.get('type') === 'private')
    ) {
      setPreviewOpen(true);
    }
  }, [SearchParams]);

  const renderSharedPreview = () => {
    return (
      <FileViewer
        visible={isPreviewOpen}
        files={[
          {
            type: 'File',
            folderType:
              SearchParams.get('type') === 'private'
                ? 'private'
                : SearchParams.get('type') === 'image'
                ? 'image'
                : SearchParams.get('type') === 'video'
                ? 'video'
                : 'others',
            driveType: SearchParams.get('folder')
              ? (SearchParams.get('folder') as DriveType)
              : 'public',
            fileName: SearchParams.get('path') || '',
            isBookmark: false,
            dir: '',
            fileType: getFileType(SearchParams.get('fileName') || ''),
          },
        ]}
        onClose={() => {
          setPreviewOpen(false);
        }}
        previewIndex={0}
        token={SearchParams.get('token') || ''}
        url={SearchParams.get('url') || ''}
      />
    );
  };

  const getDriveFolderData = useCallback(async () => {
    const query = new URLSearchParams(location.search);
    const type = query.get('type');
    const path = query.get('path') || '';
    const folder = query.get('folder');
    const shareToken = query.get('token');
    const fileName = query.get('fileName');
    if (shareToken) {
      setToken(shareToken);
    }
    if (type && path && folder && shareToken && !fileName) {
      setLoading(true);
      try {
        if (type) {
          const params: GetShareFolderListParams = {
            folderType: type as FileType,
            driveType: folder as DriveType,
            path,
            shareToken,
          };
          const DriveRes = await getShareFolderList(params);
          console.log(DriveRes);
          setDriveData(
            DriveRes.map(res => ({
              ...res,
              fileType: getFileType(res.fileName),
            })),
          );
        }
      } catch (err) {
        // console.log(err);
        Sentry.captureException(err);
        toastError('獲取文件夾資料失敗');
      } finally {
        setLoading(false);
        firstTimeLoading.current = false;
      }
    } else {
      firstTimeLoading.current = false;
      setLoading(false);
    }
  }, [location.search]);

  useEffect(() => {
    getDriveFolderData();
  }, [location.search, getDriveFolderData]);

  const openFolder = ({
    dir,
    fileName,
    driveType,
    folderType,
  }: DriveObject) => {
    const params = new URLSearchParams(location.search);
    params.set('path', `${dir}/${fileName}`);
    params.set('type', folderType);
    params.set('folder', driveType);
    navigate({
      pathname: `/share`,
      search: params.toString(),
    });
  };

  // preview file
  const previewFile = (item: DriveObject) => {
    const index = _.findIndex(
      driveData.filter(file => file.type === 'File'),
      file => file.fileName === item.fileName,
    );
    if (index > -1) {
      setPreviewIndex(index);
      setPreviewOpen(true);
    }
  };

  const fileOnClick = (e: React.MouseEvent<HTMLElement>, data: DriveFile) => {
    console.log(data);
    if (data.type === 'Folder') {
      openFolder(data);
    } else {
      previewFile(data);
    }
  };

  if (Loading) {
    return (
      <div className="drive-public-page-container page-container-with-header">
        <PageLoading />
      </div>
    );
  }
  return (
    <div className="drive-public-page-container page-container-with-header">
      {isMobile ? (
        <div className="bg-white position-absolute top-0 start-0 py-4 w-100 px-3 d-flex flex-row align-items-center">
          <img
            src={VibesLogo}
            className="d-inline-block align-top"
            width={150}
            alt="Super Vibes logo"
          />
        </div>
      ) : (
        <>
          <div className="px-4 pb-4">
            <img
              src={VibesLogo}
              className="d-inline-block align-top"
              width={232}
              height={33}
              alt="Super Vibes logo"
            />
          </div>
        </>
      )}

      <div className="file-item-div px-2 px-sm-4">
        {isMobile ? (
          driveData.length > 0 ? (
            <>
              {driveData.map((data, index) => (
                <FileListItemMobile
                  data={data}
                  isSelect={false}
                  selectedItem={[]}
                  dataOnClick={e => fileOnClick(e, data)}
                  key={index}
                  getDriveListData={() => {
                    getDriveFolderData();
                  }}
                  isShareItem
                  token={token}
                />
              ))}
            </>
          ) : (
            <DriveEmptyPage />
          )
        ) : driveData.length > 0 ? (
          <Table>
            <tbody>
              {driveData.map((data, index) => (
                <FileListItem
                  data={data}
                  isSelect={false}
                  selectedItem={[]}
                  dataOnClick={e => fileOnClick(e, data)}
                  key={index}
                  getDriveListData={() => {
                    getDriveFolderData();
                  }}
                  isShareItem
                  token={token}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <DriveEmptyPage />
        )}
      </div>

      {SearchParams.get('url') &&
      (SearchParams.get('type') === 'image' ||
        SearchParams.get('type') === 'video' ||
        SearchParams.get('type') === 'others' ||
        SearchParams.get('type') === 'private') ? (
        renderSharedPreview()
      ) : isPreviewOpen ? (
        <FileViewer
          visible={isPreviewOpen}
          files={driveData || []}
          onClose={() => setPreviewOpen(false)}
          previewIndex={previewIndex}
          token={token}
        />
      ) : null}
    </div>
  );
};
