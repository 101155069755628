import * as Sentry from '@sentry/react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentMaintenance } from 'services/MaintenanceServices';

const useMaintenance = () => {
  const navigate = useNavigate();

  const checkMaintenance = useCallback(async () => {
    try {
      const arrayOfMaintenance = await getCurrentMaintenance();
      if (arrayOfMaintenance && arrayOfMaintenance.length > 0) {
        navigate('/maintenance');
      }
    } catch (err) {
      // console.log(error);
      Sentry.captureException(err);
    }
  }, [navigate]);

  useEffect(() => {
    checkMaintenance();
  }, [checkMaintenance]);
};

export default useMaintenance;
