import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import 'assets/scss/Components/Profile/PaymentDetail.scss';
import { APP_URL } from 'config';
import { StripePaymentResponse } from 'models';
import { useState } from 'react';
import { PendingSum } from 'services/UserService';

import CustomCheckoutForm from '../Payment/CustomCheckoutForm';
import { OutstandingAmountCard } from './OutstandingAmountCard';

interface Props {
  statementTotal?: PendingSum | null;
  paymentInfo: StripePaymentResponse;
  disabled?: boolean;
  redirectURL?: string;
}

const PaymentDetail = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { statementTotal, paymentInfo, disabled, redirectURL } = props;

  const stripePromise = loadStripe(paymentInfo.publishableKey);
  const options: StripeElementsOptions = {
    // passing the client secret obtained in step 3
    clientSecret: paymentInfo.paymentIntent,
    // Fully customizable with appearance API.
    appearance: {
      theme: 'stripe',
    },
    customerOptions: {
      customer: paymentInfo.customer,
      ephemeralKey: paymentInfo.ephemeralKey,
    },
  };
  return (
    <div className="payment-record-container">
      {/* <PaymentDetailInfoCard statement={statement} /> */}

      <Elements stripe={stripePromise} options={options}>
        <CustomCheckoutForm
          setLoading={setLoading}
          customPayNowComponent={
            <div className="outstanding-amount-card-container">
              <OutstandingAmountCard
                isLink={false}
                amount={statementTotal?.totalAmount ?? 0}
                dueDate={statementTotal?.oldestDueDate}
                loading={loading}
                disabled={disabled}
              />
            </div>
          }
          redirectURL={redirectURL ?? `${APP_URL}/profile/fee?page=success`}
        />
      </Elements>
    </div>
  );
};

export default PaymentDetail;
