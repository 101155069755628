import * as Sentry from '@sentry/react';
import FileIcon from 'assets/icons/drive_document_icon.svg';
import FolderIcon from 'assets/icons/drive_folder_icon.svg';
import ImageIcon from 'assets/icons/drive_image_icon_small.svg';
// import 'assets/scss/Components/Drive/FileListItem.scss';
import PrivateDeleteIcon from 'assets/icons/drive_private_remove_icon_purple.svg';
import SmallCheckCircle from 'assets/icons/drive_ticked_circle_small.svg';
import VideoIcon from 'assets/icons/drive_video_icon_small.svg';
import 'assets/scss/Components/Drive/FileListItemMobile.scss';
import { toastError, toastSuccess } from 'helpers';
import { getFilePreviewLink, getFileType } from 'helpers/DriveHelper';
import { BookmarkData, DriveFile } from 'models/Drive';
import { Dispatch, useState } from 'react';
import { removeFromBookmarkInDrive } from 'services/DriveService';

import { BookmarkButton } from '../Common/BookmarkButton';
import Image from '../Common/Image';
import Loading from '../Common/Loading';
import { DropDownMenu } from './DropDownMenu';

interface Props {
  data: DriveFile;
  isSelect: boolean;
  setSelectedItem?: (item: string[]) => void;
  dataOnClick?: (e) => void;
  selectedItem?: string[];
  setSelectedItemObject?: Dispatch<React.SetStateAction<DriveFile[]>>;
  isSearching?: boolean;
  getDriveListData?: () => void;
  isMoveFolderInPrivate?: boolean;
  isPrivate?: boolean;
  setIsMoveFolderDialog?: () => void;
  // selectedItemInDialog?: string[];
  isBookmark?: boolean;
  isShareItem?: boolean;
  token?: string;
}

const FileListItemMobile = ({
  isSearching,
  selectedItem,
  setSelectedItem,
  setSelectedItemObject,
  data,
  dataOnClick,
  isSelect,
  getDriveListData,
  isMoveFolderInPrivate,
  isPrivate,
  setIsMoveFolderDialog,
  isBookmark,
  isShareItem,
  token,
}: // selectedItemInDialog,

Props) => {
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [bookMarked, setBookMarked] = useState<boolean>(data.isBookmark);

  const BookmarkParams = {
    folderType: data.folderType,
    driveType: data.driveType,
    path: `${data.dir ? data.dir + '/' : ''}${data.fileName}`,
  };

  const isBeingSelected =
    isSelect &&
    !isSearching &&
    selectedItem &&
    selectedItem.includes(
      `${data.folderType}/${data.dir ? data.dir + '/' : ''}${data.fileName}`,
    );

  const showBookmarkButton =
    !isBeingSelected && data.type !== 'Folder' && !isSelect && !isShareItem;

  const removeFromBookmarkOnclick = async (
    e: React.MouseEvent<HTMLElement>,
    data: BookmarkData,
  ) => {
    e.stopPropagation();
    const { folderType, driveType, dir, fileName } = data;
    const params = {
      folderType,
      driveType,
      path: `${dir ? dir + '/' : ''}${fileName}`,
    };
    try {
      setRemoveLoading(true);
      await removeFromBookmarkInDrive(params);
      getDriveListData?.();
      toastSuccess('移除成功');
      setRemoveLoading(false);
    } catch (err) {
      // console.log(err);
      Sentry.captureException(err);
      toastError('移除失敗，請重試');
      setRemoveLoading(false);
    } finally {
      setRemoveLoading(false);
    }
  };

  return (
    <div className="drive-list-item-mobile">
      {isBeingSelected ? (
        <img
          src={SmallCheckCircle}
          alt=""
          className="drive-list-item-mobile-tick"
        />
      ) : null}

      <div
        className={`drive-list-item-mobile-image-container ${
          isBeingSelected
            ? 'drive-list-item-mobile-image-container-selected'
            : undefined
        }`}
        onClick={dataOnClick}
      >
        {showBookmarkButton ? (
          <div className="drive-list-item-mobile-bookmark-button">
            <BookmarkButton
              isBookmarked={bookMarked}
              driveItemParams={BookmarkParams}
              setBookmark={setBookMarked}
            />
          </div>
        ) : null}

        <div
          className="drive-list-item-mobile-image"
          style={
            showBookmarkButton ? { borderRadius: '0 20px 0 0' } : undefined
          }
        >
          {getFileType(data.fileName) !== 'image' &&
          getFileType(data.fileName) !== 'video' &&
          data.type !== 'Folder' ? (
            <img
              src={FileIcon}
              alt=""
              draggable={false}
              width={150}
              height={150}
            />
          ) : (
            <Image
              className="preview-image"
              src={
                data.type === 'Folder'
                  ? getFilePreviewLink(
                      data.folderType,
                      data.dir,
                      `.${data.fileName}`,
                      data.driveType,
                      token,
                    )
                  : getFilePreviewLink(
                      data.folderType,
                      data.dir,
                      `${data.fileName}`,
                      data.driveType,
                      token,
                    )
              }
              alt={data.fileName}
              draggable={false}
              imagewidth={150}
              imageheight={150}
              customstyle={{
                borderRadius: '10px',
                objectFit: 'cover',
              }}
              fallbackimagecomponent={
                data.type === 'Folder' ? (
                  <img
                    src={FolderIcon}
                    alt=""
                    draggable={false}
                    width={150}
                    height={150}
                  />
                ) : getFileType(data.fileName) === 'image' ? (
                  <img
                    src={ImageIcon}
                    alt=""
                    className=""
                    draggable={false}
                    width={150}
                    height={150}
                  />
                ) : getFileType(data.fileName) === 'video' ? (
                  <img
                    src={VideoIcon}
                    alt=""
                    className=""
                    draggable={false}
                    width={150}
                    height={150}
                  />
                ) : (
                  <img
                    src={FileIcon}
                    alt=""
                    draggable={false}
                    width={150}
                    height={150}
                  />
                )
              }
            />
          )}
        </div>
      </div>
      <div className="drive-list-item-mobile-bottom">
        <div className="drive-list-item-mobile-title one-line-ellipsis">
          {data.fileName}
        </div>
        <div className="drive-list-item-mobile-dropdown">
          {isBookmark ? (
            <div
              className="noselect"
              onClick={e => removeFromBookmarkOnclick(e, data)}
            >
              {removeLoading ? (
                <div>
                  <Loading playerHeight={24} playerWidth={24} />
                </div>
              ) : (
                <img
                  src={PrivateDeleteIcon}
                  alt="remove_icon"
                  draggable={false}
                  className=""
                />
              )}
            </div>
          ) : data.driveType === 'public' && data.type === 'Folder' ? null : (
            <div className="option-div">
              <DropDownMenu
                data={data}
                getDriveListData={getDriveListData}
                isPrivate={isPrivate}
                setSelectedItemObject={setSelectedItemObject}
                itemLink={getFilePreviewLink(
                  data.folderType,
                  data.dir,
                  data.fileName,
                  data.driveType,
                  token,
                )}
                notBookmarked={!bookMarked}
                setBookmarked={setBookMarked}
                fileType={data.type}
                isShareItem={isShareItem}
                setIsMoveFolderDialog={setIsMoveFolderDialog}
                setSelectedItem={setSelectedItem}
                token={token}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileListItemMobile;
