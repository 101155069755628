import * as Sentry from '@sentry/react';
import CancelSearch from 'assets/icons/cancel_search.svg';
import SearchbarSearchIcon from 'assets/icons/drive_search_bar_icon_grey.svg';
import 'assets/scss/Components/Drive/DriveSearchBar.scss';
import { toastError } from 'helpers';
import { getFileType } from 'helpers/DriveHelper';
import _ from 'lodash';
import { DriveType } from 'models/Drive';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { searchFileInDrive } from 'services/DriveService';
import { RootState } from 'store';
import {
  getDriveDataFail,
  getDriveDataLoadingStart,
  getDriveDataSuccess,
  getSearchDataFail,
  getSearchDataSuccess,
  setSearchPrivateDataResult,
  setSearchPublicDataResult,
} from 'store/Drive';
import { useAppSelector } from 'store/hooks';

interface Props {
  isFromPurpleLens?: boolean;
}

const DriveSearchBar = ({ isFromPurpleLens }: Props) => {
  const [text, setText] = useState<string | null>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const { isDataSearched } = useAppSelector(
    (rootState: RootState) => rootState.drive,
  );

  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounce = useCallback(_.debounce(setSearchValue, 500), []);

  const textOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    debounce(e.target.value);
  };

  const getFolderSearch = useCallback(async () => {
    if (searchValue && searchValue.length > 1) {
      try {
        dispatch(getDriveDataLoadingStart());

        //getting image ,video and others foldertype only...

        const publicResultImageRes = await searchFileInDrive({
          driveType: 'public' as DriveType,
          folderType: 'image',
          q: searchValue,
        });
        const publicResultVideoRes = await searchFileInDrive({
          driveType: 'public' as DriveType,
          folderType: 'video',
          q: searchValue,
        });
        const publicResultOthersRes = await searchFileInDrive({
          driveType: 'public' as DriveType,
          folderType: 'others',
          q: searchValue,
        });
        const privateResultRes = await searchFileInDrive({
          driveType: 'private' as DriveType,
          folderType: 'private',
          q: searchValue,
        });

        const publicResultRes = publicResultImageRes.concat(
          publicResultVideoRes.concat(publicResultOthersRes),
        );

        dispatch(getSearchDataSuccess());
        dispatch(
          setSearchPublicDataResult(
            publicResultRes.map(res => ({
              ...res,
              fileType: getFileType(res.fileName),
            })),
          ),
        );
        dispatch(
          setSearchPrivateDataResult(
            privateResultRes.map(res => ({
              ...res,
              fileType: getFileType(res.fileName),
            })),
          ),
        );
        dispatch(getDriveDataSuccess());
      } catch (err) {
        // console.log(err);
        Sentry.captureException(err);
        dispatch(getDriveDataFail());
        dispatch(getSearchDataFail());
        toastError('搜尋時發生錯誤');
      }
      // finally {
      //   dispatch(getDriveDataFail());
      // }
    } else {
      dispatch(getSearchDataFail());
    }
  }, [dispatch, searchValue]);

  useEffect(() => {
    getFolderSearch();
  }, [getFolderSearch]);

  return (
    <div
      className={`search-bar-container ${
        isFromPurpleLens ? 'mobile-header-search-bar-container' : undefined
      }`}
    >
      <div className="search-icon-div">
        <div
          className={`search-input-field card ${
            isFromPurpleLens ? 'mobile-header-search-input-field' : undefined
          }`}
        >
          <Form.Control
            value={text || ''}
            type="text"
            onChange={textOnChange}
            // autoFocus
          />
          <img
            src={SearchbarSearchIcon}
            draggable={false}
            alt="search-icon"
            className="search-icon-grey"
            width={24}
            height={24}
          />
          <img
            src={CancelSearch}
            alt="x"
            width={24}
            height={24}
            className={`cursor-pointer ${
              isFromPurpleLens ? 'search-cancel' : 'search-cancel-desktop'
            } ${isDataSearched ? '' : 'display-none'}`}
            onClick={e => {
              setText('');
              setSearchValue('');
              e.preventDefault();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DriveSearchBar;
