import PrevPage from 'assets/icons/prev_page.svg';
import 'assets/scss/Components/CommonScss/MobileHeader.scss';
import { useNavigate } from 'react-router-dom';

interface MobileHeaderProps {
  title: string;
  icon?: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
  prevPageRoute?: string;
  headerRight?: React.ReactNode;
  //prevPageOnClick for customizing the navigating behavior
  prevPageOnClick?: void | (() => void);
}

export const MobileHeader = ({
  title,
  icon,
  headerRight,
  prevPageRoute,
  prevPageOnClick,
}: MobileHeaderProps) => {
  const navigate = useNavigate();
  const MobileHeaderLeftOnClick = () => {
    if (prevPageRoute) {
      navigate(`${prevPageRoute}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="mobile-header">
      <div className="mobile-header-left">
        <button
          onClick={() => {
            if (prevPageOnClick) {
              prevPageOnClick();
            } else {
              MobileHeaderLeftOnClick();
            }
          }}
        >
          <img src={PrevPage} alt="<" />
        </button>

        {title === '' || !title ? null : (
          <div
            className={`one-line-ellipsis ${
              icon ? 'mobile-header-title-with-icon' : 'mobile-header-title'
            }`}
          >
            <>{icon ? icon : null}</>
            <span>{title}</span>
          </div>
        )}
      </div>
      <div
        className={`mobile-header-right ${
          title === '' || !title ? 'w-100' : undefined
        }`}
      >
        {headerRight}
      </div>
    </div>
  );
};
