import ArrowRight from 'assets/icons/arrow-right.svg';
import Coin from 'assets/icons/coin.svg';
import 'assets/scss/Components/UserInfoCard.scss';
import { FontHelper, numberWithCommas } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { User } from 'models';

import { Button } from '../Common';

interface UserInfoCardProps {
  user?: User;
  dailyCount: number;
  weeklyCount: number;
  MembershipPoint: number | null;
}

export const UserInfoCard = ({
  user,
  dailyCount,
  weeklyCount,
  MembershipPoint,
}: UserInfoCardProps) => {
  const { size, isMobile } = useBreakpoint();
  return (
    <div className="user-info-card">
      <div className="d-flex flex-row align-items-center justify-content-between w-100">
        <div
          className={`${FontHelper(
            size,
            18,
            24,
            24,
            'bold',
          )} text-color-primary d-flex flex-wrap align-items-center`}
        >
          <span className="text-nowrap">你好！</span>
          {user?.displayName}
        </div>
        <div className="d-flex flex-row">
          <img src={Coin} alt="coin icon" width={isMobile ? 24 : 30} />
          <div className={`ms-1 font-${isMobile ? '22px' : '24px'}-bold`}>
            {MembershipPoint ? numberWithCommas(MembershipPoint) : '-'}
          </div>
        </div>
      </div>
      {user ? (
        <div className="w-100 d-flex flex-row align-items-center justify-content-between mt-3">
          <div className={`w-100 flex-grow-1 ${isMobile ? 'pe-2' : 'pe-3'}`}>
            <Button
              containerStyle={{ padding: 16, height: 'unset' }}
              link="/calendar"
            >
              <div className="w-100 d-flex flex-column align-item-start justify-content-between">
                <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex flex-row mb-3">
                    <div
                      className={`${FontHelper(
                        size,
                        16,
                        18,
                        18,
                        'semibold',
                      )} text-color-primary`}
                    >
                      今天
                    </div>
                    <img
                      src={ArrowRight}
                      alt="arrow right icon"
                      draggable="false"
                      width={isMobile ? 16 : 24}
                    />
                  </div>
                  <div
                    className={`font-${
                      isMobile ? '36px' : '42px'
                    }-bold text-primary`}
                  >
                    {dailyCount}
                  </div>
                </div>
                <div
                  className={`w-100 ${FontHelper(
                    size,
                    12,
                    18,
                    18,
                    'bold',
                  )} text-color-primary text-end`}
                >
                  個課程及活動
                </div>
              </div>
            </Button>
          </div>

          <div className={`w-100 flex-grow-1 ${isMobile ? 'ps-2' : 'ps-3'}`}>
            <Button
              containerStyle={{ padding: 16, height: 'unset' }}
              link="/calendar"
            >
              <div className="w-100 d-flex flex-column align-item-start justify-content-between">
                <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex flex-row mb-3">
                    <div
                      className={`${FontHelper(
                        size,
                        16,
                        18,
                        18,
                        'semibold',
                      )} text-color-primary`}
                    >
                      未來7日
                    </div>
                    <img
                      src={ArrowRight}
                      alt="arrow right icon"
                      draggable="false"
                      width={isMobile ? 16 : 24}
                    />
                  </div>
                  <div
                    className={`font-${
                      isMobile ? '36px' : '42px'
                    }-bold text-primary`}
                  >
                    {weeklyCount}
                  </div>
                </div>
                <div
                  className={`w-100 ${FontHelper(
                    size,
                    12,
                    18,
                    18,
                    'bold',
                  )} text-color-primary text-end`}
                >
                  個課程及活動
                </div>
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <div className="w-50 d-flex flex-column align-items-center justify-content-center">
          <div
            className={`${FontHelper(
              size,
              16,
              24,
              24,
              'bold',
            )} text-color-primary mb-3 text-nowrap`}
          >
            如有帳戶，請立即登入！
          </div>
          <Button link="/auth">登入</Button>
        </div>
      )}
    </div>
  );
};
