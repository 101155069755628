import { Player } from '@lottiefiles/react-lottie-player';
import LoadingIcon from 'assets/lottie/sv-loading.json';

interface Props {
  playerWidth?: number;
  playerHeight?: number;
}

const Loading = ({ playerWidth, playerHeight }: Props) => {
  return (
    <div className="loading-container">
      <Player
        autoplay
        loop
        src={LoadingIcon}
        style={{
          height: playerHeight ? playerHeight : '80px',
          width: playerWidth ? playerWidth : '80px',
        }}
      ></Player>
    </div>
  );
};

export default Loading;
