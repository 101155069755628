import DuePayIcon from 'assets/icons/due-payment.svg';
import CloseButton from 'assets/icons/payment-due-modal-close.svg';
import 'assets/scss/Components/Profile/PaymentDuePopupModal.scss';
import moment from 'moment';
import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

interface PaymentDuePopupModalProps {
  visible: boolean;
  onClose: () => void;
}

export const PaymentDuePopupModal = ({
  visible,
  onClose,
}: PaymentDuePopupModalProps) => {
  const { qrCodeColor } = useAppSelector(rootState => rootState.auth);
  const { pendingSum } = useAppSelector(rootState => rootState.app);

  const modalText = useMemo(() => {
    if (qrCodeColor?.color === 'yellow') {
      return `溫馨提示: 帳單已經逾期${qrCodeColor.noOfDaysExpired}天，為避免 閣下帳戶服務被受影響，\n敬請盡快繳費處理。\n\n此訊息有時間延遲，如果您已經完成了付款，請忽略此訊息。\n查詢：accounting@svgroup.hk`;
    }
    if (qrCodeColor?.color === 'red') {
      return `溫馨提示: 帳單已經逾期${
        qrCodeColor.noOfDaysExpired
      }天\n\n如 閣下未能於${moment(pendingSum?.oldestDueDate)
        .add(30, 'days')
        .locale('en')
        .format(
          'DD-MMM-YYYY HH:mm',
        )}前完成繳費的話，閣下帳戶將被暫停使用，\n同時所有欠費的帳單會被加收10%逾期手續費。\n\n此訊息有時間延遲，如果您已經完成了付款，請忽略此訊息。\n查詢：accounting@svgroup.hk`;
    }
  }, [
    pendingSum?.oldestDueDate,
    qrCodeColor?.color,
    qrCodeColor?.noOfDaysExpired,
  ]);

  return (
    <Modal
      show={visible}
      onHide={onClose}
      dialogClassName="payment-due-modal-dialog"
      contentClassName="payment-due-modal-content"
    >
      <div className="payment-due-modal">
        <button
          type="button"
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
          className="payment-due-close-button"
        >
          <img src={CloseButton} alt="X" />
        </button>
        <div className="payment-due-modal-content-box">
          <img
            src={DuePayIcon}
            alt="due Payment"
            className="not-found-page-withUI-icon"
          />
          <div className="payment-due-title">{modalText}</div>
          <Link
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
            to={`/profile/fee/payment`}
            rel="noreferrer"
          >
            <Button>繳費</Button>
          </Link>
        </div>
      </div>
    </Modal>
  );
};
