import Calendar from 'assets/icons/calendar_purple.svg';
import Coin from 'assets/icons/coin.svg';
import Flag from 'assets/icons/flag.svg';
import MoneyBag from 'assets/icons/money_bag.svg';
import TickIcon from 'assets/icons/tick.svg';
import Warning from 'assets/icons/warning.svg';
import BannerImage from 'assets/images/login_bg.jpeg';
import 'assets/scss/Components/SliderCard.scss';
import {
  FontHelper,
  formatPrice,
  getImageURL,
  numberWithCommas,
  toClientPrice,
} from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { SliderCardData } from 'models';
import { useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { BookmarkButton } from '../Common/BookmarkButton';

const SliderCardContent = ({ data }: { data: SliderCardData }) => {
  const {
    title,
    eventId,
    courseId,
    memberOnly,
    courseCode,
    displayDate,
    isEndSoon,
    images,
    paymentOptions,
    isBookmarked,
  } = data;
  const { size } = useBreakpoint();
  const [bookMarked, setBookMarked] = useState<boolean>(isBookmarked);
  return (
    <div className="slider-card">
      <div className="slide-image-container position-relative">
        <img
          src={images && images.length ? getImageURL(images[0]) : BannerImage}
          draggable="false"
          alt="banner"
          className="slide-image"
        />
        <BookmarkButton
          smallerBookmark={true}
          isBookmarked={bookMarked}
          eventId={eventId}
          courseId={courseId}
          setBookmark={setBookMarked}
        />
      </div>
      <div className="w-100 slide-info-contaienr p-3">
        <div className="title-text one-line-ellipsis font-16px-bold text-color-primary text-left">
          {title}
        </div>

        {eventId && (
          <div className="d-flex flex-row eventinfo-item">
            <img src={TickIcon} alt="tick icon" />
            <div
              className={`member-only ${FontHelper(
                size,
                12,
                14,
                14,
                'medium',
              )} ms-1 text-nowrap`}
            >
              {memberOnly ? '會員專用' : '公眾參與'}
            </div>
          </div>
        )}
        {courseId && (
          <div className="font-14px-medium courseID eventinfo-item text-nowrap">
            課程編號：{courseCode}
          </div>
        )}
        <div className="d-flex flex-row eventinfo-item">
          <img src={Calendar} alt="" />
          <div className="font-16px-medium ms-2">{displayDate}</div>
        </div>
        <div className="d-flex flex-row align-items-start eventinfo-item">
          <img src={MoneyBag} alt="" draggable="false" className="me-2" />
          <div className="d-flex flex-column w-100 align-items-start">
            {paymentOptions.length > 0 ||
            paymentOptions.some(
              option => option.cash === 0 && option.point === 0,
            ) ? (
              paymentOptions.map((option, index) => (
                <div key={index}>
                  {option.cash && option.point ? (
                    <span className="d-flex align-items-center payment-item">
                      {`HKD ${formatPrice(toClientPrice(option.cash), true)}`}
                      &nbsp;+&nbsp;
                      <img src={Coin} alt="Coin" />
                      &nbsp;{numberWithCommas(option.point)}
                    </span>
                  ) : option.cash && !option.point ? (
                    <span className="d-flex align-items-center payment-item">{`HKD ${formatPrice(
                      toClientPrice(option.cash),
                      true,
                    )}`}</span>
                  ) : !option.cash && option.point ? (
                    <span className="d-flex align-items-center payment-item">
                      <img src={Coin} alt="Coin" />
                      &nbsp;{numberWithCommas(option.point)}
                    </span>
                  ) : null}
                </div>
              ))
            ) : (
              <div className="text-nowrap">免費</div>
            )}
          </div>
        </div>

        <div className="d-flex flex-row eventinfo-item">
          {isEndSoon && (
            <div className="warning d-flex flex-row">
              <img src={Warning} alt="" />
              <div
                className={`warning-text ${FontHelper(
                  size,
                  12,
                  14,
                  14,
                  'bold',
                )} text-danger text-nowrap`}
              >
                即將截止
              </div>
            </div>
          )}
          {data.eventId && data.vacancy <= 0 ? (
            <div className="d-flex flex-row">
              <img src={Flag} alt="" draggable="false" />
              <div
                className={`flag-text ${FontHelper(
                  size,
                  12,
                  14,
                  14,
                  'bold',
                )} text-danger text-nowrap`}
              >
                名額已滿
              </div>
            </div>
          ) : data.vacancy > 0 && data.isFewQuotaLeft ? (
            <div className="d-flex flex-row">
              <img src={Flag} alt="" draggable="false" />
              <div
                className={`flag-text ${FontHelper(
                  size,
                  12,
                  14,
                  14,
                  'bold',
                )} text-danger text-nowrap`}
              >
                剩餘少量名額
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const SliderCard = ({
  data,
  loading,
}: {
  data: SliderCardData;
  loading: boolean;
}) => {
  const { eventId, courseId } = data;
  if (loading) {
    return (
      <div className="slider-card noselect">
        <div className="slide-image-container position-relative">
          <Placeholder className="loading-slide-image" animation="glow">
            <Placeholder xs={12} />
          </Placeholder>
        </div>
        <div className="w-100 slide-info-contaienr p-3">
          <Placeholder animation="glow">
            <Placeholder xs={6} style={{ width: 200 }} />
          </Placeholder>

          <Placeholder animation="glow">
            <Placeholder xs={6} style={{ width: 100 }} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={6} style={{ width: 200 }} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={6} style={{ width: 250 }} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={6} style={{ width: 250 }} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={6} style={{ width: 250 }} />
          </Placeholder>

          <Placeholder animation="glow">
            <Placeholder xs={6} style={{ width: 60 }} />{' '}
            <Placeholder xs={6} style={{ width: 60 }} />
          </Placeholder>
        </div>
      </div>
    );
  }
  let link = '';
  if (eventId) {
    link = `/events/detail/${eventId}`;
  } else if (courseId) {
    link = `/courses/detail/${courseId}`;
  }
  if (link) {
    return (
      <Link to={link}>
        <SliderCardContent data={data} />
      </Link>
    );
  }
  return <SliderCardContent data={data} />;
};
