import { Route, Routes } from 'react-router-dom';

import { NotFoundPageWithUI } from '../NotFoundPageWithUI/loadable';
import { NotificationListPage } from './NotificationListPage/loadable';

export const NotificationRoutes = () => {
  return (
    <Routes>
      <Route index element={<NotificationListPage />}></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
