import 'assets/scss/Components/ScoreBoard.scss';
import { FontHelper } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { RankingData } from 'models/Performance';
import { useRef, useState } from 'react';
import { Navigation, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Tabbar from '../Common/Tabbar';
import { NoPerformanceRankData } from './NoPerformanceRankData';
import PerformanceBody from './PerformanceBody';

interface ScoreBoardProps {
  data?: RankingData[][];
  loading?: boolean;
}

export const ScoreBoard = ({ data, loading }: ScoreBoardProps) => {
  const [isActiveSlideNum, setIsActiveSlideNum] = useState<number>(1);
  const swiperRef = useRef<SwiperType>();
  const { size, isMobile } = useBreakpoint();

  const scoreBoardNav: any = [
    { titleId: 1, title: '本日之星' },
    { titleId: 2, title: '本週之星' },
    { titleId: 3, title: '本月之星' },
    { titleId: 4, title: '本年之星' },
  ];

  const TabbarOnClick = (titleId: number) => {
    setIsActiveSlideNum(titleId);
    swiperRef.current?.slideTo(titleId - 1);
  };

  return (
    <div className="score-board-container">
      <div
        className={`${FontHelper(
          size,
          18,
          24,
          24,
          'bold',
          true,
        )} text-color-white title-text score-board-title yellow-heading`}
      >
        SV 龍虎榜
      </div>
      <div className="score-board-card font-18px-normal">
        <div className="d-flex flex-row justify-content-center title-container">
          <Tabbar
            isActiveSlideNum={isActiveSlideNum}
            btnHeight={25}
            activeBarHeight={`${isMobile ? '-6px' : '-14px'}`}
            activeBarWidth={`${isMobile ? '12px' : '21px'}`}
            justifyCenter={true}
            barStyle={{
              height: '0px',
            }}
            btnOnClick={TabbarOnClick}
            data={scoreBoardNav}
          />
        </div>

        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          centeredSlides
          loop={false}
          className="score-board-slider"
          modules={[Navigation]}
          onBeforeInit={swiper => {
            swiperRef.current = swiper;
          }}
          onSlideChange={() =>
            swiperRef.current
              ? setIsActiveSlideNum(swiperRef.current.activeIndex + 1)
              : null
          }
        >
          <>
            <SwiperSlide>
              {loading ? (
                <PerformanceBody loading={loading} />
              ) : data && data[0] && data[0].length > 0 ? (
                <PerformanceBody data={data[0]} />
              ) : (
                <NoPerformanceRankData />
              )}
            </SwiperSlide>
            <SwiperSlide>
              {loading ? (
                <PerformanceBody loading={loading} />
              ) : data && data[1] && data[1].length > 0 ? (
                <PerformanceBody data={data[1]} />
              ) : (
                <NoPerformanceRankData />
              )}
            </SwiperSlide>
            <SwiperSlide>
              {loading ? (
                <PerformanceBody loading={loading} />
              ) : data && data[2] && data[2].length > 0 ? (
                <PerformanceBody data={data[2]} />
              ) : (
                <NoPerformanceRankData />
              )}
            </SwiperSlide>
            <SwiperSlide>
              {loading ? (
                <PerformanceBody loading={loading} />
              ) : data && data[3] && data[3].length > 0 ? (
                <PerformanceBody data={data[3]} />
              ) : (
                <NoPerformanceRankData />
              )}
            </SwiperSlide>
          </>
        </Swiper>
      </div>
    </div>
  );
};
