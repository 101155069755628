import 'assets/scss/Components/CommonScss/CheckBox.scss';

interface CheckBoxProps {
  className?: string;
  checked?: boolean;
  onChange?: (e) => void;
  id?: string;
}

export const CheckBox = ({
  className,
  checked,
  onChange,
  id,
}: CheckBoxProps) => {
  return (
    <div className={`custom-checkbox-container ${className}`}>
      <input
        type="checkbox"
        className="custom-checkbox-before"
        checked={checked}
        onChange={onChange}
        id={id}
      />
      <span className="custom-checkbox-after">
        <div className="checkmark_stem"></div>
        <div className="checkmark_kick"></div>
      </span>
    </div>
  );
};
