import 'assets/scss/Pages/BannerPage/PreviewModal.scss';
import { getImageURL } from 'helpers';
import React, { useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'react-modal';
import { Lazy, Navigation } from 'swiper';
import { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export interface Props {
  visible: boolean;
  previewIndex: number;
  images: string[];
  onClose: () => void;
}

export const PreviewModal = ({
  visible,
  onClose,
  images,
  previewIndex,
}: Props) => {
  Modal.setAppElement('#root');
  const swiperRef = useRef<SwiperType>();
  const [currentIndex, setCurrentIndex] = useState<number>(previewIndex + 1);

  const onNextSlide = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const onPrevSlide = () => {
    setCurrentIndex(currentIndex - 1);
  };

  return (
    <Modal
      isOpen={visible}
      className="fullscreen"
      closeTimeoutMS={100}
      overlayClassName="dialog-overlay"
    >
      <div className="image-preview-container">
        <div className="image-preview-tool-bar ">
          <div className="image-name text-white">{`${currentIndex}/${images.length}`}</div>
          <div className="d-flex flex-row">
            <button className="image-preview-tool-bar-btn" onClick={onClose}>
              <MdClose />
            </button>
          </div>
        </div>

        <div className="image-preview-wrapper">
          <div className="swiper-container">
            <Swiper
              initialSlide={previewIndex}
              spaceBetween={0}
              slidesPerView={1}
              navigation={true}
              lazy={true}
              modules={[Lazy, Navigation]}
              centeredSlides={true}
              onNavigationNext={onNextSlide}
              onNavigationPrev={onPrevSlide}
              autoplay={false}
              allowTouchMove={false}
              onBeforeInit={swiper => {
                swiperRef.current = swiper;
              }}
            >
              {images.map((image, slideIndex) => {
                return (
                  <SwiperSlide key={slideIndex}>
                    <img
                      src={getImageURL(image, 'original')}
                      alt={`banner-images`}
                      className=""
                      width={'60%'}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </Modal>
  );
};
