import { RadioButton } from 'app/components/Common/RadioButton';
import Coin from 'assets/icons/coin.svg';
import 'assets/scss/Components/EventApplicationPaymentOptionsCard.scss';
import { FieldHookConfig, useField } from 'formik';
import { toClientPrice } from 'helpers';
import { CoursePaymentOptions, EventPaymentOptions } from 'models';

interface EventApplicationPaymentOptionsCardProps {
  EventPaymentData?: EventPaymentOptions[];
  CoursePaymentData?: CoursePaymentOptions[];
  numberOfPeople: number;
}

export const EventApplicationPaymentOptionsCard = (
  props: EventApplicationPaymentOptionsCardProps &
    FieldHookConfig<{
      cash: number;
      point: number;
    }>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props);
  const { EventPaymentData, CoursePaymentData, numberOfPeople } = props;

  let PaymentData: EventPaymentOptions[] | CoursePaymentOptions[] = [];
  if (EventPaymentData) {
    PaymentData = EventPaymentData;
  } else if (CoursePaymentData) {
    PaymentData = CoursePaymentData;
  }

  const checkSelected = (option: EventPaymentOptions) => {
    if (
      field.value.cash === option.cash &&
      field.value.point === option.point
    ) {
      return true;
    }
    return false;
  };
  return (
    <div className="event-application-payment-options-card">
      <span className="payment-options-heading yellow-heading">總價</span>
      <div className="payment-options-subheading">請選擇付款組合</div>
      {PaymentData && PaymentData.length > 0
        ? PaymentData.map((option, index) => (
            <label className="payment-option" key={index}>
              <RadioButton
                checked={checkSelected(option)}
                className="payment-radio"
                name="paymentOption"
                onChange={e => {
                  if (e.target.checked) {
                    helpers.setValue({
                      cash: option.cash || 0,
                      point: option.point || 0,
                    });
                  }
                }}
              />
              <div key={index}>
                <span className="d-flex flex-row align-items-center">
                  {option.cash && option.cash > 0 ? (
                    <span className="me-1">
                      HKD ${toClientPrice(option.cash) * numberOfPeople}
                    </span>
                  ) : null}
                  {option.point ? (
                    <div className="d-flex flex-row align-items-center">
                      {option.cash && option.cash > 0 ? <span>+</span> : null}
                      <img
                        src={Coin}
                        alt="Coin"
                        className={`${
                          option.cash && option.cash > 0 ? 'ms-1' : undefined
                        }`}
                      />
                      <span className="ms-1">
                        {option.point * numberOfPeople}
                      </span>
                    </div>
                  ) : null}
                </span>
              </div>
            </label>
          ))
        : null}
    </div>
  );
};
