import { PreviewModal } from 'app/components/Home/Banner';
import 'assets/scss/Components/CommonScss/ImageSwiper.scss';
import { getImageURL } from 'helpers';
import { CSSProperties, useState } from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

interface ImageSwiperProps {
  images: string[];
  aspectRatio?: string;
  containerStyle?: CSSProperties;
}

export const ImageSwiper = ({
  images,
  aspectRatio,
  containerStyle,
}: ImageSwiperProps) => {
  const [isPreviewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewIndex, setPreviewIndex] = useState<number>(0);

  const imageOnPreview = (image, imgIndex) => {
    setPreviewIndex(imgIndex);
    setPreviewOpen(true);
  };

  return (
    <div className="image-swiper-container" style={containerStyle}>
      {images.length === 1 ? (
        <div className="image-container">
          <img
            src={getImageURL(images[0], 'original')}
            alt={`images`}
            className="swiper-images"
            style={{ aspectRatio: `${aspectRatio || '16 / 9'}` }}
            onClick={() => imageOnPreview(images[0], 0)}
          />
        </div>
      ) : null}

      {images.length > 1 ? (
        <Swiper
          pagination={{
            clickable: false,
          }}
          loop={true}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          speed={1000}
          direction={'horizontal'}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={getImageURL(image, 'original')}
                alt={`images`}
                className="swiper-images"
                style={{ aspectRatio: `${aspectRatio || '16 / 9'}` }}
                onClick={() => imageOnPreview(image, index)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}

      {isPreviewOpen && (
        <PreviewModal
          visible={isPreviewOpen}
          images={images || []}
          previewIndex={previewIndex}
          onClose={() => setPreviewOpen(false)}
        />
      )}
    </div>
  );
};
