import Coin from 'assets/icons/coin.svg';
import 'assets/scss/Components/CommonScss/EventPricingOptions.scss';
import { formatPrice, numberWithCommas, toClientPrice } from 'helpers';
import { PaymentOptions } from 'models';

interface EventPricingOptionsProps {
  PaymentOptions?: PaymentOptions[];
  containerName?: string;
}

export const EventPricingOptions = ({
  PaymentOptions,
  containerName,
}: EventPricingOptionsProps) => {
  if (PaymentOptions) {
    return (
      <span className="price-text d-flex">
        {PaymentOptions.length <= 0 ? (
          <div className="text-nowrap">免費</div>
        ) : PaymentOptions.some(
            option => option.cash === 0 && option.point === 0,
          ) ? (
          <div className="text-nowrap">免費</div>
        ) : (
          PaymentOptions.map((option, index) => (
            <span
              className="d-flex flex-row align-items-center payment-options-item"
              key={index}
            >
              {index > 0 ? <span className="ms-1">/</span> : null}
              {option.cash ? (
                <span className={`${index > 0 ? 'ms-1' : ''}`}>
                  HKD {formatPrice(toClientPrice(option.cash), true)}
                </span>
              ) : null}
              {option.point ? (
                <div className="d-flex flex-row align-items-center">
                  {option.cash ? <span>&nbsp;+&nbsp;</span> : null}
                  <img
                    src={Coin}
                    alt="Coin"
                    className={index === 0 ? undefined : `ms-1`}
                  />
                  <span className="ms-1">{numberWithCommas(option.point)}</span>
                </div>
              ) : null}
            </span>
          ))
        )}
      </span>
    );
  } else {
    return null;
  }
};
