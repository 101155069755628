import { CourseRequestBodyData } from 'app/pages/CoursePage/CourseDetailPage/CourseApplicationPage';
import {
  Course,
  CourseApplication,
  CourseApplicationData,
  CourseApplicationStatus,
  CourseApplicationStatusType,
  CourseFormAttendees,
  CoursePaymentOptions,
  CoursePaymentStatus,
  CourseSchedules,
  Filter,
  ListResult,
} from 'models';

import Axios from '.';
import { CanApplyResponse } from './EventServices';

/**
 * Get all Course list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses)
 */

export interface GetCourseListParams extends Filter<Course> {
  isBookmarked?: boolean;
  applicationStatus?: CourseApplicationStatusType;
  startDate?: string;
  endDate?: string;
  allowRegistration?: boolean;
  courseName?: string;
}

export const getUserCourseList = async (params: GetCourseListParams) => {
  const { data } = await Axios.get<ListResult<Course>>('/me/courses', {
    params,
  });
  return data;
};

export const getCourseList = async (params: GetCourseListParams) => {
  const { data } = await Axios.get<ListResult<Course>>('/courses', {
    params,
  });
  return data;
};

/**
 * Get Me Course Application list (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses)
 */

export interface GetMeCourseListApplicationParams
  extends Filter<CourseApplicationData> {
  status?: CourseApplicationStatus | CourseApplicationStatus[];
  courseId?: number | string;
  paymentStatus?: CoursePaymentStatus;
  startDate?: string;
  endDate?: string;
}

export const getMeCourseListApplication = async (
  params: GetMeCourseListApplicationParams,
) => {
  const { data } = await Axios.get<ListResult<CourseApplicationData>>(
    '/me/courses/applications',
    { params },
  );
  return data;
};

/**
 * Get one Course Application (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/applications/-courseApplicationId-)
 */

export const getOneCourseApplication = async (
  courseApplicationId: string | number,
) => {
  const { data } = await Axios.get<CourseApplication>(
    `/me/courses/applications/${courseApplicationId}`,
  );
  return data;
};

/**
 * Get One course by courseId (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/courses/-courseId-)
 */

export const getOneCourseByCourseId = async (courseId: string | number) => {
  const { data } = await Axios.get<Course>(`/courses/${courseId}`);
  return data;
};

/**
 * Add One Course Bookmark (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/me/courses/bookmarks/-courseId-)
 */

export const addCourseBookmark = async (courseId: string | number | null) => {
  const { data } = await Axios.post<any>(`/me/courses/bookmarks/${courseId}`);
  return data;
};

/**
 * Delete One Course Bookmark (https://svgroup-app-dev.appicidea.com/doc/v2#delete-/v1/me/courses/bookmarks/-courseId-)
 */

export const removeCourseBookmark = async (
  courseId: string | number | null,
) => {
  const { data } = await Axios.delete<any>(`/me/courses/bookmarks/${courseId}`);
  return data;
};

/**
 * Submit One Course Application By Course ID (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/me/courses/applications/-courseId-)
 */

export const applyForOneCourse = async (
  courseId: string | number,
  params: CourseRequestBodyData,
) => {
  const { data } = await Axios.post<CourseApplicationData>(
    `/me/courses/applications/${courseId}`,
    params,
  );
  return data;
};

/**
 * Get One Course Applications' Schedule, App User Only (https://svgroup-app-dev.appicidea.com/doc/v2#get-/v1/me/courses/applications/-courseApplicationId-/courseSchedule)
 */

interface getOneCourseApplicationSchedulesParams
  extends Filter<CourseSchedules> {
  courseApplicationId: string | number;
}

export const getOneCourseApplicationSchedules = async (
  params: getOneCourseApplicationSchedulesParams,
) => {
  const { data } = await Axios.get<ListResult<CourseSchedules>>(
    `me/courses/applications/${params.courseApplicationId}/courseSchedule`,
    { params },
  );
  return data;
};

export interface ValidateCourseApplicationParams {
  paymentOption?: CoursePaymentOptions;
  willMemberAttend: boolean;
  attendees: CourseFormAttendees[];
}

export interface ValidCourseApplicationResponse {
  courseVacancy: number;
  isExceedQuota: boolean;
  totalMembershipPoint: number;
  totalPaymentAmount: number;
  hasError: boolean;
  isExceedMemberPoint: boolean;
  memberHasApply: boolean;
}

export const validateCourseApplication = async (
  courseId: number,
  params: ValidateCourseApplicationParams,
) => {
  const { data } = await Axios.post<ValidCourseApplicationResponse>(
    `/me/courses/applications/${courseId}/validate`,
    params,
  );
  return data;
};

/**
 * Verify For One Course , Optional Login (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/courses/-courseId-/verifyPermission)
 */

export const verifyPermissionForCourse = async (courseId: number) => {
  const { data } = await Axios.post<CanApplyResponse>(
    `/courses/${courseId}/verifyPermission`,
  );
  return data;
};

/**
 * Cancel One Course Application, AppUser Only (https://svgroup-app-dev.appicidea.com/doc/v2#post-/v1/me/courses/applications/-courseApplicationId-/cancel)
 */

export const cancelOneCourseApplication = async (
  courseApplicationId: number,
) => {
  const { data } = await Axios.post<any>(
    `/me/courses/applications/${courseApplicationId}/cancel`,
  );
  return data;
};
