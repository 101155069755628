import CalendarIcon from 'assets/icons/calendar.svg';
import CalendarActiveIcon from 'assets/icons/calendar_active.svg';
import CourseIcon from 'assets/icons/course.svg';
import CourseActiveIcon from 'assets/icons/course_active.svg';
import DriveIcon from 'assets/icons/drive.svg';
import DriveActiveIcon from 'assets/icons/drive_active.svg';
import EventIcon from 'assets/icons/event.svg';
import EventActiveIcon from 'assets/icons/event_active.svg';
import HistoryIcon from 'assets/icons/history.svg';
import HistoryActiveIcon from 'assets/icons/history_active.svg';
import HomeIcon from 'assets/icons/home.svg';
import HomeActiveIcon from 'assets/icons/home_active.svg';
import ProfileIcon from 'assets/icons/profile.svg';
import ProfileActiveIcon from 'assets/icons/profile_active.svg';

export const routesMap = [
  {
    path: '/home',
    regex: /^\/home\/?$/,
    title: '主頁',
    icon: HomeIcon,
    activeIcon: HomeActiveIcon,
    subRoutes: [
      {
        path: '/ranking',
        regex: /^\/ranking\/?$/,
        title: '',
      },
      {
        path: '/day-top10',
        regex: /^\/day-top10\/?$/,
        title: '本日之星',
      },
      {
        path: '/week-top10',
        regex: /^\/week-top10\/?$/,
        title: '本週之星',
      },
      {
        path: '/month-top10',
        regex: /^\/month-top10\/?$/,
        title: '本月之星',
      },
      {
        path: '/year-top10',
        regex: /^\/year-top10\/?$/,
        title: '本年之星',
      },
      {
        path: '/banner/:d',
        regex: /^\/banner\/(\d+)\/?$/,
        title: '本年之星',
      },
    ],
  },
  {
    path: '/events',
    regex: /^\/events\/?$/,
    title: '活動預約',
    icon: EventIcon,
    activeIcon: EventActiveIcon,
    subRoutes: [],
  },
  {
    path: '/courses',
    regex: /^\/courses\/?$/,
    title: '課程預約',
    icon: CourseIcon,
    activeIcon: CourseActiveIcon,
    subRoutes: [],
  },
  {
    path: '/drive',
    regex: /^\/drive\/?$/,
    title: '資料庫',
    icon: DriveIcon,
    activeIcon: DriveActiveIcon,
    subRoutes: [],
  },
  {
    path: '/history',
    regex: /^\/history\/?$/,
    title: '過往紀錄',
    icon: HistoryIcon,
    activeIcon: HistoryActiveIcon,
    subRoutes: [],
  },
  {
    path: '/calendar',
    regex: /^\/calendar\/?$/,
    title: '日程表',
    icon: CalendarIcon,
    activeIcon: CalendarActiveIcon,
    subRoutes: [],
  },
  {
    path: '/profile',
    regex: /^\/profile\/?$/,
    title: '個人中心',
    icon: ProfileIcon,
    activeIcon: ProfileActiveIcon,
    subRoutes: [],
  },
];
