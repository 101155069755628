import Calendar from 'assets/icons/calendar_purple.svg';
import Coin from 'assets/icons/coin.svg';
import Flag from 'assets/icons/flag.svg';
import MoneyBag from 'assets/icons/money_bag.svg';
import TickIcon from 'assets/icons/tick.svg';
import Warning from 'assets/icons/warning.svg';
import YellowTick from 'assets/icons/yellow_tick.svg';
import 'assets/scss/Components/EventInfoCard.scss';
import {
  FontHelper,
  formatPrice,
  getImageURL,
  numberWithCommas,
  toClientPrice,
} from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { SliderCardData } from 'models';
import { useState } from 'react';
import { Placeholder } from 'react-bootstrap';

import { BookmarkButton } from '../Common/BookmarkButton';

export const EventInfoCard = ({
  data,
  isFromBookmarkPage,
  selected,
  isLoading,
}: {
  data?: SliderCardData;
  isFromBookmarkPage: boolean;
  selected?: boolean;
  isLoading?: boolean;
}) => {
  const [bookMarked, setBookmarked] = useState<boolean>(
    data?.isBookmarked || false,
  );
  const { size, isMobile } = useBreakpoint();

  return (
    <div
      className={`event-info-card noselect position-relative d-flex ${
        selected ? 'selected' : ''
      }`}
      draggable="false"
    >
      {data ? (
        isFromBookmarkPage ? (
          isMobile ? (
            selected ? (
              <>
                <img
                  src={YellowTick}
                  alt={'✓'}
                  className="selected-yellow-tick"
                ></img>
                <BookmarkButton
                  smallerBookmark={true}
                  isBookmarked={bookMarked}
                  eventId={data?.eventId}
                  courseId={data?.courseId}
                  setBookmark={setBookmarked}
                />
              </>
            ) : (
              <BookmarkButton
                smallerBookmark={true}
                isBookmarked={bookMarked}
                eventId={data?.eventId}
                courseId={data?.courseId}
                setBookmark={setBookmarked}
              />
            )
          ) : selected ? (
            <img
              src={YellowTick}
              alt={'✓'}
              className="selected-yellow-tick"
            ></img>
          ) : null
        ) : (
          <BookmarkButton
            smallerBookmark={true}
            isBookmarked={bookMarked}
            eventId={data?.eventId}
            courseId={data?.courseId}
            setBookmark={setBookmarked}
          />
        )
      ) : null}
      <div
        className="event-image-container"
        style={
          !isFromBookmarkPage && isMobile ? { display: 'none' } : undefined
        }
      >
        {data && data?.images && data?.images.length ? (
          <img
            src={getImageURL(data?.images[0])}
            draggable="false"
            alt="Banner"
            className="event-image"
          />
        ) : isLoading ? (
          <Placeholder
            animation="glow"
            className="event-info-card-image-placeholder"
          >
            <Placeholder />
          </Placeholder>
        ) : null}
      </div>
      <div className="eventinfo d-flex flex-column align-items-start p-3">
        <div className="title-text font-16px-bold text-color-primary text-left one-line-ellipsis">
          {data ? (
            data.title
          ) : isLoading ? (
            <Placeholder
              animation="glow"
              className="event-info-card-title-placeholder"
            >
              <Placeholder />
            </Placeholder>
          ) : null}
        </div>
        {data ? (
          data.eventId ? (
            <div className="d-flex flex-row eventinfo-item">
              <img src={TickIcon} alt="tick icon" draggable="false" />
              <div
                className={`${FontHelper(
                  size,
                  12,
                  14,
                  14,
                  'medium',
                )} ms-1 member-only text-nowrap`}
              >
                {data.memberOnly ? '會員專用' : '公眾參與'}
              </div>
            </div>
          ) : data.courseCode ? (
            <div
              className={`${FontHelper(
                size,
                12,
                14,
                14,
                'medium',
              )} eventinfo-item courseID text-nowrap`}
            >
              {'課程編號：' + data.courseCode}
            </div>
          ) : null
        ) : isLoading ? (
          <Placeholder
            animation="glow"
            className="event-info-card-text-placeholder"
          >
            <Placeholder />
          </Placeholder>
        ) : null}
        <div className="d-flex flex-row eventinfo-item">
          {data ? (
            <>
              <img src={Calendar} alt="" draggable="false" />
              <div className="font-16px-medium ms-2">{data.displayDate}</div>
            </>
          ) : isLoading ? (
            <Placeholder
              animation="glow"
              className="event-info-card-text-placeholder"
            >
              <Placeholder />
            </Placeholder>
          ) : null}
        </div>
        <div className="d-flex flex-row align-items-start eventinfo-item">
          {data ? (
            <>
              <img src={MoneyBag} alt="" draggable="false" className="me-2" />
              <div className="d-flex flex-column align-items-start eventinfo-flexbox">
                {data.paymentOptions.length > 0 ||
                data.paymentOptions.some(
                  option => option.cash === 0 && option.point === 0,
                ) ? (
                  data.paymentOptions.map((option, index) => (
                    <div key={index}>
                      {option.cash && option.point ? (
                        <span
                          className="d-flex align-items-center payment-item"
                          key={index}
                        >
                          {`HKD ${formatPrice(
                            toClientPrice(option.cash),
                            true,
                          )}`}
                          &nbsp;+&nbsp;
                          <img src={Coin} alt="Coin" />
                          &nbsp;{numberWithCommas(option.point)}
                        </span>
                      ) : option.cash && !option.point ? (
                        <span
                          className="d-flex align-items-center payment-item"
                          key={index}
                        >{`HKD ${formatPrice(
                          toClientPrice(option.cash),
                          true,
                        )}`}</span>
                      ) : !option.cash && option.point ? (
                        <span
                          className="d-flex align-items-center payment-item"
                          key={index}
                        >
                          <img src={Coin} alt="Coin" />
                          &nbsp;{numberWithCommas(option.point)}
                        </span>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="text-nowrap">免費</div>
                )}
              </div>
            </>
          ) : isLoading ? (
            <Placeholder
              animation="glow"
              className="event-info-card-text-placeholder"
            >
              <Placeholder />
            </Placeholder>
          ) : null}
        </div>

        <div className="d-flex flex-row eventinfo-item eventinfo-last-item">
          {data ? (
            data.isEndSoon && (
              <div className="warning d-flex flex-row">
                <img src={Warning} alt="" draggable="false" />
                <div
                  className={`warning-text ${FontHelper(
                    size,
                    12,
                    14,
                    14,
                    'bold',
                  )} text-danger text-nowrap`}
                >
                  即將截止
                </div>
              </div>
            )
          ) : isLoading ? (
            <Placeholder
              animation="glow"
              className="event-info-card-text-placeholder"
            >
              <Placeholder />
            </Placeholder>
          ) : null}
          {data ? (
            data.eventId && data.vacancy <= 0 ? (
              <div className="d-flex flex-row">
                <img src={Flag} alt="" draggable="false" />
                <div
                  className={`flag-text ${FontHelper(
                    size,
                    12,
                    14,
                    14,
                    'bold',
                  )} text-danger text-nowrap`}
                >
                  名額已滿
                </div>
              </div>
            ) : data.vacancy > 0 && data.isFewQuotaLeft ? (
              <div className="d-flex flex-row">
                <img src={Flag} alt="" draggable="false" />
                <div
                  className={`flag-text ${FontHelper(
                    size,
                    12,
                    14,
                    14,
                    'bold',
                  )} text-danger text-nowrap`}
                >
                  剩餘少量名額
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    </div>
  );
};
