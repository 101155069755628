import { default as QrCode } from 'assets/icons/history_qr_code.svg';
import Email from 'assets/icons/mail_icon.svg';
import membershipPoint from 'assets/icons/membership_point.svg';
import Pen from 'assets/icons/pen.svg';
// import { default as zoomIcon } from 'assets/icons/zoom_icon.svg';
import 'assets/scss/Components/CommonScss/InfoCard.scss';
import { CheckIsSameDate, FontHelper, getImageURL } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { FormattedInfoCardType } from 'models';

interface Props {
  data?: FormattedInfoCardType;
  openModal?: () => void;
  setActiveCard?: () => void;
  editMode?: boolean;
  targetId?: number;
  editPreagent?: () => void;
  setCurrentPreagent?: () => void;
  currentUse?: 'ScorePage' | 'MyEventListPage' | 'HistoryListPage';
}

const InfoCard = ({
  data,
  openModal,
  setActiveCard,
  editMode,
  targetId,
  editPreagent,
  setCurrentPreagent,
  currentUse,
}: Props) => {
  const { size } = useBreakpoint();
  return (
    <div className="info-card card">
      {data?.eventApplicationId && currentUse !== 'ScorePage' ? (
        <div className="info-card-image-container">
          <img
            className="info-card-image"
            src={
              data.Event?.images && data.Event?.images.length
                ? getImageURL(data.Event.images[0], 'original')
                : ''
            }
            alt="Event Banner"
          />
        </div>
      ) : null}
      <div
        className={`info-card-container ${
          currentUse === 'ScorePage' ? 'info-card-container-score' : undefined
        }`}
      >
        <div className="left-side-content">
          <div
            className={`title-div ${
              currentUse === 'ScorePage'
                ? 'title-div-no-margin'
                : targetId === 3
                ? 'preagent'
                : null
            }`}
          >
            {data?.preAgentId ? (
              <div className="font-18px-bold title-textitlet">
                <span>
                  {data?.lastname} {data?.firstname}
                </span>
                {!editMode && (
                  <img
                    alt="edit"
                    className="pen-edit-icon-btn"
                    src={Pen}
                    onClick={() => {
                      editPreagent?.();
                      setCurrentPreagent?.();
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <div
                  className={`yellow-vector ${
                    currentUse === 'ScorePage'
                      ? !data?.eventApplicationId && !data?.courseApplicationId
                        ? 'yellow-vector-none'
                        : data.courseApplicationId
                        ? 'yellow-vector-red'
                        : undefined
                      : undefined
                  }`}
                ></div>
                <div
                  className={`title one-line-ellipsis ${FontHelper(
                    size,
                    14,
                    16,
                    16,
                    'bold',
                  )} ${
                    currentUse === 'ScorePage' &&
                    !data?.courseApplicationId &&
                    !data?.eventApplicationId
                      ? 'title-no-padding'
                      : undefined
                  }`}
                >
                  {data?.title}
                </div>
              </div>
            )}
          </div>
          {currentUse === 'ScorePage' ? (
            data?.remarks ? (
              <div
                className={`score-remark ${FontHelper(size, 12, 14, 14, 500)}`}
              >
                {data?.remarks}
              </div>
            ) : null
          ) : data?.preAgentId ? (
            <div className="preagent-content-div">
              <div className="telephone-icon ">
                <img src={Email} alt="zoom-link" className="drag-none" />
              </div>
              <a
                href={`mailto: ${data?.email}`}
                className="email-div font-16px-medium text-color-link one-line-ellipsis"
              >
                {data?.email}
              </a>
            </div>
          ) : (
            <>
              <div className="date-div title d-flex">
                <div className="font-14px-semibold text-nowrap">日期：</div>
                <div className="font-14px-medium">
                  {data?.startDate && data?.endDate ? (
                    CheckIsSameDate(data.startDate, data.endDate) ? (
                      <div className="d-flex">
                        <div className="">{data?.startDate}</div>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <div className="">{data?.startDate}</div>
                        <div className="font-14px-semibold">{' 至 '}</div>
                        <div className="">{data?.endDate}</div>
                      </div>
                    )
                  ) : data?.startDate ? (
                    data?.startDate
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              {data?.eventApplicationId ? (
                <div className="time-div d-flex">
                  <div className="font-14px-semibold text-nowrap">時間：</div>
                  <div className="font-14px-medium">
                    {data?.eventApplicationId
                      ? data?.startTime! + ' - ' + data.endTime
                      : '請細閱活動資料'}
                  </div>
                </div>
              ) : null}
              {data?.waitingListOrder ? (
                <div className="time-div d-flex">
                  <div className="font-14px-semibold">現時輪候次序：</div>
                  <div className="font-14px-medium">
                    {data?.waitingListOrder}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
        <div
          className={`${
            currentUse === 'ScorePage'
              ? 'right-side-content-score'
              : 'right-side-content'
          }`}
        >
          {data?.code && !editMode && (
            <div
              className="text-center image-div qrcode-preview-btn"
              style={{ top: data?.preAgentId ? '34px' : '48px' }}
              onClick={e => {
                openModal?.();
                setActiveCard?.();
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <img src={QrCode} alt="qr-code" className="drag-none" />
              <div className="font-14px-semibold text-color-purple">預覽</div>
            </div>
          )}
          {data?.attendees?.[0].code && !editMode && (
            <div
              className="text-center image-div qrcode-preview-btn"
              style={{ top: data?.preAgentId ? '34px' : '48px' }}
              onClick={e => {
                openModal?.();
                setActiveCard?.();
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <img src={QrCode} alt="qr-code" className="drag-none" />
              <div className="font-14px-semibold text-color-purple">預覽</div>
            </div>
          )}
          {data?.membershipPointEarned && (
            <div className="membership-point-div">
              <div
                className={`math-symbol-div ${FontHelper(
                  size,
                  18,
                  20,
                  20,
                  'bold',
                )} text-color-purple`}
              >
                {Math.sign(data?.membershipPointEarned) === -1
                  ? '-'
                  : Math.sign(data?.membershipPointEarned) === 1
                  ? '+'
                  : ''}
              </div>
              <img src={membershipPoint} alt="qr-code" className="drag-none" />
              <div
                className={`font-14px-semibold point ${FontHelper(
                  size,
                  16,
                  18,
                  18,
                  'bold',
                )}`}
              >
                {Math.abs(data?.membershipPointEarned)}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
