export interface UserLoginPayload {
  token: string;
  user: User;
}

export type CodeColor = 'black' | 'yellow' | 'red' | 'suspended';

export interface UserLoginError {
  name: string;
  message: string;
  status: number;
  errors: [];
}

export interface UserAuth {
  userAuthId: number;
  userId: number;
  email?: string;
}

export interface UserTag {
  userTagId: number;
  userId: number | null;
  tagName: string;
}
export interface User {
  userId: number;
  uid: string;
  email?: string;
  displayName: string;
  chineseName?: string;
  isReceivedInformation: boolean;
  firstName?: string;
  lastName?: string;
  englishName?: string;
  avatar?: string;
  notificationToken?: string;
  onceId?: string;
  qrcode?: string | null;
  status: '已停用' | '已啟用';
  membershipPoint: number;
  agentLicenseNumber?: string;
  userType: '公眾' | '會員';
  contactPhone?: string;
  marriageStatus?: '已婚' | '未婚';
  memberLevel?:
    | '紅寶石'
    | '黃鑽'
    | '鑽石'
    | '綠寶石'
    | '藍寶石'
    | '純銀'
    | '普通會員';
  jobTitle?: string;
  quote: string;
  rankCode?: string | null;
  rankCodeNumber?: string | null;
  isStaff: boolean;
  branchCode?: string | null;
  branchName?: string | null;
  entryDate?: string | null;
  birthDate?: string | null;
  UserAuths: UserAuth[];
  UserTags: UserTag[];
  createdAt: string;
  updatedAt: string;
  cardAddress?: string | null;
}

export interface QRCodeColor {
  color: CodeColor;
  noOfDaysExpired: number;
  penaltyPercentage: number;
}

export interface PreAgent {
  preAgentId: number;
  userId: string;
  firstName: string;
  hkId: string;
  lastName: string;
  code: string;
  email: string;
  studentNumber: string;
  createdAt: string;
  updatedAt: string;
}

export interface CalendarItem {
  id?: number;
  name: string;
  date: string;
  endDate: string | null;
  startTime: string;
  endTime: string;
  zoomRoomNumber?: string;
  zoomRoomPassword?: string;
  isCompanyActivity: boolean;
  eventId?: number;
  eventApplicationId?: number;
  courseId?: number;
  courseApplicationId?: number;
  companyActivityId?: number;
}

export enum MembershipLevelMap {
  紅寶石 = 'ruby',
  黃鑽 = 'yellow-diamond',
  鑽石 = 'diamond',
  綠寶石 = 'emerald',
  藍寶石 = 'sapphire',
  純銀 = 'silver',
  普通會員 = 'common',
}
