import Loading from './Loading';

export const ScrollLoadingSpinner = () => {
  return (
    <div
      className="scroll-loading w-100 d-flex flex-row align-items-center justify-content-center"
      style={{ gridColumn: '1/3', maxHeight: '50px', overflow: 'hidden' }}
    >
      <Loading />
    </div>
  );
};
