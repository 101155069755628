import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SENTRY_ENV, SENTRY_REL } from 'config';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import 'react-quill/dist/quill.snow.css';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import 'theme/Bootstrap.scss';
import 'theme/Font.scss';
import 'theme/custom.scss';
import 'theme/customComponents.scss';
import 'theme/index.scss';

import { App } from './app';

Sentry.init({
  dsn: 'https://fa976844f5614c788e3654bc82d12b5a@sentry.appicidea.com/7',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: SENTRY_ENV,
  release: SENTRY_REL,
  enabled: process.env.NODE_ENV !== 'development',
  beforeSend: event => {
    if (window?.location?.hostname === 'localhost') {
      return null;
    }
    return event;
  },
  ignoreErrors: [
    'Request failed with status code 403',
    'ResizeObserver loop limit exceeded',
    'AbortError: The user aborted a request',
    'NetworkError when attempting to fetch resource',
    'There was a network error',
    'Connection is canceled',
    'Network Error',
    'ResizeObserver loop completed with undelivered notifications',
  ],
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
);
