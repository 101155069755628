import 'assets/scss/Components/SectionHeader.scss';

type SectionHeaderPadding = '16px' | '24px';

interface SectionHeaderProps {
  title: string;
  icon?: React.ReactNode;
  padding: SectionHeaderPadding;
}

export const SectionHeader = ({ title, icon, padding }: SectionHeaderProps) => {
  return (
    <div
      className="section-header"
      style={
        padding === '16px'
          ? { padding: '5px 16px 4px 16px' }
          : { padding: '5px 24px 4px 24px' }
      }
    >
      {icon ? <div className="section-header-icon">{icon}</div> : null}
      <div className="section-header-title">{title}</div>
    </div>
  );
};
