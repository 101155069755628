import SVlogo from 'assets/icons/super_vibes.svg';
import 'assets/scss/Components/QRcodeSliderCard.scss';
import { CodeColor } from 'models';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { DisabledUser } from '../Common/DisabledUser';

export interface ORcodeCardData {
  name: string | null;
  code: string | null;
}

export interface QRcodeSliderCardData {
  data: ORcodeCardData;
  isActive: boolean;
  currentUse?: string;
  checkin?: string | null;
  checkout?: string | null;
  color?: CodeColor;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
}

export const QRcodeSliderCard = ({
  data,
  isActive,
  currentUse = 'attendee',
  checkin,
  checkout,
  color,
  setIsOpen,
}: QRcodeSliderCardData) => {
  const { Auth } = useAppSelector((rootState: RootState) => rootState.auth);
  return (
    <div className="QR-code-card-container">
      {data.code ? (
        <div
          className={`${currentUse}-QRcode-card QRcode-card d-flex flex-column align-items-center`}
        >
          <div
            className={`QR-code-card-content  d-flex flex-column align-items-center ${
              currentUse === 'user' && color && color === 'suspended'
                ? 'disabled-QRcode-card-content'
                : undefined
            }`}
          >
            <div
              className={`${currentUse}-name QRcode-card-name one-line-ellipsis font-16px-semibold align-self-${
                currentUse === 'user' ? 'center' : 'start'
              }`}
            >
              {data && currentUse === 'attendee' ? (
                data.name
              ) : data &&
                currentUse === 'user' &&
                Auth &&
                Auth?.user?.userType === '會員' ? (
                <div className="user-QR-code d-flex flex-column align-items-center">
                  <img src={SVlogo} alt="Super Vibes" />
                  <div className="user-QR-code-text">會員碼</div>
                </div>
              ) : null}
            </div>
            <div className="QRcode-container user-select-none">
              {data &&
              data.code &&
              (currentUse === 'attendee' ||
                currentUse === 'preagent' ||
                (currentUse === 'user' &&
                  Auth &&
                  Auth?.user?.userType === '會員')) ? (
                <QRCode
                  value={data.code}
                  fgColor={`${
                    color
                      ? color === 'red'
                        ? '#FF0000'
                        : color === 'yellow'
                        ? '#FFDB5C'
                        : '#009745'
                      : null
                  }`}
                />
              ) : null}
            </div>
            {currentUse === 'user' &&
            Auth &&
            Auth?.user?.userType === '會員' &&
            color &&
            color !== 'black' ? (
              <>
                <div className="QRcode-card-expired">
                  會籍已到期，請盡快繳費
                </div>
                <Link
                  onClick={e => {
                    e.stopPropagation();
                    setIsOpen?.(false);
                  }}
                  to={`/profile/fee/payment`}
                  rel="noreferrer"
                >
                  <Button>繳費</Button>
                </Link>
              </>
            ) : null}
            <div className="redeem">
              {/* isActive determines to show redeem code or not.. */}
              {isActive ? (
                <div className="d-flex flex-column align-items-center">
                  <div className="redeem-text font-16px-semibold">兌換碼</div>
                  <div className="redeem-code font-16px-medium">
                    {data ? data.code : null}
                  </div>
                </div>
              ) : null}
              {currentUse !== 'attendee' ? null : (
                <div className="check-in-out-container d-flex flex-column text-left mt-1">
                  <div className="checkin-time">
                    簽到:{checkin ? moment(checkin).format(' HH:mm') : ' —'}
                  </div>
                  <div className="checkout-time">
                    簽走:{checkout ? moment(checkout).format(' HH:mm') : ' —'}
                  </div>
                </div>
              )}
            </div>
          </div>
          {currentUse === 'user' &&
          Auth &&
          Auth?.user?.userType === '會員' &&
          !color ? (
            <DisabledUser setIsOpen={setIsOpen} isColorError={true} />
          ) : currentUse === 'user' &&
            Auth &&
            Auth?.user?.userType === '會員' &&
            color === 'suspended' ? (
            <DisabledUser setIsOpen={setIsOpen} />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
