import { Route, Routes } from 'react-router-dom';

import { NotFoundPageWithUI } from '../NotFoundPageWithUI/loadable';
import { ProfileHistoryPage } from '../ProfilePage/ProfileHistoryPage/loadable';

export const HistoryRoutes = () => {
  return (
    <Routes>
      <Route index element={<ProfileHistoryPage />}></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
