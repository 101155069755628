import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCheckoutForm from 'app/components/Payment';
import 'assets/scss/Components/EventApplicationPaymentMethodCard.scss';
import { StripePaymentResponse } from 'models';
import { useEffect } from 'react';
import { updateMembershipPoint, updateUserProfile } from 'store/Auth';
import { useAppDispatch } from 'store/hooks';

interface PaymentProps {
  paymentInfo: StripePaymentResponse;
  url?: string;
}

export const EventApplicationPaymentMethodCard = ({
  paymentInfo,
  url,
}: PaymentProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(updateUserProfile());
      dispatch(updateMembershipPoint());
    };
  }, [dispatch]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const stripePromise = loadStripe(paymentInfo.publishableKey);
  const options: any = {
    // passing the client secret obtained in step 3
    clientSecret: paymentInfo.paymentIntent,
    // Fully customizable with appearance API.
    appearance: {
      theme: 'stripe',
    },
    customerOptions: {
      customer: paymentInfo.customer,
      ephemeralKey: paymentInfo.ephemeralKey,
    },
  };

  return (
    <>
      <div className="event-application-payment-method-card">
        <span className="payment-method-heading yellow-heading">付款方式</span>
        <Elements stripe={stripePromise} options={options as any}>
          <StripeCheckoutForm redirectURL={url} />
        </Elements>
      </div>
    </>
  );
};
