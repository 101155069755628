import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DriveFile, FileType } from 'models/Drive';

interface DriveState {
  isSearchLoading: boolean;
  currentFolder: DriveFile[];
  selectedFolderType: FileType | null;
  selectedPath: string;
  isError: boolean;
  isAllUploaded: boolean;
  isDataSearched: boolean;
  searchPublicDataResult: DriveFile[];
  searchPrivateDataResult: DriveFile[];
}

const initialState = {
  isSearchLoading: false,
  currentFolder: [],
  selectedFolderType: null,
  selectedPath: '',
  isError: false,
  isAllUploaded: true,
  isDataSearched: false,
  searchPublicDataResult: [],
  searchPrivateDataResult: [],
} as DriveState;

const driveSlice = createSlice({
  name: 'drive',
  initialState,
  reducers: {
    getDriveDataLoadingStart(state) {
      state.isSearchLoading = true;
    },
    getDriveDataSuccess(state) {
      state.isSearchLoading = false;
      state.isError = false;
    },
    getDriveDataFail(state) {
      state.isSearchLoading = false;
      state.isError = true;
    },

    getSearchDataSuccess(state) {
      state.isDataSearched = true;
    },
    getSearchDataFail(state) {
      state.isDataSearched = false;
    },
    setIsDataSearched(state, action: PayloadAction<boolean>) {
      state.isDataSearched = action.payload;
    },
    setSearchPublicDataResult(state, action: PayloadAction<DriveFile[]>) {
      state.searchPublicDataResult = action.payload;
    },
    setSearchPrivateDataResult(state, action: PayloadAction<DriveFile[]>) {
      state.searchPrivateDataResult = action.payload;
    },

    reset() {
      return initialState;
    },
  },
});

export const {
  getDriveDataLoadingStart,
  getDriveDataSuccess,
  getDriveDataFail,

  getSearchDataSuccess,
  getSearchDataFail,
  setIsDataSearched,
  setSearchPublicDataResult,
  setSearchPrivateDataResult,
} = driveSlice.actions;
export default driveSlice.reducer;
