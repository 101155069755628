import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import * as stripeJs from '@stripe/stripe-js';
import 'assets/scss/Components/Payment/StripeCheckoutForm.scss';
import { APP_URL } from 'config';
import { ReactNode, useState } from 'react';
import { toast } from 'react-toastify';

import { Button } from '../Common';

const StripeCheckoutForm = ({
  customPayNowComponent,
  redirectURL,
}: {
  customPayNowComponent?: ReactNode;
  redirectURL?: string;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleSubmit = async (event: any) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${redirectURL ? redirectURL : `${APP_URL}/home`}`,
      },
    });

    if (error) {
      toast.error(
        error.message || 'Encounter unknown payment error, please try again.',
      );
      setErrorMessage(
        error.message || 'Encounter unknown payment error, please try again.',
      );
    }
    setLoading(false);
  };

  const paymentElementOptions: stripeJs.StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
    wallets: {
      applePay: 'auto',
      googlePay: 'auto',
    },
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-checkout-form">
      <PaymentElement options={paymentElementOptions} className="stripe-form" />
      {customPayNowComponent ? (
        customPayNowComponent
      ) : (
        <Button
          id="pay-now-button"
          className={`book-now-button mt-4 `}
          type="submit"
          loading={isLoading}
          containerStyle={{
            padding: '9px 0',
            height: '24.5px',
            width: '100%',
          }}
        >
          付款
        </Button>
      )}
      {errorMessage && <div className="err-text">{errorMessage}</div>}
    </form>
  );
};

export default StripeCheckoutForm;
