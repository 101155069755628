import 'assets/scss/Components/Form.scss';
import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

interface SurveySelectFieldProps {
  label: string;
  options: string[];
  firstOptionRemarks: string;
  lastOptionRemarks: string;
  requiredSymbol?: boolean;
}

export const SurveySelectField = (
  props: SurveySelectFieldProps & FieldHookConfig<string>,
) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    options,
    firstOptionRemarks,
    lastOptionRemarks,
    requiredSymbol,
  } = props;
  return (
    <div
      className={clsx(
        'form-group w-100',
        meta.touched && meta.error && 'text-danger',
      )}
    >
      <div className="d-flex flex-column w-100">
        {label ? (
          <div className="position-relative" style={{ width: 'fit-content' }}>
            <label className="font-16px-semibold">{label}</label>
            {requiredSymbol ? (
              <div className="required-symbol-div text-color-warning">*</div>
            ) : null}
          </div>
        ) : null}
        <div className="d-flex flex-row align-items-center justify-content-between w-100 mb-3">
          {options.map((option, index) => (
            <div
              key={index}
              className="cursor-pointer"
              onClick={() => helpers.setValue(option)}
            >
              {option === field.value ? (
                <MdRadioButtonChecked color="#6b63c4" size={20} />
              ) : (
                <MdRadioButtonUnchecked size={20} />
              )}
            </div>
          ))}
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="font-12px-normal text-color-purple">
            {firstOptionRemarks || ''}
          </div>
          <div className="font-12px-normal text-color-purple">
            {lastOptionRemarks || ''}
          </div>
        </div>
      </div>
      {meta.touched && meta.error ? (
        <div className="font-12px-medium mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};
