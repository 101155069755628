import FolderIcon from 'assets/icons/drive_folder_icon.svg';
import 'assets/scss/Components/Drive/FileViewer.scss';
import { downloadFile, getFilePreviewLink } from 'helpers/DriveHelper';
import useBreakpoint from 'hooks/breakpoints';
import { DriveFile } from 'models/Drive';
import { useCallback, useState } from 'react';
import { MdClose, MdOutlineFileDownload } from 'react-icons/md';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import Modal from 'react-modal';
import { Lazy, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

import { PDFViewer } from './PDFViewer';

Modal.setAppElement('#root');

export interface FileViewerProps {
  visible: boolean;
  files: DriveFile[];
  onClose: () => void;
  previewIndex: number;
  url?: string;
  token?: string;
}

interface PreviewItem extends DriveFile {
  preview: string;
}

export const FileViewer = ({
  visible,
  files,
  onClose,
  previewIndex,
  url,
  token,
}: FileViewerProps) => {
  const [index, setIndex] = useState<number>(previewIndex);
  const { size, isMobile } = useBreakpoint();

  const renderSlide = useCallback(
    (file: PreviewItem, slideIndex) => {
      if (index === slideIndex) {
        switch (file.fileType) {
          case 'image':
            if (size === 'desktop') {
              return (
                <div className="file-image">
                  <InnerImageZoom
                    zoomScale={2}
                    src={file.preview}
                    moveType={'pan'}
                  />
                </div>
              );
            } else
              return (
                <div className="file-image-non-desktop">
                  <img src={file.preview} alt="" />
                </div>
              );

          case 'video':
            return (
              <div className="file-video">
                <video
                  width="auto"
                  height="60%"
                  controls
                  id={`video-${slideIndex}`}
                >
                  <source src={file.preview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            );
          case 'pdf':
            return (
              <div className="file-pdf">
                <PDFViewer file={file} url={url} token={token} />
              </div>
            );
          default:
            return (
              <div className="file-default">
                <img src={FolderIcon} alt="empty folder" />
                <div className="text-white">未能預覽</div>
              </div>
            );
        }
      }
    },
    [index, size, token, url],
  );

  const previewItems = files
    .filter(file => file.type === 'File')
    .map(file => ({
      ...file,
      preview: url
        ? url
        : getFilePreviewLink(
            file.folderType,
            file.dir,
            file.fileName,
            file.driveType,
            token,
          ),
    }));
  const previewItemLength = previewItems.length;
  const currentFile = previewItems[index];
  const previewItemName = currentFile.fileName;

  const onNextSlide = () => {
    pasueSlideVideo();
    setIndex((index + 1) % previewItemLength);
  };

  const onPrevSlide = () => {
    pasueSlideVideo();
    setIndex((index - 1) % previewItemLength);
  };

  const pasueSlideVideo = () => {
    let activeSlide: HTMLVideoElement | null = document.getElementById(
      `video-${index}`,
    ) as HTMLVideoElement;
    if (activeSlide) {
      activeSlide.pause();
    }
  };

  const driveKeyMapper = (item: DriveFile) => {
    return `${item.driveType}_${item.folderType}/${item.dir}/${item.fileName}`;
  };

  return (
    <Modal
      isOpen={visible}
      className="fullscreen"
      closeTimeoutMS={100}
      overlayClassName="dialog-overlay"
      style={{
        content: {
          position: 'absolute',
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          overflow: 'auto',
          outline: 'none',
        },
      }}
    >
      <div className="file-view-container">
        <div className="file-viewer-tool-bar">
          <div className="font-size-16 text-white file-title">
            {previewItemName}
          </div>
          <div className="d-flex flex-row">
            <button
              className="file-view-tool-bar-btn"
              onClick={async () => {
                console.log(currentFile, token, files);
                downloadFile(currentFile, token);
              }}
            >
              <MdOutlineFileDownload />
            </button>
            <button className="file-view-tool-bar-btn" onClick={onClose}>
              <MdClose />
            </button>
          </div>
        </div>

        <div className="file-view-wrapper">
          <div className="swiper-container">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={true}
              lazy={true}
              modules={[Lazy, Navigation]}
              centeredSlides={true}
              initialSlide={index}
              onNavigationNext={onNextSlide}
              onNavigationPrev={onPrevSlide}
              autoplay={false}
              allowTouchMove={isMobile ? true : false}
            >
              {previewItems.map((item, slideIndex) => {
                return (
                  <SwiperSlide key={driveKeyMapper(item)}>
                    {renderSlide(item, slideIndex)}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </Modal>
  );
};
