import { Route, Routes } from 'react-router-dom';

import { NotFoundPageWithUI } from '../NotFoundPageWithUI/loadable';
import { EventAplicationPaymentPage } from './EventAplicationPaymentPage/loadable';
import { EventBookmarkPage } from './EventBookmarkPage';
import { EventDetailRoutes } from './EventDetailPage';
import { EventListPage } from './EventListPage/loadable';
import { EventMobileSearchPage } from './EventMobileSearchPage/loadable';
import { MyEventRoutes } from './MyEventPage';

export const EventRoutes = () => {
  return (
    <Routes>
      {/* /events */}
      <Route index element={<EventListPage />}></Route>
      <Route path="/my-events/*" element={<MyEventRoutes />}></Route>
      <Route path="/detail/:eventId/*" element={<EventDetailRoutes />}></Route>
      <Route path="/bookmark" element={<EventBookmarkPage />}></Route>
      <Route path="/payment" element={<EventAplicationPaymentPage />}></Route>
      <Route path="/search" element={<EventMobileSearchPage />}></Route>
      <Route path="*" element={<NotFoundPageWithUI />} />
    </Routes>
  );
};
