import 'assets/scss/Components/Home/PerformanceTopTen.scss';
import { FontHelper, changeDollarToPC, formatPrice } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { RankingData } from 'models';
import { Link } from 'react-router-dom';

interface PerformanceTopTenProps {
  data: RankingData;
  index: number;
}

export const PerformanceTopTen = ({ data, index }: PerformanceTopTenProps) => {
  const { size } = useBreakpoint();
  return (
    <>
      {
        <div className="rank-item">
          {index > 0 ? <hr className="rank-item-seperation-line" /> : null}
          <Link
            to={`/home/ranking?userid=${data.user.userId}&type=${data.type}`}
            className="text-dark"
          >
            <div className="d-flex justify-content-between align-items-center rank-item-info">
              <div className="d-flex rank-item-name-flexbox">
                <div className="text-color-orange rank-item-number">
                  {index + 4}
                </div>
                <div
                  className={`${FontHelper(
                    size,
                    14,
                    16,
                    16,
                    'bold',
                  )} rank-item-name text-nowrap one-line-ellipsis`}
                >
                  {data.user.displayName}
                </div>
              </div>
              <div
                className={`${FontHelper(
                  size,
                  14,
                  18,
                  20,
                  'bold',
                )} one-line-ellipsis rank-item-amount`}
              >
                {changeDollarToPC(formatPrice(data.totalAmount, true))}
              </div>
            </div>
          </Link>
        </div>
      }
    </>
  );
};
