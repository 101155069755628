import 'assets/scss/Components/EventAttendeeInfoCard.scss';
import { FontHelper } from 'helpers';
import useBreakpoint from 'hooks/breakpoints';
import { EventAttendees, FormAttendees } from 'models';

export interface EventAttendeeInfoCardData {
  attendeeData?: EventAttendees;
  inputAttendeeData?: FormAttendees;
  index: number;
}

export const EventAttendeeInfoCard = (data: EventAttendeeInfoCardData) => {
  const { size } = useBreakpoint();
  return (
    <div className="attendee-info-card w-100">
      <span className="attendee-info-heading font-18px-semibold yellow-heading">
        {'訪客資訊 (' + (data.index + 1) + ')'}
      </span>
      <div className="attendee-info d-flex justify-content-between">
        <div className="attendee-name">
          <div className="attendee-info-title-text font-16px-semibold">
            英文姓名：
          </div>
          {data.attendeeData ? (
            <div
              className={`${FontHelper(
                size,
                14,
                16,
                16,
                'medium',
              )} attendee-info-text`}
            >
              {data.attendeeData.name
                ? data.attendeeData.name
                : data.attendeeData.User
                ? data.attendeeData.User.displayName
                : null}
            </div>
          ) : data.inputAttendeeData ? (
            <div
              className={`${FontHelper(
                size,
                14,
                16,
                16,
                'medium',
              )} attendee-info-text`}
            >
              {data.inputAttendeeData.name ? data.inputAttendeeData.name : null}
            </div>
          ) : null}
        </div>
        <div className="attendee-email">
          <div className="attendee-info-title-text font-16px-semibold">
            電郵地址：
          </div>
          {data.attendeeData ? (
            <div
              className={`${FontHelper(
                size,
                14,
                16,
                16,
                'medium',
              )} attendee-info-text`}
            >
              {data.attendeeData.email
                ? data.attendeeData.email
                : data.attendeeData.User
                ? data.attendeeData.User.email
                : null}
            </div>
          ) : data.inputAttendeeData ? (
            <div
              className={`${FontHelper(
                size,
                14,
                16,
                16,
                'medium',
              )} attendee-info-text`}
            >
              {data.inputAttendeeData.email
                ? data.inputAttendeeData.email
                : null}
            </div>
          ) : null}
        </div>
        <div className="attendee-contact">
          <div className="attendee-info-title-text font-16px-semibold">
            聯絡電話：
          </div>
          {data.attendeeData ? (
            <div
              className={`${FontHelper(
                size,
                14,
                16,
                16,
                'medium',
              )} attendee-info-text`}
            >
              {data.attendeeData.phoneNumber
                ? data.attendeeData.phoneNumber
                : data.attendeeData.User
                ? data.attendeeData.User.contactPhone
                : null}
            </div>
          ) : data.inputAttendeeData ? (
            <div
              className={`${FontHelper(
                size,
                14,
                16,
                16,
                'medium',
              )} attendee-info-text`}
            >
              {data.inputAttendeeData.phoneNumber
                ? data.inputAttendeeData.phoneNumber
                : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
