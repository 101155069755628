import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { Statement } from 'models';
import moment from 'moment';
import {
  PendingSum,
  getCalendarItem,
  getCurrentStatement,
  getPendingSum,
} from 'services/UserService';
import { AppThunk } from 'store';

interface AppState {
  statement: Statement | null;
  dailyCount: number;
  weeklyCount: number;
  hasUnreadMsg: boolean;
  pendingSum: PendingSum | null;
}

const initialState = {
  statement: null,
  dailyCount: 0,
  weeklyCount: 0,
  hasUnreadMsg: false,
  pendingSum: null,
} as AppState;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setStatement(state, action: PayloadAction<Statement | null>) {
      state.statement = action.payload;
    },
    setDailyCount(state, action: PayloadAction<number>) {
      state.dailyCount = action.payload;
    },
    setWeeklyCount(state, action: PayloadAction<number>) {
      state.weeklyCount = action.payload;
    },
    setHasUnreadMsg(state, action: PayloadAction<boolean>) {
      state.hasUnreadMsg = action.payload;
    },
    setPendingSum(state, action: PayloadAction<PendingSum | null>) {
      state.pendingSum = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  setStatement,
  setDailyCount,
  setWeeklyCount,
  reset,
  setHasUnreadMsg,
  setPendingSum,
} = appSlice.actions;
export default appSlice.reducer;

export const getUserCurrentStatement = (): AppThunk => async dispatch => {
  try {
    const res = await getCurrentStatement();
    dispatch(setStatement(res));
  } catch (error) {
    // console.log(error);
    Sentry.captureException(error);
    dispatch(setStatement(null));
  }
};

export const getUserPendingSum = (): AppThunk => async dispatch => {
  try {
    const res = await getPendingSum();
    dispatch(setPendingSum(res));
  } catch (error) {
    // console.log(error);
    Sentry.captureException(error);
    dispatch(setPendingSum(null));
  }
};

export const updateCount = (): AppThunk => async dispatch => {
  const dayCount = await getCalendarItem({
    startDate: moment().utcOffset(8).format('YYYY-MM-DD'),
    endDate: moment().utcOffset(8).format('YYYY-MM-DD'),
  });
  dispatch(setDailyCount(dayCount.filter(el => !el.isCompanyActivity).length));
  const weekCount = await getCalendarItem({
    startDate: moment().utcOffset(8).format('YYYY-MM-DD'),
    endDate: moment().utcOffset(8).add(6, 'day').format('YYYY-MM-DD'),
  });
  dispatch(
    setWeeklyCount(weekCount.filter(el => !el.isCompanyActivity).length),
  );
};
