import { NotificationData } from 'models/Notification';
import React, { Dispatch } from 'react';

import { scrollToTop } from './common';

interface resetInfiniteScrollingProps<T> {
  setLoading: Dispatch<React.SetStateAction<boolean>>;
  firstTimeLoading?: React.MutableRefObject<boolean>;
  renderedMobilePage?: React.MutableRefObject<number[]>;

  setData?: Dispatch<React.SetStateAction<T[]>>;
  setDataMobile?: Dispatch<React.SetStateAction<T[]>>;

  setNotificationDataMapDeskTop?: Dispatch<
    React.SetStateAction<Record<string, NotificationData[]>>
  >;
  setNotificationDataMapMobile?: Dispatch<
    React.SetStateAction<Record<string, NotificationData[]>>
  >;

  setActivePage?: Dispatch<React.SetStateAction<number>>;
  setIsActiveSubSlideNum?: Dispatch<React.SetStateAction<number>>;
  setTotalCount?: Dispatch<React.SetStateAction<number>>;

  setInfScrollInactive: Dispatch<React.SetStateAction<boolean>>;
}

export const resetInfiniteScrolling = <T>({
  setLoading,
  firstTimeLoading,
  renderedMobilePage,
  setData,
  setDataMobile,
  setNotificationDataMapDeskTop,
  setNotificationDataMapMobile,
  setActivePage,
  setIsActiveSubSlideNum,
  setTotalCount,
  setInfScrollInactive,
}: resetInfiniteScrollingProps<T>) => {
  setLoading(true);

  if (firstTimeLoading) {
    firstTimeLoading.current = true;
  }

  if (renderedMobilePage) {
    renderedMobilePage.current = [];
  }

  setInfScrollInactive(true);

  scrollToTop();

  if (setData) {
    setData([]);
  }
  if (setDataMobile) {
    setDataMobile([]);
  }

  if (setNotificationDataMapDeskTop) {
    setNotificationDataMapDeskTop({});
  }
  if (setNotificationDataMapMobile) {
    setNotificationDataMapMobile({});
  }

  if (setTotalCount) {
    setTotalCount(0);
  }

  if (setActivePage) {
    setActivePage(0);
  }

  if (setIsActiveSubSlideNum) {
    setIsActiveSubSlideNum(1);
  }

  setLoading(false);
};
