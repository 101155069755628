import useBreakpoint from 'hooks/breakpoints';
import React, { ImgHTMLAttributes, useState } from 'react';

// import Loading from './Loading';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  alt?: string;
  src: string;
  fallbackImgSrc?: string;
  fallbackimagecomponent?: React.ReactElement;
  imagewidth?: number;
  imageheight?: number;
  customstyle?: React.CSSProperties;
}

const Image = (props: Props) => {
  const {
    alt,
    src,
    fallbackImgSrc,
    fallbackimagecomponent,
    imagewidth,
    imageheight,
    customstyle,
  } = props;
  const [isError, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { isMobile } = useBreakpoint();

  const onError = () => {
    setError(true);
  };

  const onLoad = () => {
    setLoading(false);
  };
  if (isError && fallbackimagecomponent) {
    return fallbackimagecomponent;
  }

  return (
    <>
      <img
        {...props}
        alt={alt}
        src={isError ? fallbackImgSrc : src}
        onError={onError}
        width={imagewidth}
        height={imageheight}
        onLoad={onLoad}
        style={{ display: isLoading ? 'none' : 'block', ...customstyle }}
      />
      {isLoading ? (
        // <div>
        //   <Loading playerHeight={56} playerWidth={56} />
        // </div>
        <div
          style={{
            display: 'block',
            width: isMobile ? '20px' : '56px',
            height: isMobile ? '20px' : '56px',
            content: '',
          }}
        ></div>
      ) : null}
    </>
  );
};

export default Image;
