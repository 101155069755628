import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import useBreakpoint from './breakpoints';

interface InfiniteScrollProps {
  InActive: boolean;
  loading: boolean;
  cooldownInterval?: number;

  triggerDistance?: number;

  setActivePage?: Dispatch<SetStateAction<number>>;
  ActivePage?: number;
  maxPage?: number;

  firstTimeLoading: boolean;
}

const useInfiniteScroll = ({
  InActive = false,
  loading,
  cooldownInterval,
  triggerDistance,
  setActivePage,
  maxPage = 1,
  ActivePage,
  firstTimeLoading = true,
}: InfiniteScrollProps) => {
  const [IsBottom, setIsBottom] = useState<boolean>(false);
  const { size, isMobile } = useBreakpoint();
  const LocalActivePage = useRef<number>(1);
  const loadingRef = useRef<boolean>(false);
  const [Cooldown, setCooldown] = useState<boolean>(false);
  const currentScrollTop = useRef<number>(0);

  loadingRef.current = loading;

  if (ActivePage && LocalActivePage.current !== ActivePage && !InActive) {
    LocalActivePage.current = ActivePage;
  }

  const checkCanSendReq = useCallback(() => {
    if (!Cooldown) {
      setCooldown(true);
      setTimeout(() => {
        setCooldown(false);
      }, cooldownInterval || 1000);
      return true;
    } else {
      return false;
    }
  }, [Cooldown, cooldownInterval]);

  useEffect(() => {
    const checkIfScrollToBottom = () => {
      // // console.log(document.documentElement.scrollTop);
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.scrollHeight - (triggerDistance || 250) &&
        !loadingRef.current
      ) {
        if (!IsBottom && !InActive) {
          setIsBottom(true);
        }
        return true;
      } else {
        if (IsBottom) {
          setIsBottom(false);
        }
        return false;
      }
    };
    window.addEventListener('scroll', checkIfScrollToBottom);
    window.addEventListener('touchmove', checkIfScrollToBottom);
    return () => {
      window.removeEventListener('scroll', checkIfScrollToBottom);
      window.removeEventListener('touchmove', checkIfScrollToBottom);
    };
  }, [size, IsBottom, triggerDistance, InActive]);

  useEffect(() => {
    if (InActive) setIsBottom(false);
  }, [InActive]);

  useEffect(() => {
    if (isMobile && setActivePage && !InActive) {
      if (
        IsBottom &&
        !loadingRef.current &&
        LocalActivePage.current < maxPage &&
        checkCanSendReq() &&
        !firstTimeLoading &&
        !InActive
      ) {
        currentScrollTop.current = document.documentElement.scrollTop;
        setActivePage(prevPage => ++prevPage);
        LocalActivePage.current++;
        setIsBottom(false);
      }
    }
  }, [
    IsBottom,
    setActivePage,
    maxPage,
    isMobile,
    checkCanSendReq,
    firstTimeLoading,
    InActive,
  ]);

  return;
};

export default useInfiniteScroll;
