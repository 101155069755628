import ArrowRightIcon from 'assets/icons/arrow_right_purple.svg';
import 'assets/scss/Components/Drive/DriveExplorer.scss';
import { DriveFile, DriveObject } from 'models/Drive';
import { Table } from 'react-bootstrap';

import { ButtonCore } from '../Common';
import { DriveEmptyPage } from './DriveEmptyPage';
import FileListItem from './FileListItem';

interface Props {
  previousFolderLocation: DriveObject[];
  currentFolderListData: DriveFile[];
  loading: boolean;
  onSubmit: () => void;
  selectedItem: string[];
  selectedItemObject: DriveFile[];
  selectedItemInDialog: string[];
  dataOnClick: (e: React.MouseEvent<HTMLElement>, data: DriveObject) => void;
  previousLevelOnClick: () => void;
}

export const DriveExplorer = ({
  previousFolderLocation,
  currentFolderListData,
  loading,
  onSubmit,
  selectedItem,
  selectedItemObject,
  selectedItemInDialog,
  dataOnClick,
  previousLevelOnClick,
}: Props) => {
  return (
    <div className="drive-move-folder-dialog">
      <div className="title-div font-18px-semibold noselect">
        文件夾列表
        <div className="title-bottom-bar"></div>
      </div>
      <div className="content-list-item">
        {currentFolderListData.length > 0 ? (
          <Table>
            <tbody>
              {currentFolderListData.map((data, index) => (
                <FileListItem
                  isMoveFolderInPrivate={true}
                  selectedItemInDialog={selectedItemInDialog}
                  data={data}
                  isSelect={false}
                  selectedItem={selectedItem}
                  selectedItemObject={selectedItemObject}
                  dataOnClick={e => dataOnClick(e, data)}
                  key={index}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <DriveEmptyPage />
        )}
      </div>
      <div className="button-div font-18px-semibold text-color-purple noselect">
        <ButtonCore loading={loading} type={'submit'} onClick={onSubmit}>
          移到這裡
        </ButtonCore>
      </div>
      {previousFolderLocation.length > 0 && (
        <div
          className="previous-page-icon"
          onClick={() => previousLevelOnClick()}
        >
          <img
            src={ArrowRightIcon}
            draggable={false}
            alt="previous-page"
            className="cursor-pointer"
          />
        </div>
      )}
    </div>
  );
};
